function disableConsoleLogs() {
    // Check if the current environment is production
    const isProduction = process.env.NODE_ENV === 'production';
    
    // Check if the URL has the "debug" parameter set to true
    const urlParams = new URLSearchParams(window.location.search);
    const isDebug = urlParams.get('debug') === 'true';
    
    // If it's production and debug is not true, disable console logs
    if (isProduction && !isDebug) {
      console.log = () => {};  // Disable console.log
      console.warn = () => {}; // Disable console.warn
      console.error = () => {}; // Disable console.error
      console.info = () => {}; // Disable console.info
      console.debug = () => {}; // Disable console.debug
    }
  }

  export default disableConsoleLogs;
  