import React, { Fragment, lazy, Suspense } from "react";
import { connect } from "react-redux";
// import Navbar from '../../_components/_layout/Navbar';
// import PMAHeader from '../../_components/_layout/Navbar/PMAHeader';
import PmaEditor from "./PMAEditor";
import DataVerification from "views/Pma/DataVerification";
import { pmaActions } from "../../../_store/_actions";
import "../index.scss";
import DocumentTitle from "react-document-title";
//import { accountConstants } from '../../_constants';
import PMANavbar from "../PMANavbar";
import {
  CircularProgress,
  Typography,
  ButtonGroup,
  Button,
  Grid,
} from "@material-ui/core";
import { Fab } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { ScrollToTopComponent } from "components";
import { propertyUtil } from "../PropertyUtil";
import { renderLogicUtil } from "../RenderLogicUtil";
import { IsValidSessionSwitch, GetStateStorage } from "common/storage";
import { Link } from "react-router-dom";
import UnderConstruction from "./UnderConstruction";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";

const AgentPMALoader = () => {
  return (
    <div
      style={{
        height: "80vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        background: "inherit",
      }}
    >
      <div>
        <CircularProgress />
      </div>
      <div>
        <Typography variant="subtitle2">
          &nbsp;&nbsp;Building your Precision Market Analysis
        </Typography>
        <Typography variant="body1">
          &nbsp;&nbsp;This may take a few seconds. Lots of data is being
          collected!
        </Typography>
      </div>
    </div>
  );
};

class AgentPMA extends React.Component {
  state = {
    hoID: null,
    homeowner: 2,
  };

  async componentDidMount() {
    let {
      navbarData,
      version,
      agentData,
      typeOfVersion,
      typeOfMQVersion,
      typeOfQVersion,
      typeOfYEVersion,
      qrCodeMailerProID,
      isUnavailable,
    } = this.props;

    const { subName } = this.props.match.params;

    let isValidSwitchSession = IsValidSessionSwitch();
    //console.log("**inside componentDidMount", this.state, this.props);

    // setTimeout(() => {
    //   this.props.switchStep(0);
    // }, 50);
    // if (isValidSwitchSession) {
    //   let stepSwitch = await this.props.switchStep(2);
    // }
    // this.setState({
    //   homeowner: 0
    // });
    //  console.log("agent props", this.props);
    const { listgenUserID, pmaScheduleID } = agentData;
    const { mlsAreaID, mlsCityID, mlsCityName } = navbarData;
    let switchedCities = ["montecito", "santa barbara", "goleta"];
    let lcArea = mlsCityName && mlsCityName.toLowerCase();
    //console.log("mlsAreaID", mlsAreaID);
    // if (
    //   mlsCityName.toLowerCase() === "montecito" ||
    //   mlsCityName.toLowerCase() === "santa barbara" ||
    //   mlsCityName.toLowerCase() === "goleta"
    //   // ||
    //   // listgenUserID === "100185"
    // ) {
    let differentVersion = false;
    let differentVersionName = "";
    //
    // if (
    //   pmaScheduleID === "SMA-PA"
    // ) {
    //   setTimeout(async () => {
    //     let switchTheVersion = await this.props.switchVersion("qv4");
    //     differentVersion = true;
    //     differentVersionName = "qv4";
    //     let changeTheLoading = await this.props.changeLoading(false);
    //   }, 400);
    // }

    // if (listgenUserID === "101001" || listgenUserID === "100076") {
    //   setTimeout(async () => {
    //     let switchTheVersion = await this.props.switchVersion("year-end-2022");
    //     differentVersion = true;
    //     differentVersionName = "year-end-2022";
    //     let changeTheLoading = await this.props.changeLoading(false);
    //   }, 400);
    // }
    // if (mlsAreaID == "DEMO-SCL-16B") {
    //   console.log("****inside if statement");
    //   setTimeout(async () => {
    //     let switchTheVersion = await this.props.switchVersion("ye2");
    //     differentVersion = true;
    //     differentVersionName = "ye2";
    //     let changeTheLoading = await this.props.changeLoading(false);
    //   }, 400);
    //   // this.props.switchVersion("q");
    // }
    // console.log(this.props, this.state);
    // console.log("***mlsAreaID", mlsAreaID);\

    // if (
    //   listgenUserID === "101567" ||
    //   listgenUserID === "101566" ||
    //   listgenUserID === "100374"
    // ) {
    //   // console.log("***inside before switch");
    //   differentVersion = true;
    //   differentVersionName = "year-end-2022";
    //   version = "year-end-2022";
    //   setTimeout(async () => {
    //     let switchTheVersion = await this.props.switchVersion("year-end-2022");
    //
    //     let changeTheLoading = await this.props.changeLoading(false);
    //   }, 800);
    //   this.props.switchVersion("year-end-2022");
    // }
    // console.log("version is", version);
    // console.log("differentVersion", differentVersion);
    let date = new Date();
    let thisYear = date.getFullYear();
    const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
    const today = `${date.getDate()}`.padStart(2, 0);
    const areaAndDate = `${mlsAreaID}-${thisYear}-${thisMonth}-${today}`;

    let cityAndDate = `${mlsCityID}-${thisYear}-${thisMonth}-${today}`;

    if (version === "qv3") {
      cityAndDate = `${mlsCityID}-${thisYear}-${thisMonth}-${today}`;
    }
    let specialHO = "";

    if (!isUnavailable) {
      try {
        {
          const propertyRecords = await this.props.getMailerProDetails(
            mlsAreaID,
            cityAndDate,
            agentData,
            true,
            differentVersion ? differentVersionName : version,
            version,
            listgenUserID,
            listgenUserID,
            false,
            "",
            subName ? subName : specialHO,
            qrCodeMailerProID
          );
        }
        // const dataVerification = await this.props.getDataVerification(
        //   areaAndDate,
        //   "-"
        // );
      } catch {
        // console.log("***error with mailpro api");
      }
    }

    // console.log('mlsAreaID', mlsAreaID);

    window.scrollTo(0, 0);
  }

  async switchSubdivisionData(specialHO) {
    let { navbarData } = this.props;

    this.props.history.push(
      `/pma/subdivision/${specialHO}/${specialHO}/${navbarData.mlsAreaID}`
    );
    window.history.pushState(
      {},
      "",
      `/pma/subdivision/${specialHO}/${specialHO}/${navbarData.mlsAreaID}`
    );
  }
  switchHomeowner = () => {
    this.setState({
      homeowner: 1,
    });
  };

  async switchTheVersion(v) {
    console.log("switch the version", v);
    await this.props.changeLoading(true);
    let {
      currentArea,
      mlsAreas,
      navbarData,
      version,
      agentData,
      typeOfVersion,
      typeOfMQVersion,
      typeOfQVersion,
      typeOfYEVersion,
    } = this.props;
    const { listgenUserID } = agentData;

    const { mlsAreaID, mlsCityID } = currentArea;
    let date = new Date();

    let thisYear = date.getFullYear();
    const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
    const today = `${date.getDate()}`.padStart(2, 0);
    let clear = true;
    const areaAndDate = `${mlsAreaID}-${thisYear}-${thisMonth}-${today}`;

    let cityAndDate = `${mlsCityID}-${thisYear}-${thisMonth}-${today}`;
    // if (version === "q") {
    //   cityAndDate = `${mlsCityID}-${thisYear}-02-23`;
    // }
    let specialHO = "";
    const propertyRecords = await this.props.getMailerProDetails(
      mlsAreaID,
      cityAndDate,
      agentData,
      true,
      version,
      typeOfMQVersion,
      typeOfQVersion,
      listgenUserID,
      typeOfYEVersion,
      false,
      specialHO
    );

    // const mailPro = await this.props.getMailerProDetails(
    //   mlsAreaID,
    //   cityAndDate,
    //   agentData,
    //   true,
    //   v
    // );

    try {
      if (v === "mq3") {
      } else if (v === "mq") {
        let replacedArea;
        if (mlsAreaID === "157") {
          replacedArea = "345";
        } else {
          replacedArea = mlsCityID;
        }
        let fullCityAndDay = `${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}`;
        const marketActivity = await this.props.getMarketActivity(areaAndDate);
        const mq2Details = await this.props.getMQ2CityDetails(
          fullCityAndDay,
          "-",
          listgenUserID
        );
        const coverPageDetails = await this.props.getPMACoverPhotoDetails(
          replacedArea
        );
      } else if (v == "q") {
        let fullCityAndDay = `${mlsAreaID}-${mlsCityID}-${thisYear}-${thisMonth}-${today}`;

        if (typeOfVersion === "qv3") {
          let replacedArea;
          if (mlsAreaID === "752") {
            replacedArea = "5389";
          } else if (mlsAreaID === "1522155") {
            replacedArea = "5370";
          } else {
            replacedArea = mlsCityID;
          }
          const coverPageDetails = await this.props.getPMACoverPhotoDetails(
            replacedArea
          );
          const getAprilData = await this.props.getAprilApi(
            fullCityAndDay,
            "-"
          );
          // console.log("this.props", this.props);
        } else if (typeOfVersion === "qv4") {
          console.log("verison is qv4");
        } else {
          const { propertyData } = this.props;
          let replacedArea;
          if (mlsAreaID === "752") {
            replacedArea = "5389";
          } else if (mlsAreaID === "1522155") {
            replacedArea = "5370";
          } else if (mlsAreaID === "157") {
            replacedArea = "345";
          } else {
            replacedArea = mlsCityID;
          }
          const coverPageDetails = await this.props.getPMACoverPhotoDetails(
            replacedArea
          );
          // const quarterlyAreaAndCityDetails = await this.props.qAreaAndCityDetails(
          //   mlsAreaID,
          //   `${mlsCityID}-2021-02-06`,
          //   subdivision
          // );
        }
      } else {
        //if verison === 'ye'
        const coverPageDetails = await this.props.getPMACoverPhotoDetails(
          mlsAreaID === "752" ? "5389" : mlsCityID
        );
        const yearEndDetails = await this.props.getYearEndDetails(
          `${mlsAreaID}-${mlsCityID}-2020-12-06`,
          listgenUserID
        );
      }
      setTimeout(async () => {
        let switchTheVersion = await this.props.switchVersion(v);
        let changeTheLoading = await this.props.changeLoading(false);
      }, 800);
    } catch (error) {
      console.log("error switching version", error);
    }
  }

  determineBGColor = (v) => {
    const { version, mailingVersion } = this.props;
    if (v === version && v === mailingVersion) {
      return ["green", "white"];
    } else if (v === version && v !== mailingVersion) {
      return ["darkred", "white"];
    } else {
      return ["", ""];
    }
  };

  isValidPropAddress = (prop) => {
    // console.log('inside is valid prop address', prop);
    // if (prop && prop.mailingDetails === undefined) return false;
    const {
      MailAddress,
      MailCity,
      MailState,
      MailZip,
      OwnerFirst,
      OwnerLast,
    } = prop.mailingDetails;
    function isValid(prop) {
      if (
        prop !== "0" &&
        prop !== "EmptyValue" &&
        prop !== "*no Site Address" &&
        prop !== "-" &&
        prop !== "0"
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (
      isValid(MailAddress) &&
      isValid(MailCity) &&
      isValid(MailState) &&
      isValid(MailZip) &&
      isValid(OwnerFirst) &&
      isValid(OwnerLast)
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleNextHomeowner = async () => {
    this.setState((state, props) => ({
      homeowner: (state.homeowner += 1),
    }));

    let {
      navbarData,
      version,
      agentData,
      typeOfVersion,
      typeOfMQVersion,
      typeOfQVersion,
      typeOfYEVersion,
    } = this.props;
    let isValidSwitchSession = IsValidSessionSwitch();

    const { listgenUserID } = agentData;
    const { mlsAreaID, mlsCityID, mlsCityName } = navbarData;
    let switchedCities = ["montecito", "santa barbara", "goleta"];
    let lcArea = mlsCityName && mlsCityName.toLowerCase();

    let differentVersion = false;
    let differentVersionName = "";

    let date = new Date();
    let thisYear = date.getFullYear();
    const thisMonth = `${date.getMonth() + 1}`.padStart(2, 0);
    const today = `${date.getDate()}`.padStart(2, 0);
    const areaAndDate = `${mlsAreaID}-${thisYear}-${thisMonth}-${today}`;

    let cityAndDate = `${mlsCityID}-${thisYear}-${thisMonth}-${today}`;

    if (version === "qv3") {
      cityAndDate = `${mlsCityID}-${thisYear}-${thisMonth}-${today}`;
    }
    let specialHO = "";
    if (mlsAreaID === "162") {
      specialHO = "Del Mesa";
    }

    try {
      {
        const propertyRecords = await this.props.getMailerProDetails(
          mlsAreaID,
          cityAndDate,
          agentData,
          true,
          differentVersion ? differentVersionName : version,
          version,
          listgenUserID,
          listgenUserID,
          false,
          specialHO
        );
      }
    } catch {}

    window.scrollTo(0, 0);
  };

  handlePreviousHomeowner = () => {
    this.setState((state, props) => ({
      homeowner: (state.homeowner -= 1),
    }));
  };

  forceUpdateAgentPMA = () => {
    this.forceUpdate();
  };

  handleLinkClick = (index) => {
    const stateData = GetStateStorage();
    const agentData = stateData.userData;
    let nav =
      agentData && Array.isArray(agentData.navbarInfo) && agentData.navbarInfo;
    let thisArea = this.props.match.params.areaID;
    let navIndex = nav.map((area) => area.mlsAreaID).indexOf(thisArea);
    let nextIndex = navIndex === nav.length - 1 ? navIndex : navIndex + 1;
    let url = window.location.href;
    this.props.history.push(
      !url.includes("pma")
        ? `/pma/${nav[navIndex].mlsAreaID}`
        : `/mailpro/${nav[navIndex].mlsAreaID}`
    );
    window.history.pushState(
      {},
      "",
      !url.includes("pma")
        ? `/pma/${nav[navIndex].mlsAreaID}`
        : `/mailpro/${nav[navIndex].mlsAreaID}`
    );
  };

  render() {
    const {
      currentStep,
      propertyData,
      pma,
      marketActivity,
      mlsAreaIds,
      mlsAreas,
      currentArea,
      MQCityDetails,
      agentData,
      altAgentData,
      isLoading,
      navbarData,
      handleDrawerOpen,
      isFullProMode, //from parent component not from redux
      quarterlyAreaTable,
      quarterlyCityTable,
      localSellingAgents,
      version,
      mailingVersion,
      viewingGeneratedCL,
      enableMailProApprovalProcess,
      handlePMAIssuesDialog,
      coverPageDetails,
      testimonials,
      agentDesignations,
      agentAdvantages,
      salePriceForCities,
      yearEnd,
      qArea,
      qCity,
      aprilData,
      typeOfVersion,
      typeOfMQVersion,
      typeOfQVersion,
      typeOfYEVersion,
      dataVerification,
      showDataVerification,
      missionStatement,
      propertyPhotoDetailsDTO,
      pmaQuarterlyCombinedCityDTO,
      singleHOData,
      quadrantModalOpen,
      pmaMailerDataLoaded,
      quadrantDetails,
      volunteerDetails,
      bubbleBoxes,
      oldPhotoDetails,
      photoTableData,
      recentActivityPhotoList,
      photoCheckboxModalOpen,
      isUnavailable,
    } = this.props;

    const { pmaScheduleID } = agentData;

    let localStateData = GetStateStorage();
    // let tr = propertyData.filter(
    //   (x) => x.changeDetails.lgSecondaryOwnerFirstName.length > 2
    // );

    let mapped = propertyData.map((x, index) => {});

    // console.log("mapped", mapped);
    let set = new Set(mapped);

    const { listgenUserID, userCreationDate } = agentData ? agentData : {};
    let showMQBtn = true;
    let showQtrBtn = true;
    let showYEBtn = true;
    let quarterDate = new Date(2021, 0, 17).toJSON().slice(0, 10);
    const { mlsAreaID } = currentArea;
    let isKyle = false;
    if (listgenUserID === "100076" || listgenUserID === "100047") {
      isKyle = true;
    }
    /**Get Unapproved areas */
    const { showBox, areaCount } = propertyUtil.getUnapprovedAreaCount(
      mlsAreas
    );

    let isPmaEditor = window.location.href.includes("/pma");

    let dontShowPma = false;
    //
    // if (
    //   pmaScheduleID === "NOPRINT-SCH" ||
    //   pmaScheduleID == null ||
    //   pmaScheduleID === "NEWAGENTS"
    // ) {
    //   dontShowPma = true;
    // }

    //
    // if (
    //   mlsAreaID.includes("SCL") &&
    //   listgenUserID !== "101566" &&
    //   listgenUserID !== "101649" &&
    //   listgenUserID !== "101677" &&
    //   listgenUserID !== "101567"
    // ) {
    //   dontShowPma = false;
    // }

    let isClaudiaM = listgenUserID === "100075";
    //console.log("****recentActivityPhotoList", recentActivityPhotoList);
    let agentsWithoutPMAs = [];

    if (
      dontShowPma ||
      isUnavailable ||
      ((dontShowPma || pmaMailerDataLoaded) &&
        ((!isLoading &&
          currentStep === 2 &&
          Object.keys(dataVerification).length > 0 &&
          quarterlyAreaTable) ||
          (!isLoading &&
            propertyData &&
            ((version === "table-quadrants" &&
              currentArea &&
              singleHOData &&
              agentData &&
              quarterlyAreaTable &&
              quarterlyAreaTable.monthsSupplyTable &&
              coverPageDetails &&
              // MQCityDetails &&
              testimonials &&
              quadrantDetails &&
              quadrantDetails.length &&
              MQCityDetails &&
              MQCityDetails.avgHomesSoldLast12Months) ||
              (version === "bubble-compare" &&
                currentArea &&
                singleHOData &&
                agentData) ||
              (version === "qv2" &&
                currentArea &&
                agentData &&
                coverPageDetails &&
                coverPageDetails.coverPhoto &&
                aprilData &&
                bubbleBoxes &&
                bubbleBoxes.length >= 1 &&
                recentActivityPhotoList &&
                recentActivityPhotoList.length >= 1) ||
              (version === "ye2" &&
                quarterlyAreaTable &&
                currentArea &&
                agentData &&
                coverPageDetails &&
                coverPageDetails.coverPhoto &&
                aprilData &&
                aprilData.pmaAprilRunAreaDTO) ||
              (version === "qv4" &&
                currentArea &&
                agentData &&
                coverPageDetails &&
                coverPageDetails.coverPhoto &&
                volunteerDetails) ||
              (version === "bubble-compare" &&
                currentArea &&
                agentData &&
                coverPageDetails &&
                coverPageDetails.coverPhoto &&
                bubbleBoxes &&
                bubbleBoxes.length >= 1) ||
              (version === "year-end-2022" &&
                currentArea &&
                agentData &&
                coverPageDetails &&
                coverPageDetails.coverPhoto &&
                bubbleBoxes &&
                bubbleBoxes.length >= 1) ||
              (version === "qv4" &&
                currentArea &&
                agentData &&
                coverPageDetails &&
                coverPageDetails.coverPhoto &&
                quadrantDetails &&
                quadrantDetails.length >= 1 &&
                volunteerDetails &&
                volunteerDetails.length >= 1) ||
              (version === "mq3" &&
                currentArea &&
                agentData &&
                coverPageDetails &&
                coverPageDetails.coverPhoto &&
                MQCityDetails &&
                quarterlyAreaTable) ||
              dontShowPma ||
              propertyData.length > 1))))
    ) {
      return (
        <DocumentTitle title={"PMA Editor"}>
          <div className="pma-creator">
            <main>
              <PMANavbar
                propertyData={propertyData}
                agentData={agentData}
                pma={pma}
                mlsAreas={mlsAreas}
                currentArea={currentArea}
                navbarData={navbarData}
                handleDrawerOpen={handleDrawerOpen}
                isFullProMode={isFullProMode}
                isLoading={isLoading}
                showUnapprovedBox={showBox}
                unapprovedAreaCount={areaCount}
                enableMailProApprovalProcess={enableMailProApprovalProcess}
                // newMailingMailProMode={
                //   currentStep === 0
                //     ? false
                //     : currentStep === 1 && showNewMailingDialog
                // }
                isUnavailable={isUnavailable}
              />
              <section
                className={`pma-margin-top ${
                  currentStep === 1 ? "pma-margin-top-mailpro" : ""
                }`}
              >
                {isPmaEditor &&
                singleHOData &&
                // singleHOData.mailingDetails &&
                propertyData ? (
                  // &&
                  // !isLoading
                  <Fragment>
                    {/* //mailingVersion === version && */}
                    {enableMailProApprovalProcess &&
                      currentArea.mailingStatus !== "Approved" && (
                        <div className="showUnApprovedMessage">
                          <div className="showUnApprovedMessageInner">
                            <p>
                              Please review your PMA carefully. If you find any
                              errors or adjustments that need to be made please
                              click on the{" "}
                              <span style={{ color: "#df4d4d" }}>
                                pink button
                              </span>{" "}
                              below.
                              {/* <span>
                                Please review your{" "}
                                {mailingVersion === "mq"
                                  ? "Mid-Quarter"
                                  : mailingVersion === "q"
                                  ? "Quarterly"
                                  : "Year End"}{" "}
                                PMA carefully.
                              </span>
                              If you find any errors please let us know
                              immediately. */}
                            </p>
                            <p>
                              If everything looks good then click on the{" "}
                              <span style={{ color: "#0b5394" }}>
                                blue button
                              </span>{" "}
                              below which will take you to the MailPro APPROVAL
                              screen.
                            </p>
                            <div className="unApprovedButtonContainer">
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={(event) => this.handleLinkClick(1)}
                              >
                                Looks good to me. Continue
                              </Button>
                              <Button
                                color="default"
                                variant="contained"
                                onClick={(event) => handlePMAIssuesDialog(true)}
                              >
                                I have found the following issues / error(s)
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    {/*{showDataVerification && (
                      <Link to="data-verification">
                        <div className="verify-details-button">
                          <div>Verify Details</div>
                        </div>
                      </Link>
                    )}
*/}
                    {/*<div className="version-btn-container">
                        <div className="inner-btn-container">
                          {listgenUserID !== "100129" &&
                            listgenUserID !== "100" && (
                              <button
                                onClick={() => this.switchTheVersion("ye")}
                                className="version-switch-btn"
                                style={{
                                  backgroundColor: this.determineBGColor(
                                    "ye"
                                  )[0],
                                  color: this.determineBGColor("ye")[1]
                                }}
                              >
                                <p className="switch-btn">2020 YEAR-END</p>
                              </button>
                            )}
                          {listgenUserID !== "100129" && (
                            <button
                              onClick={() => this.switchTheVersion("q")}
                              className="version-switch-btn"
                              style={{
                                backgroundColor: this.determineBGColor("q")[0],
                                color: this.determineBGColor("q")[1]
                              }}
                            >
                              <p className="switch-btn">QUARTERLY</p>
                              <p className="switch-subtitle">
                                All data is live!
                              </p>
                            </button>
                          )}
                          <button
                            onClick={() => this.switchTheVersion("mq")}
                            className="version-switch-btn"
                            style={{
                              backgroundColor: this.determineBGColor("mq")[0],
                              color: this.determineBGColor("mq")[1]
                            }}
                          >
                            <p className="switch-btn">CURRENT</p>
                            <p className="switch-subtitle">All data is live!</p>
                          </button>
                        </div>
                      </div>
*/}
                    {isClaudiaM && (
                      <div className="handle-homeowner-button-container">
                        {this.state.homeowner >= 1 && (
                          <button
                            className="next-homeowner-btn"
                            onClick={() =>
                              this.switchSubdivisionData("delmesa")
                            }
                          >
                            Del Mesa
                          </button>
                        )}

                        <button
                          className="next-homeowner-btn"
                          onClick={() => this.switchSubdivisionData("hacienda")}
                        >
                          Hacienda
                        </button>
                      </div>
                    )}

                    {/*<div className="handle-homeowner-button-container">
                      {this.state.homeowner >= 1 && (
                        <button
                          className="next-homeowner-btn"
                          onClick={this.handlePreviousHomeowner}
                        >
                          Previous
                        </button>
                      )}
                      <div>Current Homeowner: {this.state.homeowner}</div>
                      <button
                        className="next-homeowner-btn"
                        onClick={this.handleNextHomeowner}
                      >
                        Next Homeowner
                      </button>
                    </div>*/}
                    <PmaEditor
                      propertyData={propertyData}
                      agentData={agentData}
                      altAgentData={altAgentData}
                      printVersion={false}
                      version={version}
                      singleHOData={singleHOData}
                      marketActivity={marketActivity}
                      mlsAreas={mlsAreas}
                      currentArea={currentArea || mlsAreas[0]}
                      navbarData={navbarData}
                      MQCityDetails={MQCityDetails}
                      quarterlyAreaTable={quarterlyAreaTable}
                      quarterlyCityTable={quarterlyCityTable}
                      localSellingAgents={localSellingAgents}
                      viewingGeneratedCL={viewingGeneratedCL}
                      coverPageDetails={coverPageDetails}
                      testimonials={testimonials}
                      salePriceForCities={salePriceForCities}
                      missionStatement={missionStatement}
                      isKyle={isKyle}
                      yearEnd={yearEnd}
                      qArea={qArea}
                      qCity={qCity}
                      aprilData={aprilData}
                      quadrantDetails={quadrantDetails}
                      volunteerDetails={volunteerDetails}
                      typeOfVersion={typeOfVersion}
                      typeOfMQVersion={typeOfMQVersion}
                      typeOfQVersion={typeOfQVersion}
                      typeOfYEVersion={typeOfYEVersion}
                      dontShowPma={dontShowPma}
                      showDataVerification={showDataVerification}
                      propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
                      pmaQuarterlyCombinedCityDTO={pmaQuarterlyCombinedCityDTO}
                      quadrantModalOpen={quadrantModalOpen}
                      bubbleBoxes={bubbleBoxes}
                      oldPhotoDetails={oldPhotoDetails}
                      photoTableData={photoTableData}
                      recentActivityPhotoList={recentActivityPhotoList}
                      photoCheckboxModalOpen={photoCheckboxModalOpen}
                      isUnavailable={isUnavailable}
                    />
                    <Fragment>
                      <div>
                        <ButtonGroup
                          variant="contained"
                          size="small"
                          aria-label="small contained button group"
                        >
                          {/* <ScrollToTopComponent {...this.props} key={0}>
                              <Fab
                                color="primary"
                                size="medium"
                                aria-label="Scroll back to top"
                                title="Scroll back to top"
                              >
                                <KeyboardArrowUpIcon />
                              </Fab>
                            </ScrollToTopComponent> */}
                          {/* <ScrollToTopComponent
                                                 {...this.props}
                                                 pageMode1={true}
                                                 key={1}
                                               >
                                                 <Fab
                                                   color="primary"
                                                   size="medium"
                                                   aria-label="Scroll back to page 1"
                                                   title="Scroll back to page 1"
                                                   pageMode1={true}
                                                 >
                                                   P.1
                                                 </Fab>
                                               </ScrollToTopComponent> */}
                          {/* <ScrollToTopComponent
                                                 {...this.props}
                                                 pageMode2={true}
                                                 key={2}
                                               >
                                                 <Fab
                                                   color="primary"
                                                   size="medium"
                                                   aria-label="Scroll back to page 2"
                                                   title="Scroll back to page 2"
                                                 >
                                                   P.2
                                                 </Fab>
                                               </ScrollToTopComponent> */}
                        </ButtonGroup>
                      </div>
                    </Fragment>
                  </Fragment>
                ) : currentStep === 2 &&
                  Object.keys(dataVerification).length > 0 &&
                  singleHOData ? (
                  <div className="section-pma-mailing-list">
                    <div>WHERE DATA SHOULD BE</div>
                    {/*<DataVerification
                      currentArea={currentArea}
                      navbarData={navbarData}
                      quarterlyAreaTable={quarterlyAreaTable}
                      mlsAreas={mlsAreas}
                      dataVerification={dataVerification}
                    />*/}
                  </div>
                ) : dontShowPma ? (
                  <UnderConstruction />
                ) : (
                  <div>PMA Data is loading...</div>
                )}
              </section>
            </main>
          </div>
        </DocumentTitle>
      );
      // } else {
      //   return <div>Data cannot be loaded</div>;
      // }
    } else {
      return <AgentPMALoader />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    singleHOData: state.pma.singleHOData,
    currentStep: state.pma.currentStep,
    currentArea: state.pma.currentArea,
    propertyData: state.pma.propertyData,
    pma: state.pma,
    mlsAreaIds: state.pma.mlsAreaIds,
    marketActivity: state.pma.marketActivity,
    MQCityDetails: state.pma.MQCityDetails,
    isLoading: state.pma.isLoading,
    quarterlyAreaTable: state.pma.quarterlyAreaTable,
    quarterlyCityTable: state.pma.quarterlyCityTable,
    localSellingAgents: state.pma.localSellingAgents,
    oldPhotoDetails: state.pma.oldPhotoDetails,
    version: state.pma.version,
    typeOfVersion: state.pma.typeOfVersion,
    typeOfMQVersion: state.pma.typeOfMQVersion,
    typeOfQVersion: state.pma.typeOfQVersion,
    typeOfYEVersion: state.pma.typeOfYEVersion,
    mailingVersion: state.pma.mailingVersion,
    viewingGeneratedCL: state.pma.viewingGeneratedCL,
    coverPageDetails: state.pma.coverPageDetails,
    testimonials: state.pma.testimonials,
    agentDesignations: state.pma.agentDesignations,
    agentAdvantages: state.pma.agentAdvantages,
    salePriceForCities: state.pma.salePriceForCities,
    yearEnd: state.pma.yearEnd,
    qArea: state.pma.qArea,
    qCity: state.pma.qCity,
    aprilData: state.pma.aprilData,
    propertyPhotoDetailsDTO: state.pma.propertyPhotoDetailsDTO,
    missionStatement: state.pma.missionStatement,
    dataVerification: state.pma.dataVerification,
    showDataVerification: state.pma.showDataVerification,
    pmaQuarterlyCombinedCityDTO: state.pma.pmaQuarterlyCombinedCityDTO,
    explicitEnableNewMailProInit: state.pma.explicitEnableNewMailProInit,
    quadrantModalOpen: state.pma.quadrantModalOpen,
    quadrantDetails: state.pma.quadrantDetails,
    volunteerDetails: state.pma.volunteerDetails,
    bubbleBoxes: state.pma.bubbleBoxes,
    pmaMailerDataLoaded: state.pma.pmaMailerDataLoaded,
    photoTableData: state.pma.photoTableData,
    recentActivityPhotoList: state.pma.recentActivityPhotoList,
    photoCheckboxModalOpen: state.pma.photoCheckboxModalOpen,
    altAgentData: state.pma.altAgentData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMailerProDetails: (
      areaId,
      cityAndDate,
      agentData,
      forEditor,
      version,
      typeOfMQVersion,
      typeOfQVersion,
      listgenUserID,
      typeOfYEVersion,
      isDataVerification,
      specialHO,
      qrCodeMailerProID
    ) => {
      dispatch(
        pmaActions.getMailerProDetails(
          areaId,
          cityAndDate,
          agentData,
          forEditor,
          version,
          typeOfMQVersion,
          typeOfQVersion,
          listgenUserID,
          typeOfYEVersion,
          isDataVerification,
          specialHO,
          qrCodeMailerProID
        )
      );
    },
    getMarketActivity: (areaAndDate, clear) => {
      dispatch(pmaActions.getMarketActivity(areaAndDate, clear));
    },
    getMQ2CityDetails: (cityAndDate, subdivision, listgenUserID) => {
      dispatch(
        pmaActions.getMQ2CityDetails(cityAndDate, subdivision, listgenUserID)
      );
    },
    getMQCityDetails: (cityAndDate, clear) => {
      dispatch(pmaActions.getMQCityDetails(cityAndDate, clear));
    },
    getQuarterAreaTable: (areaAndDate) => {
      dispatch(pmaActions.getQuarterAreaTable(areaAndDate));
    },
    getQuarterCityTable: (cityAndDate) => {
      dispatch(pmaActions.getQuarterCityTable(cityAndDate));
    },
    getLocalSellingAgents: (areaAndDate) => {
      dispatch(pmaActions.getLocalSellingAgents(areaAndDate));
    },
    switchVersion: (version) => {
      dispatch(pmaActions.switchVersion(version));
    },
    getPMACoverPhotoDetails: (mlsCityID) => {
      dispatch(pmaActions.getPMACoverPhotoDetails(mlsCityID));
    },
    getTestimonials: (listgenUserID) => {
      dispatch(pmaActions.getTestimonials(listgenUserID));
    },
    getAgentDesignations: (listgenUserID) => {
      dispatch(pmaActions.getAgentDesignations(listgenUserID));
    },
    getAprilApi: (cityAndDate, subdivision) => {
      dispatch(pmaActions.getAprilApi(cityAndDate, subdivision));
    },

    getAgentAdvantages: (listgenUserID) => {
      dispatch(pmaActions.getAgentAdvantages(listgenUserID));
    },
    getSalePriceForCities: (date) => {
      dispatch(pmaActions.getSalePriceForCities(date));
    },

    getSAT: (hoID, listgenUserID) => {
      dispatch(pmaActions.getSAT(hoID, listgenUserID));
    },
    qAreaAndCityDetails: (mlsAreaID, cityAndDate, subdivision) => {
      dispatch(
        pmaActions.qAreaAndCityDetails(mlsAreaID, cityAndDate, subdivision)
      );
    },
    changeLoading: (status) => {
      dispatch(pmaActions.changeLoading(status));
    },
    updateNavbar: (mlsAreas) => dispatch(pmaActions.updateNavbar(mlsAreas)),
    switchStep: (step) => dispatch(pmaActions.switchStep(step)),

    getYearEndDetails: (date, listgenUserID) => {
      dispatch(pmaActions.getYearEndDetails(date, listgenUserID));
    },
    getDataVerification: (areaAndDate, subdivision) => {
      dispatch(pmaActions.getDataVerification(areaAndDate, subdivision));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(withRouter)(AgentPMA));
