import React, { Component, Fragment } from "react";
import { propertyUtil } from "../../PropertyUtil";
import moment from "moment";
import OffMarketSale from "./OffMarketSale";
import { Typography } from "@material-ui/core";
import { renderLogicUtil } from "views/Pma/RenderLogicUtil";
import CustomNoteDialogV2 from "./Notes/PersonalNote/CustomNoteDialogV2";

const LastSale = (props) => {
  const { listPrice, listingStatus, expiryDate, cancelledDate } = props.propertyDetails;

  const {
    propertyDetails,
    mailingDetails,
    areaID,
    mailerProID,
    agentID,
    mailingStatus,
    changeDetails,
    isPropertyMapped,
    propertyData,
    nextPMAPrintDate,
    lastPMAPrintDate,
    agentData,
    property,
    propertySequenceNumber,
    isMailProLocked
  } = props;
  const { psType, psToSend } = changeDetails
    ? changeDetails
    : propertyDetails
    ? propertyDetails
    : {};

  const { latestSaleDate, latestSalePrice } = property;
  /**
   * Check for blocked here
   */
  let { blocked } = props;
  blocked = blocked && blocked !== null ? true : false;
  
  const {formattedSale, formattedSold} = renderLogicUtil.getFormattedSaleDateandPrice(property)
  /**
   * Put the if statements here
   */
  if (!formattedSold && !formattedSale) {
    const { YearBuilt } = mailingDetails || {};
    return (
      <Fragment>
        <div style={{ marginTop: "32px", fontWeight: 600 }}>
          Year Built: {YearBuilt}
        </div>
        <div style={{ marginTop: "16px", fontWeight: 600 }}>
          NEVER ON THE MLS
        </div>
      </Fragment>
    );
  } else if (property.HOQuickFilterData.isCongrats && !blocked) {
    //showCongrats
    //     /* if there is a personal ps && the homeowner bought the house within the last 60 days, the text will be green. if the homeowner recently bought the house and there is no personal ps, then the text will be red */
    return (
      <CustomNoteDialogV2
        cameFrom="Congrats"
        formattedSold={formattedSold}
        lastSalePrice={formattedSale}
        propertyDetails={propertyDetails}
        mailingDetails={mailingDetails}
        areaID={areaID}
        mailerProID={mailerProID}
        agentID={agentID}
        mailingStatus={mailingStatus}
        changeDetails={changeDetails}
        propertyData={propertyData}
        isCustomPropertyBlocked={blocked}
        agentData={agentData}
        propertySequenceNumber={propertySequenceNumber}
        property={property}
        lastPMAPrintDate={lastPMAPrintDate}
        isMailProLocked={isMailProLocked}
      />
    );
  } else if (property.HOQuickFilterData.isAnniversary && !blocked) {
    //showAnniversary
    return (
      <CustomNoteDialogV2
        cameFrom="Anniversary"
        formattedSold={formattedSold}
        lastSalePrice={formattedSale}
        propertyDetails={propertyDetails}
        mailingDetails={mailingDetails}
        areaID={areaID}
        mailerProID={mailerProID}
        agentID={agentID}
        mailingStatus={mailingStatus}
        changeDetails={changeDetails}
        propertyData={propertyData}
        isCustomPropertyBlocked={blocked}
        agentData={agentData}
        propertySequenceNumber={propertySequenceNumber}
        property={property}
        lastPMAPrintDate={lastPMAPrintDate}
        isMailProLocked={isMailProLocked}
      />
    );
  } else if (
    // listingStatus === "Expired" &&
    // isSoldDateBwLastPrintandToday &&
    property.HOQuickFilterData.isExpired &&
    !blocked
  ) {
    return (
      <CustomNoteDialogV2
        cameFrom="Expired"
        formattedSold={formattedSold}
        lastSalePrice={formattedSale}
        propertyDetails={propertyDetails}
        mailingDetails={mailingDetails}
        areaID={areaID}
        mailerProID={mailerProID}
        agentID={agentID}
        mailingStatus={mailingStatus}
        changeDetails={changeDetails}
        propertyData={propertyData}
        isCustomPropertyBlocked={blocked}
        agentData={agentData}
        expiryDate={expiryDate}
        propertySequenceNumber={propertySequenceNumber}
        property={property}
        lastPMAPrintDate={lastPMAPrintDate}
        isMailProLocked={isMailProLocked}
      />
    );
  } else if (
    // listingStatus == "Cancelled" &&
    // isSoldDateBwLastPrintandToday &&
    property.HOQuickFilterData.isCancelled &&
    !blocked
  ) {
    return (
      <CustomNoteDialogV2
        cameFrom="Cancelled"
        formattedSold={formattedSold}
        lastSalePrice={formattedSale}
        propertyDetails={propertyDetails}
        mailingDetails={mailingDetails}
        areaID={areaID}
        mailerProID={mailerProID}
        agentID={agentID}
        mailingStatus={mailingStatus}
        changeDetails={changeDetails}
        propertyData={propertyData}
        isCustomPropertyBlocked={blocked}
        agentData={agentData}
        cancelledDate={cancelledDate}
        propertySequenceNumber={propertySequenceNumber}
        property={property}
        lastPMAPrintDate={lastPMAPrintDate}
        isMailProLocked={isMailProLocked}
      />
    );
  } else {
    // remove if (isPropertyMapped)
    return (
      <div>
        <div>
          <div>{formattedSold ? formattedSold : "Sale Date Unknown"}</div>
          <div className="at-the-rate">&#64;</div>
          <div>{formattedSale ? `$${formattedSale}` : "Sale Price Unknown"}</div>
        </div>
        <div style={{ marginTop: "16px" }}>
          <OffMarketSale property={property} agentData={agentData} />
        </div>
      </div>
    );
  }
};

export default LastSale;
