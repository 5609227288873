import React, { Component } from "react";
import QVFourFirstLeft from "./QVFourFirstLeft";
import QVFourFirstRight from "./QVFourFirstRight";
import V2DebSamuel from "views/Pma/PmaEditor/CustomAgentPages/V2DebSamuel";
import YETwoFirstLeft from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoFirstPage/YETwoFirstLeft";

import RGB from "views/Pma/PmaEditor/CustomAgentPages/RGB";

class QVFourFirstPage extends Component {
  // componentDidMount() {
  //   if (this.props.printVersion) {
  //     require("views/Pma/PmaEditor/YearEnd2022/YeTwo/ye2print.scss");
  //   } else {
  //     require("views/Pma/PmaEditor/YearEnd2022/YeTwo/ye2styles.scss");
  //   }
  // }
  render() {
    const {
      agentData,
      altAgentData,
      hoData,
      currentArea,
      mlsAreas,
      aprilData,
      printVersion,
      version,
      coverPageDetails,
      quarterlyAreaTable,
      testimonials,
      quadrantModalOpen,
      quadrantDetails,
      volunteerDetails,
      propertyPhotoDetailsDTO,
      missionStatement,
      isDemo,
      bubbleBoxes,
      handlePropertyHighlightDialog,
    } = this.props;
    let showPropertyHighlightPage = false;
    const { mlsAreaID } = currentArea;
    const { listgenUserID } = agentData;
    let isKristinArntz = listgenUserID === "x100462";

    // if (isKristinArntz) {
    //   showPropertyHighlightPage = true;
    // }
    return (
      <div
        className={
          "pma-page-container " +
          (printVersion
            ? "qv4-print-page-container"
            : "qv4-pma-editor-page-container")
        }
      >
        {(printVersion && mlsAreaID === "SBA-10A") ||
        (printVersion && mlsAreaID === "SBA-10G") ? (
          <RGB
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version="qv4"
            //  demoAccountHOName={demoAccountHOName}
            coverPageDetails={coverPageDetails}
          />
        ) : listgenUserID === "100585" ? (
          <V2DebSamuel
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version="qv4"
            //  demoAccountHOName={demoAccountHOName}
            coverPageDetails={coverPageDetails}
          />
        ) : showPropertyHighlightPage ? (
          <YETwoFirstLeft
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version={version}
            aprilData={aprilData}
            coverPageDetails={coverPageDetails}
            testimonials={testimonials}
            handlePropertyHighlightDialog={handlePropertyHighlightDialog}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            quarterlyAreaTable={quarterlyAreaTable}
            mlsAreaID={mlsAreaID}
            isDemo={isDemo}
            bubbleBoxes={bubbleBoxes}
            quadrantDetails={quadrantDetails}
          />
        ) : (
          <QVFourFirstLeft
            agentData={agentData}
            altAgentData={altAgentData}
            hoData={hoData}
            currentArea={currentArea}
            printVersion={printVersion}
            version={version}
            mlsAreas={mlsAreas}
            coverPageDetails={coverPageDetails}
            quarterlyAreaTable={quarterlyAreaTable}
            testimonials={testimonials}
            quadrantModalOpen={quadrantModalOpen}
            quadrantDetails={quadrantDetails}
            volunteerDetails={volunteerDetails}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            missionStatement={missionStatement}
            isDemo={isDemo}
            bubbleBoxes={bubbleBoxes}
          />
        )}
        <QVFourFirstRight
          agentData={agentData}
          hoData={hoData}
          printVersion={printVersion}
          version={version}
          currentArea={currentArea}
          coverPageDetails={coverPageDetails}
          quarterlyAreaTable={quarterlyAreaTable}
          quadrantModalOpen={quadrantModalOpen}
          isDemo={isDemo}
        />
      </div>
    );
  }
}

export default QVFourFirstPage;
