import React, { Component } from "react";

class BrettNote extends Component {
  render() {
    return (
      <div className="brett-note">
        <div className="brett-note-inner-container">
          <div className="brett-note-title">A NOTE FROM BRETT...</div>
          <div className="brett-note-text">
            Happy Holidays from my family to yours. This is our favorite time of
            the year, and we love celebrating in this incredible Santa Barbara
            weather. I want to thank everyone who has either referred a client
            to me or used me as their Realtor over the years of my career. As my
            family grows in this community, I look forward to another year of
            helping friends and clients achieve their real estate goals.
          </div>

          <div className="brett-note-signoff">
            Happy Holidays, The Buschbom's
          </div>
        </div>
        <div className="brett-note-img-container">
          <img
            src="https://pma-coverphotos.s3.us-east-1.amazonaws.com/brett-b-nov-4-large.jpg"
            className="brett-note-img"
            alt="Brett Note"
          />
        </div>
      </div>
    );
  }
}

export default BrettNote;
