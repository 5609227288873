import React, { Component } from "react";
import { pmaActions } from "_store/_actions";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
class VolunteerOrganizations extends Component {
  componentDidMount = () => {
    const { currentArea, altAgentData } = this.props;
    const { pmaScheduleID, pmaMarketID } = altAgentData;

    this.props.getVolunteerDetails(pmaScheduleID, pmaMarketID);
  };

  render() {
    const {
      selectOrganizationText,
      selectedQuadrantNumber,
      volunteerList,
      editQuadrantFormData,
    } = this.props;
    const { defaultBPCIList } = volunteerList;
    const { bpciAreaSubTitle, bpciAreaTitle } = editQuadrantFormData;
    if (defaultBPCIList && defaultBPCIList.length >= 1) {
      return (
        <div className="volunteer-organizations">
          <div className="volunteer-organizations-title">
            <p>
              If you would like additional organizations to choose from, please
              select from the list below:
            </p>
          </div>
          <div className="volunteer-organizations-list-container">
            {defaultBPCIList.map((organization) => {
              let buttonText = "Select This";
              let buttonStyles;
              let quadrantTitle =
                editQuadrantFormData[`bpciHeading${selectedQuadrantNumber}`];
              let quadrantText =
                editQuadrantFormData[`bpciText${selectedQuadrantNumber}`];
              let quadrantWebsiteURL =
                editQuadrantFormData[`bpciFooter${selectedQuadrantNumber}`];

              if (
                quadrantTitle === organization.bpciHeader &&
                quadrantText === organization.bpciText &&
                quadrantWebsiteURL === organization.bpciFooter
              ) {
                buttonText = "Selected";
                buttonStyles = "selected-organization-btn";
              }
              return (
                <div className="organization-section" key={organization.bpciID}>
                  <div className="organization-section-button-container">
                    <div
                      className={`organization-section-button ${buttonStyles}`}
                    >
                      <p
                        className="organization-section-button-text"
                        onClick={() =>
                          selectOrganizationText(
                            organization,
                            selectedQuadrantNumber
                          )
                        }
                      >
                        {buttonText}
                      </p>
                    </div>
                  </div>
                  <div className="organization-section-content-container">
                    <p className="organization-section-inner-content">
                      <span className="organization-list-text">
                        <strong>{organization.bpciHeader}</strong>
                        {organization.bpciText}{" "}
                        <span className="organization-list-websiteURL">
                          {organization.bpciFooter}
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div className="volunteer-loading-container">
          <CircularProgress />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    volunteerList: state.pma.volunteerList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVolunteerDetails: (pmaScheduleID, pmaMarketID) => {
      dispatch(pmaActions.getVolunteerDetails(pmaScheduleID, pmaMarketID));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VolunteerOrganizations);
