import { Typography } from '@material-ui/core';
import { isAKylesAccount } from 'common/helpers';
import React from 'react';

const OffMarketSale = ({ property, agentData = {}, styles = {} }) => {
    const { propertyDetails } = property ? property : {};
    const { listingID } = propertyDetails ? propertyDetails : {};
    const isOffMarketSale = !listingID;
    const isAKyleAccount = isAKylesAccount(agentData);
    if(!isAKyleAccount) {
        return null;
    }
    return (
        <div style={{...{marginTop: '16px'}, ...styles}}>
            {isOffMarketSale ?
                <div className='off-market-sale-section'>Off-Market Sale</div>
                :
                <div className='off-market-mls-listing'>MLS # {listingID}</div>}
        </div>
    );
}
export default OffMarketSale;