import React, { useContext, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, withRoutes } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import {
  Avatar, Typography, Button, LinearProgress,
  TextField, InputLabel
} from '@material-ui/core';
import { Portlet, PortletContent, PortletFooter } from 'components';
import styles from './styles';
import { Store } from 'Store';
import Base from 'common/constants';
import { showSuccessNotification } from 'common/helpers';
import { showErrorNotification } from 'common/helpers';
import { RegisterAgentUploadPhotoAPI } from 'services/agent';
import AgentProfile from 'components/AgentProfile';
import { UPDATE_USER_PROFILE_IMAGE } from 'store/types';
import compose from "recompose/compose";
import { withSnackbar } from 'notistack';
import { isUserAdmin, isUserAgent } from 'common/roles/role';

const AccountProfile = (props) => {
  const { state, dispatch } = useContext(Store);

  const [isLoading, setLoading] = useState(false);

  const [fileErrors, setFileErrors] = useState(null);

  const [viewProfile, setViewProfile] = useState(false);

  const { userData } = state;

  const { classes, className, ...rest } = props;

  const rootClassName = classNames(classes.root, className);

  const validateFile = (type, file) => {
    /**first set file errors to null */
    setFileErrors(null);
    /**check for file type first */
    var regex = new RegExp(/^.*\.(jpg|jpeg|JPG|JPEG|png|PNG)$/);
    if (!regex.test(file.name.toLowerCase())) {
      setFileErrors('Invalid file type. Only jpg | JPG | jpeg | JPEG | png | PNG extension file allowed.');
      return;
    }
    /**check for size */
    if (file.size > state.maxMediaSize) {
      setFileErrors('Invalid file size. Maximum file size should not exceed 5 mb.');
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      var image = new Image();
      image.src = e.target.result;

      image.onload = function () {
        var height = this.height;
        var width = this.width;
        // if (
        //   !(width <= state.mediaSize[type].width) ||
        //   !(height <= state.mediaSize[type].height)
        // ) {
        //   setFileErrors(
        //     'Invalid media size. Allowed resolution(width: ' +
        //     state.mediaSize[type].width +
        //     ' x height: ' +
        //     state.mediaSize[type].height +
        //     '). Current resolution(width: ' +
        //     width +
        //     ' x height: ' +
        //     height +
        //     ')'
        //   );
        //   return;
        // }
        /**everythinf fine. upload image */
        uploadImages(type, file);
      };
    };
  };

  const uploadImages = (type, file) => {
    /**make loading true */
    setLoading(true);
    try {
      RegisterAgentUploadPhotoAPI(
        type,
        userData.listgenUserID,
        file
      )
        .then(data => {
          if (data) {
            dispatch({
              type: UPDATE_USER_PROFILE_IMAGE,
              payload: data
            });
            showSuccessNotification(props, 'Profile image uploaded.');
          } else {
            showErrorNotification(props, 'Unable to upload image.');
          }
          /**stop loading also */
          setLoading(false)
        })
        .catch(err => {
          showErrorNotification(props, 'Unable to process request.');
          setLoading(false);
        });
    } catch (err) {
      showErrorNotification(props, 'Unable to process request.');
      setLoading(false);
    }
  };

  const handleFileUpload = (event, type = "profile") => {
    event.preventDefault();
    //uploadImages(type, event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      validateFile(type, event.target.files[0]);
    }
  };

  const onCloseProfile = () => {
    setViewProfile(false);
  };

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      {viewProfile ?
        <AgentProfile listgenUserID={state.userData.listgenUserID} onClose={onCloseProfile} />
        : ''}
      <PortletContent>
        <div className={classes.details}>
          <div className={classes.info}>
            <Typography variant="h6">{userData.listgenUserFirstName}&nbsp;{userData.listgenUserLastName}</Typography>
            <Typography
              className={classes.dateText}
              variant="subtitle2"
            >
              {userData.emailAddress ? userData.emailAddress : 'No email address'}
            </Typography>
            {isUserAgent(userData.listgenRoleID) ?
              <Fragment>
                <Typography
                  className={classes.locationText}
                  variant="subtitle2"
                >
                  Agent License Number: {userData.agentLicenseNumber}
                </Typography>
                <Typography
                  className={classes.locationText}
                  variant="subtitle2"
                >
                  Broker License Number: {userData.brokerLicenseNumber}
                </Typography>
              </Fragment>
              : ''}
            <Typography
              className={classes.locationText}
              variant="subtitle2"
            >
              Account Type: {isUserAdmin(userData.listgenRoleID) ? 'Admin' : 'Agent'}
            </Typography>
            <Typography
              className={classes.locationText}
              variant="subtitle2"
            >
              Account Status: {userData.userStatus ? userData.userStatus : 'Active'}
            </Typography>
          </div>
          <div className={classes.imageSection}>
            <div className={classes.imageHolder}>
              <Avatar
                className={classes.avatar}
                src={userData.photoURL ? userData.photoURL : '/images/no-image.png'}
              />
            </div>
            <div className={classNames(classes.uploadButtonsection)}>
              <TextField
                className={classNames(classes.textField, classes.hideField)}
                id="additional-agent-photo"
                name="agentPhoto"
                onChange={
                  event => handleFileUpload(event)
                }
                type="file"
                inputProps={state.settings.file}
              />
              <InputLabel htmlFor="additional-agent-photo">
                <Button component="span" color="primary" className={classes.uploadButton}>
                  Upload
                                                </Button>
              </InputLabel>
            </div>
          </div>
        </div>
        <div className={classes.progressWrapper}>
          <Typography variant="body1">Profile Completeness: 100%</Typography>
          <LinearProgress
            value='100'
            variant="determinate"
          />
        </div>
        <div>
          {fileErrors && (
            <Typography className={classes.fieldError} variant="body2">
              <br />
              {fileErrors}
            </Typography>
          )}
        </div>
      </PortletContent>
      {isUserAgent(userData.listgenRoleID) ?
        <PortletFooter>
          <Button
            className={classes.uploadButton}
            color="primary"
            variant="text"
            onClick={event => setViewProfile(true)}
          >
            View Complete Profile
          </Button>
          {/* Its always agent specific only */}
          <Link to={"/agent/details/ag/" + btoa(userData.listgenUserID)}>
            <Button variant="text">Update Profile</Button>
          </Link>
        </PortletFooter>
        : ''}
    </Portlet>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default compose(
  withSnackbar,
  withStyles(styles)
)(AccountProfile);
