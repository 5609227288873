import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, Grid, Divider, CircularProgress } from '@material-ui/core';
import Base from 'common/constants';

import {
  Badge,
  IconButton,
  Popover,
  Toolbar,
  Typography
} from '@material-ui/core';

import {
  Menu as MenuIcon,
  Close as CloseIcon,
  NotificationsOutlined as NotificationsIcon,
  AccountBoxOutlined as AccountIcon,
  FormatListBulletedOutlined
} from '@material-ui/icons';

import { NotificationList, ProfileMenu } from './components';

import styles from './styles';
import NavbarCounters from 'components/NavbarCounters';
import { isUserAdmin } from 'common/roles/role';

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      notificationsLimit: 4,
      notificationsCount: 0,
      notificationsEl: null,
      menuAnchorEl: null,
      menuOpen: false,
      tooltip: {
        notification: false,
        account: false
      }
    };
    this.signal = true;

  }

  async getNotifications() {
  }

  componentDidMount() {
    this.signal = true;
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleSignOut = () => {
    const { history } = this.props;

    //localStorage.setItem('isAuthenticated', false);
    //ResetAllLocalStorages();
    /*close the popover */
    this.handleMenuClose();
    history.push('/logout?mode=1');
  };

  handleShowNotifications = event => {
    this.setState({
      notificationsEl: event.currentTarget,
      tooltip: {
        notification: false,
        account: false
      }
    });
  };

  handleCloseNotifications = () => {
    this.setState({
      notificationsEl: null,
      tooltip: {
        notification: false,
        account: false
      }
    });
  };

  handleMenuOpen = event => {
    this.setState({
      menuAnchorEl: event.currentTarget,
      menuOpen: true,
      tooltip: {
        notification: false,
        account: false
      }
    });
  };

  handleMenuClose = () => {
    /**set tooltip open to false to hide tooltip when menu close happen */
    this.setState({
      menuAnchorEl: null,
      menuOpen: false,
      tooltip: {
        notification: false,
        account: false
      }
    });
  };

  handleTooltipClose = (field) => {
    const newState = { ...this.state };
    newState.tooltip[field] = false;
    this.setState(newState);
  };

  handleTooltipOpen = (field) => {
    const newState = { ...this.state };
    newState.tooltip[field] = true;
    this.setState(newState);
  };

  render() {
    const {
      classes,
      className,
      title,
      isSidebarOpen,
      onToggleSidebar,
      localStateData,
      sessionSwitchMode
    } = this.props;
    const { notifications, notificationsCount, notificationsEl, menuAnchorEl, menuOpen } = this.state;

    const rootClassName = classNames(classes.root, className);
    const showNotifications = Boolean(notificationsEl);
    let { userData } = localStateData;
    userData = userData ? userData : {};

    return (
      <Fragment>
        <div className={rootClassName}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.toolbarLeft}>
              <IconButton
                className={classes.menuButton}
                onClick={onToggleSidebar}
                variant="text"
                color="inherit"
                classes={{ label: classes.menuButtonLabel }}
              >
                {isSidebarOpen ?
                  <CloseIcon /> :
                  <Fragment>
                    <MenuIcon />
                    <span>Menu</span>
                  </Fragment>}
              </IconButton>
              <Typography
                className={classes.title}
                variant="subtitle1"
              >
                {title}
              </Typography>
            </div>
            <div className={classes.toolbarCenter}>
              {localStateData && localStateData.userRole && isUserAdmin(localStateData.userRole) ?
                <NavbarCounters />
                // show agent names in session switch mode
                : (sessionSwitchMode ? <div className={classes.agentNameDiv}>
                  <div>Agent Name:&nbsp;{userData.listgenUserFirstName}&nbsp;{userData.listgenUserLastName}</div>
                  <div>Account ID # {userData.listgenUserID}{userData.mobilePhoneNo ? <Fragment>&nbsp;&nbsp;|&nbsp;&nbsp;Mobile # {userData.mobilePhoneNo}</Fragment> : ""}</div>
                </div> : "")
              }
            </div>
            <div className={classes.toolbarRight}>
              {/* <Tooltip
                onClose={event => this.handleTooltipClose('notification')}
                onOpen={event => this.handleTooltipOpen('notification')}
                open={this.state.tooltip.notification}
                title="Notifications"
                disableFocusListener disableTouchListener
              >
                <IconButton
                  className={classes.notificationsButton}
                  onClick={this.handleShowNotifications}
                >
                  <Badge
                    badgeContent={notificationsCount}
                    color="primary"
                    variant="dot"
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Tooltip> */}
              <div>
                <Tooltip
                  onClose={event => this.handleTooltipClose('account')}
                  onOpen={event => this.handleTooltipOpen('account')}
                  open={this.state.tooltip.account}
                  title="My Account"
                  disableFocusListener disableTouchListener
                >
                  <IconButton
                    className={classes.accountButton}
                    onClick={this.handleMenuOpen}
                    color="inherit"
                  >
                    <AccountIcon />
                    &nbsp;<Typography variant="subtitle2" component="h6">My Account</Typography>
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </Toolbar>
        </div>
        {/* <Popover
          anchorEl={notificationsEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          onClose={this.handleCloseNotifications}
          open={showNotifications}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <NotificationList
            notifications={notifications}
            onSelect={this.handleCloseNotifications}
          />
        </Popover> */}
        <Popover
          anchorEl={menuAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          onClose={this.handleMenuClose}
          open={menuOpen}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <ProfileMenu
            onSelect={this.handleMenuClose}
            handleSignOut={this.handleSignOut}
          ></ProfileMenu>
        </Popover>
      </Fragment>
    );
  }
}

Topbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isSidebarOpen: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
  title: PropTypes.string
};

Topbar.defaultProps = {
  onToggleSidebar: () => { }
};

export default compose(
  withRouter,
  withStyles(styles)
)(Topbar);
