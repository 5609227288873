import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Button, withStyles } from "@material-ui/core";
import { Typography, CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "./styles.scss";
import { Dashboard as DashboardLayout } from "layouts";
import { withSnackbar } from "notistack";
import { Portlet } from "components";
import { PortletContent } from "components";
import { PortletLabel } from "components";
import { PortletHeader } from "components";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import { pmaActions } from "../../_store/_actions";
import DocumentTitle from "react-document-title";
import theme from "theme";
import classNames from "classnames";
import querystring from "querystring";

import { isCommonEmptyCheck } from "common/helpers";

import { showErrorNotification, showSuccessNotification } from "common/helpers";
import { handleLogoutFn } from "common/helpers";
import { withRouter, Link } from "react-router-dom";
import { IsValidSessionSwitch } from "common/storage";
import { IsValidLoginAuth } from "common/storage";
import { GetStateStorage } from "common/storage";
import Base from "common/constants";

class NewAgentStart extends Component {
  render() {
    return (
      <Fragment>
        <DocumentTitle title="New Agent Start">
          <div className="start-container">
            <div className="start-inner-container">
              <div>
                <div className="new-agent-title">Welcome to ListGen</div>
              </div>
              <div className="start-button-container">
                <Link to="/schedule-demo">
                  <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    // onClick={(event) => this.props.setROIEnlargeView(!enlargeView)}
                  >
                    Schedule a demo
                  </Button>
                </Link>
                <Link to="/test">
                  <div>
                    <Button
                      color="primary"
                      size="small"
                      variant="outlined"
                      //  onClick={(event) => this.props.setROIEnlargeView(!enlargeView)}
                    >
                      View Available Neighborhoods
                    </Button>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </DocumentTitle>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return {};
}

const enhance = compose(
  withSnackbar,
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(NewAgentStart);
