import { green, red, blueGrey, grey, yellow, amber } from "@material-ui/core/colors";
import palette from "theme/palette";

export default theme => ({
  root: {},
  propertyFactHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  marginTopPropInfoButton: {
    marginTop: theme.spacing(1)
  },
  closeModalBtn: {
    display: 'flex',
    marginTop: -theme.spacing(1.5),
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
    marginRight: -theme.spacing(2.5),
  },
  closeredButton: {
    minWidth: theme.spacing(2.5),
    color: 'white',
    backgroundColor: red[900],
    textAlign: 'center',
    verticalAlign: 'middle',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: red[900],
      color: 'white',
    }
  },
  whiteColor: {
    color: 'white'
  },
  propertyDetailsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    '&>div': {
      padding: '10px 20px',
      '&>p': {
        marginTop: '2px',
        marginLeft: '4px',
        marginRight: '8px',
      }
    }
  },
  propertyListingDetails: {

  },
  propertyDetailsLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    '&>div:nth-child(2n+2)': {
      marginTop: theme.spacing(0.3),
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(1)
    }
  },
  propertyDetailsRow: {
    width: '100%',
    display: 'flex',
    justifyContent: ' flex-start',
    textAlign: 'center',
    alignItems: 'center'
  },
  propertyTopDetailsContainer: {
    marginTop: '10px',
    width: '100%',
  },
  topDetailsSection: {
    display: 'flex',
    flexDirection: 'column'
  },
  noteMoadlContainer: {
    minHeight: '200px'
  },
  propertyDetailsModal: {
    minHeight: '300px'
  },
  propertyDetailsModalMinWidth: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '850px',
    },
  },
  greenColor: {
    color: green[700]
  },
  redColor: {
    color: "#b82525"
  },
  darkRedColor: {
    color: red[900]
  },
  lightBlueBackground: {
    background: `${palette.latestLightGreen.main} !important`,
  },
  hoverBgLightBlue: {
    '&:hover': {
      background: `${palette.latestLightGreen.main} !important`,
    }
  },
  paleBackground: {
    background: '#3D85C6'
  },
  primaryColor: {
    color: palette.primary.main,
  },
  primaryBackground: {
    background: palette.primary.main,
  },
  lightYellowBackground: {
    background: yellow[50],
  },
  lightAmberBackground: {
    backgroundColor: amber[50],
  },
  lightGreenBackground: {
    backgroundColor: green[50],
  },
  // lightGreenBackground1: {
  //   backgroundColor: green[50],
  // },
  breakAll: {
    wordBreak: 'break-word'
  },
  lightBoldText: {
    fontWeight: '500'
  },
  boldText: {
    fontWeight: '800'
  },
  field: {
    width: '100%'
  },
  fullField: {
    width: '100%'
  },
  fieldMarginTop: {
    marginTop: theme.spacing(2),
  },
  fieldMarginBottom: {
    marginBottom: theme.spacing(2.5)
  },
  borderPadding44: {
    padding: '0 4px',
    borderRadius: '4px'
  },
  textField: {
    width: '100%',
  },
  customNoteDialogContent: {
    width: 'auto'
  },
  fontSizeRedText: {
    fontSize: '14px'
  },
  centerAlign: {
    textAlign: 'center'
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  tableRedText: {
    color: red[900],
    fontWeight: '600',
    fontSize: '13px',
    textAlign: 'center',
    cursor: 'pointer'
  },
  tableGreenText: {
    color: green[900],
    fontWeight: '600',
    fontSize: '13px',
    textAlign: 'center',
    cursor: 'pointer'
  },
  fullWidth: {
    width: '100%'
  },
  fitContentWidth: {
    width: 'fit-content'
  },
  cProgress: {
    display: 'block',
    //marginTop: theme.spacing(2.5),
    //marginBottom: theme.spacing(0.5),
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  cProgressLessTopMargin: {
    marginTop: theme.spacing(1.5),
  },
  cProgressStopBlinking: {
    minHeight: '60px',
    display: 'flex',
    alignItems: 'center'
  },
  fullWidthMessageDiv: {
    width: '100%',
    margin: theme.spacing(1, 0, 0, 0)
  },
  portletFooterWithMessageDiv: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    //paddingTop: theme.spacing(-1),//becuase portletFooter padding top is 2 but we want 1 and also do want to remove portletFooter from classes
    //paddingBottom: theme.spacing(1),
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  portletFooter: {
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    width: '100%'
  },
  footerButtons: {
    position: 'relative',
    display: 'flex'
  },
  footerButtonsCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  footerButtonsLeftRight: {
    flex: 1
  },
  endAlignemnt: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  extraMarginTableSection: {
    marginTop: theme.spacing(2)
  },
  fullWidthTableRow: {
    height: 'auto'
  },
  noBorderTableCell: {
    border: 'none'
  },
  tableMarginBottom: {
    marginBottom: theme.spacing(2)
  },
  warningPrimaryText: {
    color: palette.primary.main,
    fontWeight: 'bold'
  },
  boldFont: {
    fontWeight: 'bold'
  },
  redColorButton: {
    backgroundColor: red[900],
    border: red[900],
    color: 'white',
    '&:hover': {
      backgroundColor: red[900],
      border: red[900],
      color: 'white',
    }
  },
  approvalDialogTextContent: {
    padding: theme.spacing(1)
  },
  finishedButton: {
    color: 'white !important',
    backgroundColor: green[700],
    '&:hover': {
      color: 'white !important',
      backgroundColor: green[700],
    }
  },
  paleBlueButton: {
    color: 'white !important',
    backgroundColor: '#3D85C6',
    '&:hover': {
      color: 'white !important',
      backgroundColor: '#3D85C6',
    }
  },
  unapproveAreaDivDialog: {
    color: 'white',
    display: 'flex',
    padding: theme.spacing(0.5),
    textAlign: 'left',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    backgroundColor: red[900],
    padding: theme.spacing(1),
    borderRadius: '4px'
  },
  greetingText: {
    color: '#004fac',
    fontWeight: 500
  },
  textCountSize: {
    fontSize: '14px'
  },
  atTheRate: {
    fontSize: '16px',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  dialogContentPadding: {
    //paddingTop: theme.spacing(4),
  },
  dialogContentNoPadding: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonDanger: {
    color: red[900],
    borderColor: red[900],
    padding: `2px 4px`,
    // backgroundColor: red[700],
    '&:hover': {
      borderColor: red[900],
    },
  },
  buttonDangerBlocked: {
    color: '#ec8080',
    borderColor: '#ec8080',
    padding: `2px 4px`,
    // backgroundColor: red[700],
    '&:hover': {
      borderColor: '#ec8080',
    },
  },
  buttonSuccess: {
    color: '#8fd046',
    borderColor: '#8fd046',
    padding: `2px 4px`,
    // backgroundColor: green[700],
    '&:hover': {
      borderColor: '#8fd046',
    },
  },
  buttonProgress: {
    color: palette.common.primary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  hoGrid: {
    margin: theme.spacing(1, 0, 0, 0)
  },
  hoOnlyDialog: {
    margin: theme.spacing(3, 0, 3, 0)
  },
  bottom1Margin: {
    marginBottom: theme.spacing(1)
  },
  //edit ho name styles
  hoNameTextSection: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: theme.spacing(0),
    marginBottom: '8px'
  },
  topNameDialogBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    //padding: theme.spacing(1),
    padding: '0 8px',
    borderTop: `1px solid ${palette.border}`
  },
  hoTableNames: {
    padding: '4px',
    borderRadius: '4px',
    width: 'fit-content',
    color: '#666666'
  },
  hoNameTextSectionTop: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  hoNameTextSectionMarginBottom: {
    marginBottom: theme.spacing(2)
  },
  //BLOCK HO
  blockListTextSection: {
    marginBottom: theme.spacing(3)
  },
  blockReasonList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  blockReasonListItemText: {
    '&>span': {
      fontSize: '15px',
      fontWeight: '400'
    },
    '&>p': {
      marginTop: theme.spacing(0.5),
      fontSize: '14px'
    }
  },
  chooseResonText: {
    fontSize: '16px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  tagsDefaulltMapGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto',
    //gap: '10px',
    alignItems: 'start',
    gridTemplateColumns: '50% auto',
    gridTemplateRows: 'max-content',
    marginBottom: theme.spacing(0.5)
  },
  tagsDefaulltMapSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: 'white'
  },
  tagCustomContDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '95%',
  },
  tagCustomContDivEdit: {
    //background: `${palette.latestLightGreen.main}`,
    borderRadius: '4px',
    minHeight: theme.spacing(5.5),
    height: 'fit-content',
    marginBottom: theme.spacing(0.5),
    padding: '8px',
    border: `2px solid ${palette.latestLightGreen.dark}`,
    '&>div:nth-child(1)': {
      width: '60%'
    },
    '&>div:nth-child(2)': {
      marginTop: theme.spacing(2)
    }
  },
  dense0margin: {
    margin: 0
  },
  characterCounter: {
    width: '100%',
    textAlign: 'center',
  },
  characterCounterText: {
    fontSize: '12px'
  },
  addCustomTagButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  hoaAreaSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    justifyContent: 'center'
  },
  customHoaSectionTop: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  customHoaSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  hoatTextField: {
    width: '100%'
  },
  customSectionCheckbox: {
    marginTop: -theme.spacing(1.5)
  },
  hosFooterContent: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  grammerInnerContainer: {
    marginRight: theme.spacing(4)
  },
  approvalFooter: {
    paddingLeft: theme.spacing(2),
    minHeight: '64px'
  },
  mailignField: {
    //width: '100%',
    margin: theme.spacing(0, 2, 0.5, 0)
  },
  mailign2BottomSpace: {
    //width: '100%',
    marginBottom: theme.spacing(2)
  },
  mailingDivider: {
    margin: theme.spacing(1.5, 0, 1.5, 0)
  },
  currentMailingAddressDiv: {
    width: 'fit-content',
    border: `1px solid ${theme.palette.border}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    //color: theme.palette.text.secondary,
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
  currentMailingAddressDiv: {
    display: 'flex',
    aflexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    margin: theme.spacing(0.5, 0, 0.5, 0),
    fontSize: '14px'
  },
  currentMailingAddressDivmMiddle: {
    width: '60%',
    marginRight: theme.spacing(2)
  },
  cMADivmDivider: {
    margin: theme.spacing(0.5, 2, 0.5, 2),
    color: blueGrey[600]
  },
  cMADivmLast: {
    marginLeft: theme.spacing(1)
  },
  requiredFieldMessage: {
    color: palette.error.main,
    fontWeight: 500
  },
  mailingDialogSiteAddress: {
    margin: theme.spacing(-1, 0, 0, 0)
  },
  noteNotInCurrentMailingDiv: {
    border: `1px solid #DFE3E8`,
    borderRadius: '4px',
    marginBottom: '8px',
  },
  noteNotInCurrentMailingRedColor: {
    color: red[900]
  },
  noteNotInCurrentMailingUnderline: {
    textDecoration: 'underline'
  },
  highlightEHOGrid: {
    '&>*': {
      background: '#3D85C6',
      padding: theme.spacing(0.5, 1, 0.5, 1),
      //bordeRadius: 4px;
      color: 'white',
      height: '20px',
      display: 'flex',
      alignItems: 'center'
    },
    '&>*:nth-child(1)': {
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px'
    },
    '&>*:nth-child(2)': {
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px'
    }
  },
  hoIssuesGrid: {
    margin: theme.spacing(0, 2, 2, 2)
  },
  pmaIssues16pxFont: {
    fontSize: '16px'
  },
  backDropBackgroundDark: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)'
  },
  disabledInput: {
    background: 'rgba(0, 0, 0, 0.09)',
    pointerEvents: 'none'
  },
  areaChooserMain: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '150px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  areaChooserHeading: {
    fontSize: '15px'
  },
  marginTop0_5em: {
    marginTop: '0.5em'
  },
  leavAsIsBtn: {
    border: `1px solid ${palette.latestLightGreen.dark}`,
    borderRadius: '4px',
    cursor: 'pointer',
    padding: theme.spacing(0.5, 1),
    '&:hover': {
      color: 'white',
      backgroundColor: palette.latestLightGreen.dark
    }
  },
  nicknameEdited: {
    backgroundColor: palette.success.light
  },
  customTooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: '500'
  },
  richEditorButton: {
    height: theme.spacing(3), 
    marginBottom: '-8px', 
    background: '#3D85C6', 
    pointerEvents: 'auto',
    textTransform: "uppercase",
    '&:hover': {
      background: '#316a9e', 
    }
  },
  restoreGlobalPSButton: {
    textAlign: 'right', 
    color: '#3D85C6', 
    cursor: 'pointer', 
    marginTop: theme.spacing(0.5),
    fontSize: '14px', 
    fontWeight: 500,
    '&:hover': {
      color: '#316a9e', 
    }
  },
  editNoteButton: {
    background: green[400],
    height: theme.spacing(3), 
    marginBottom: '-8px', 
    pointerEvents: 'auto',
    textTransform: "uppercase",
    '&:hover': {
      background: green[600], 
    }
  },
  newPropertyHO: {
    border: `1px solid #b82525`,
    padding: '4px',
    width: 'max-content',
    borderRadius: '4px',
    color: '#b82525',
    marginTop: '8px'
  }
});
