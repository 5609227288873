import React, { Component } from "react";
import QVFourSecondLeft from "./QVFourSecondLeft";
import QVFourSecondRight from "./QVFourSecondRight";
import YETwoFirstLeft from "views/Pma/PmaEditor/YearEnd/YETwo/YETwoFirstPage/YETwoFirstLeft";
import YETwoSecondRight from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoSecondPage/YETwoSecondRight";
import YEOneSecondPageLeft from "views/Pma/PmaEditor/YearEnd2022/YEOne/YEOneSecondPage/YEOneSecondPageLeft";
import QVThreeSecondLeftMarketIndicator from "views/Pma/PmaEditor/Quarterly/QVThree/QVThreeSecondPage/QVThreeSecondLeftMarketIndicator";

class QVFourSecondPage extends Component {
  render() {
    const {
      auth,
      agentData,
      hoData,
      currentArea,
      printVersion,
      version,
      mlsAreas,
      flipBook,
      quarterlyAreaTable,
      quarterlyCityTable,
      propertyPhotoDetailsDTO,
      isDemo,
      MQCityDetails,
      photoTableData,
      oldPhotoDetails,
      yearEnd,
      aprilData,
    } = this.props;

    return (
      <div
        className={
          "pma-page-container " +
          (printVersion
            ? "qv4-print-page-container " +
              (flipBook ? "flipbook" : "regular-print-page")
            : "qv4-pma-editor-page-container")
        }
      >
        {/*<YEOneSecondPageLeft
          agentData={agentData}
          yearEnd={yearEnd}
          currentArea={currentArea}
        />*/}

        <QVThreeSecondLeftMarketIndicator
          currentArea={currentArea}
          hoData={hoData}
          agentData={agentData}
          aprilData={aprilData}
          printVersion={printVersion}
        />

        {/*<QVFourSecondLeft
            currentArea={currentArea}
            hoData={hoData}
            agentData={agentData}
            printVersion={printVersion}
            quarterlyAreaTable={quarterlyAreaTable}
            quarterlyCityTable={quarterlyCityTable}
          />*/}

        <YETwoSecondRight
          currentArea={currentArea}
          agentData={agentData}
          printVersion={printVersion}
          version={version}
          mlsAreas={mlsAreas}
          hoData={hoData}
          quarterlyAreaTable={quarterlyAreaTable}
          propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
          oldPhotoDetails={photoTableData}
          photoTableData={photoTableData}
          isDemo={isDemo}
          MQCityDetails={MQCityDetails}
        />
        {/*<QVFourSecondRight
          currentArea={currentArea}
          auth={auth}
          agentData={agentData}
          printVersion={printVersion}
          version={version}
          mlsAreas={mlsAreas}
          hoData={hoData}
          quarterlyAreaTable={quarterlyAreaTable}
          isDemo={isDemo}
          MQCityDetails={MQCityDetails}
          propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
          oldPhotoDetails={photoTableData}
          photoTableData={photoTableData}
        />*/}
      </div>
    );
  }
}

export default QVFourSecondPage;
