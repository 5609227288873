import React, { Fragment, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Checkbox, FormControl, FormControlLabel, FormLabel, Input, InputLabel, ListItemText, makeStyles, MenuItem, Radio, RadioGroup, Select, Tooltip, Typography } from '@material-ui/core';
import { cyan, indigo, red, teal, blue, pink, purple } from '@material-ui/core/colors';
import cancelImage from '../PmaMailingList/cancel-button.png';
import { renderLogicUtil } from 'views/Pma/RenderLogicUtil';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    advanceFilterContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    dialogTitle: {
        fontSize: '16px',
        textAlign: 'center',
        color: theme.palette.primary.main
    },
    clickableButton: {
        color: '#3D85C6',
        cursor: 'pointer',
        "&:hover": {
            textDecoration: "underline"
        }
    },
    resetButton: {
        color: red[500],
        cursor: 'pointer',
    },
    footerButtons: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px'
    },
    imageHolderContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginRight: '-18%',
        marginTop: '-40px'
    },
    imageHolder: {
        width: '48px'
    },
    image: {
        width: '100%',
        cursor: 'pointer',
    },
    underline: {
        textDecoration: 'underline'
    },
    redColor: {
        backgroundColor: '#b82525',
        border: '#b82525',
        color: 'white',
        '&:hover': {
            border: '#b82525',
            color: 'white',
        }
    },
    dropdownFormControl: {
        minWidth: 120,
        maxWidth: 550,
    },
    disclaimer: {
        color: purple[400],
        marginTop: "8px",
        marginBottom: "8px",
        fontSize: '11px'
    },
    filterByHeading: {
        color: pink[600],
        marginBottom: '8px'
    },
    gridContainer: {
        margin: "20px auto",
        width: "100%",
        height: "auto",
        backgroundColor: "#fff",
        display: "grid",
        gridTemplateColumns: "50% 50%",
        gridRow: "auto auto",
        gridColumnGap: "2px",
        gridRowGap: "2px",
        "&>div:first-child": {
            backgroundColor: `${cyan[100]} !important`
        },
        "&>div:nth-child(2)": {
            backgroundColor: cyan[100]
        },
        "&>div:nth-child(3)": {
            backgroundColor: cyan[100]
        },
        "&>div:nth-child(4)": {
            backgroundColor: cyan[100]
        },
        '&>.box': {
            padding: "20px",
            color: "#000",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            fontSize: "11px",
        },
    }
}))

export default function MailingHeaderAdvanceFilterBox({ updateHOAdvanceFilter, currentHOFilterLabel, hoAdvanceFilter, updateHOAdvanceFilterOptionData, propertyData, agentData }) {
    const [open, setOpen] = React.useState(false);
    const [currentFilter, setFilter] = React.useState(hoAdvanceFilter || null);
    const [allPropertyOptionData, setAllPropertyOptionaData] = React.useState({ property_tags: [], property_types: [] });
    const [selectedProeprtyOptionaData, setSelectedOptionaData] = React.useState({ property_tags: [], property_types: [] })
    const classes = useStyles();

    useEffect(() => {
        setFilter(hoAdvanceFilter || null);
    }, [hoAdvanceFilter]);

    useEffect(() => {
        //async processes
        if (open) {
            const allPropertyOptionsData = renderLogicUtil.getAllAdvanceFilterOptionsSet(propertyData, agentData)
            setAllPropertyOptionaData(allPropertyOptionsData);
        }
    }, [open])


    const handleClickOpen = (open = true) => {
        setOpen(open);
    };

    const handleClose = () => {
        updateHOAdvanceFilter(currentFilter);
        updateHOAdvanceFilterOptionData({ [currentFilter]: selectedProeprtyOptionaData[currentFilter] })
        setTimeout(() => {
            setOpen(false);
        }, 500);

    };

    const handleChange = (event) => {
        setFilter(event.target.value)
    }

    const resetFilter = () => {
        updateHOAdvanceFilter(null);
        updateHOAdvanceFilterOptionData(null);
        setTimeout(() => {
            setOpen(false);
        }, 500);
    }

    const handleSelectedFilterData = (filterType, event) => {
        const value = event.target.value;
        if (value.length > 0) {
            setFilter(filterType)
        } else {
            setFilter(hoAdvanceFilter || null)
        }
        setSelectedOptionaData({ ...selectedProeprtyOptionaData, ...{ [filterType]: value } });
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <div>
            <div className={classes.advanceFilterContainer}>
                <div onClick={handleClickOpen} className={classes.clickableButton}>
                    Apply Advanced Filters
                </div>
                {hoAdvanceFilter &&
                    <Tooltip title="Reset Advance Filters">
                        <div
                            onClick={resetFilter}
                            style={{ marginLeft: '4px', cursor: 'pointer', color: red[600], textDecoration: 'underline' }}
                        >
                            <CloseIcon />
                        </div>
                    </Tooltip>
                }
                <div></div>
            </div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth={'lg'}
                fullWidth={true}


            >
                <DialogTitle id="alert-dialog-slide-title" classes={{ root: classes.dialogTitle }}>
                    <Typography variant="h4" color="primary">SELECT AN ADVANCED FILTER</Typography>
                </DialogTitle>
                <div className={classes.imageHolderContainer}>
                    <div className={classes.imageHolder} style={{ width: '-18%' }} onClick={event => handleClickOpen(false)}>
                        <img
                            src={cancelImage}
                            alt="Close dialog"
                            className={classes.image}
                            onClick={event => handleClickOpen(false)}
                        />
                    </div>
                </div>
                <DialogContent>
                    <div className={classes.gridContainer}>
                        <div className="box">
                            <div>
                                <div>
                                    <Typography variant='subtitle2' className={classes.filterByHeading}>Filter by Homeowners</Typography>
                                </div>
                                <div>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="gender" name="gender1" value={currentFilter} onChange={handleChange}>
                                            <FormControlLabel value="property_first_ho" control={<Radio />} label={<Fragment>All properties with <span className={classes.underline}>1 homeowner name only</span></Fragment>} />
                                            <FormControlLabel value="property_both_ho" control={<Radio />} label={<Fragment>All properties with <span className={classes.underline}>2 homeowner’s names</span></Fragment>} />
                                            <FormControlLabel value="property_ho_not_modified" control={<Radio />} label={<Fragment>All homeowner’s <span className={classes.underline}>names that have not been PERSONALIZED by me</span></Fragment>} />
                                            <FormControlLabel value="property_ho_modified" control={<Radio />} label={<Fragment>All homeowner’s <span className={classes.underline}>names that have been PERSONALIZED by me</span></Fragment>} />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </div>

                        </div>
                        <div className="box">
                            <div>
                                <div>
                                    <Typography variant='subtitle2' className={classes.filterByHeading}>Filter by TAGS</Typography>
                                </div>
                                <div>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="gender" name="gender1" value={currentFilter} onChange={handleChange}>
                                            <FormControlLabel value="added_comments" control={<Radio />} label={<Fragment>All homeowners that <span className={classes.underline}>i have added COMMENTS</span> to</Fragment>} />
                                            <FormControlLabel value="property_tags" control={<Radio />} label={<Fragment>Select TAGS to filter</Fragment>} />
                                            <FormControl className={classes.dropdownFormControl} disabled={currentFilter !== "property_tags"}>
                                                <InputLabel id="tags-mutiple-checkbox-label">Select Tags and Apply</InputLabel>
                                                <Select
                                                    labelId="tags-mutiple-checkbox-label"
                                                    id="tags-mutiple-checkbox"
                                                    multiple
                                                    value={selectedProeprtyOptionaData.property_tags}
                                                    onChange={(event) => handleSelectedFilterData("property_tags", event)}
                                                    input={<Input />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                >
                                                    {allPropertyOptionData.property_tags.map((name) => (
                                                        <MenuItem key={name} value={name} title={name}>
                                                            <Checkbox checked={selectedProeprtyOptionaData.property_tags.indexOf(name) > -1} />
                                                            <ListItemText primary={name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <div className={classes.disclaimer}>Note:- Showing TAGS from {currentHOFilterLabel && currentHOFilterLabel.toUpperCase()} properties only.</div>
                                                {currentFilter === 'property_tags' && selectedProeprtyOptionaData.property_tags.length > 0 &&
                                                    <div style={{ marginTop: '8px' }}>
                                                        <strong>Selected Tags</strong>: {selectedProeprtyOptionaData.property_tags.join(', ')}
                                                    </div>}
                                            </FormControl>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div>
                                <div>
                                    <Typography variant='subtitle2' className={classes.filterByHeading}>Filter by Notes</Typography>
                                </div>
                                <div>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="gender" name="gender1" value={currentFilter} onChange={handleChange}>
                                            <FormControlLabel value="property_pn_not_ps" control={<Radio />} label={<Fragment>All homeowners that <span className={classes.underline}>have a Personal Note but no Personal P.S.</span></Fragment>} />
                                            <FormControlLabel value="property_not_pn_ps" control={<Radio />} label={<Fragment>All homeowners that have a <span className={classes.underline}>Personal P.S. but no Personal Note</span></Fragment>} />
                                            <FormControlLabel value="property_not_pn_not_ps" control={<Radio />} label={<Fragment>All homeowners that <span className={classes.underline}>do not have a Personal Note or a Personal P.S.</span></Fragment>} />
                                            <FormControlLabel value="property_pn_ps" control={<Radio />} label={<Fragment>All homeowners that have <span className={classes.underline}>both a Personal Note and a Personal P.S.</span></Fragment>} />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div>
                                <div>
                                    <Typography variant='subtitle2' className={classes.filterByHeading}>Filter by Property Types</Typography>
                                </div>
                                <div>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="gender" name="gender1" value={currentFilter} onChange={handleChange}>
                                            <FormControlLabel value="adu_properties" control={<Radio />} label={<Fragment>All <span className={classes.underline}>Properties with ADUs</span></Fragment>} />
                                            <FormControlLabel value="property_types" control={<Radio />} label={<Fragment>Select Property types to filter</Fragment>} />
                                            <FormControl className={classes.dropdownFormControl} disabled={currentFilter !== "property_types"}>
                                                <InputLabel id="tags-mutiple-checkbox-label">Select Property Types and Apply</InputLabel>
                                                <Select
                                                    labelId="tags-mutiple-checkbox-label"
                                                    id="tags-mutiple-checkbox"
                                                    multiple
                                                    value={selectedProeprtyOptionaData.property_types}
                                                    onChange={(event) => handleSelectedFilterData("property_types", event)}
                                                    input={<Input />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                >
                                                    {allPropertyOptionData.property_types.map((name) => (
                                                        <MenuItem key={name} value={name} title={name}>
                                                            <Checkbox checked={selectedProeprtyOptionaData.property_types.indexOf(name) > -1} />
                                                            <ListItemText primary={name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {currentFilter === 'property_types' && selectedProeprtyOptionaData.property_types.length > 0 &&
                                                    <div style={{ marginTop: '8px' }}>
                                                        <strong>Selected Property Types</strong>: {selectedProeprtyOptionaData.property_types.join(', ')}
                                                    </div>}
                                            </FormControl>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className={classes.footerButtons}>
                        <div>
                            <Button onClick={event => handleClickOpen(false)} variant="contained" color="default">
                                CANCEL
                            </Button>
                        </div>
                        <Button onClick={handleClose} variant="contained" color="primary">
                            OK, APPLY SELECTED FILTERS
                        </Button>
                        <div></div>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
