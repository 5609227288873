import React, {
  Component,
  Fragment,
  useState,
  useEffect,
  useContext,
} from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles2, { getPHListStyle, getDDItemStyle } from "../styles";
import styles from "./styles";
import "./styles.scss";
import { Dashboard as DashboardLayout } from "layouts";
import { withSnackbar } from "notistack";
import { Portlet } from "components";
import { PortletContent } from "components";
import { PortletHeader } from "components";
import classNames from "classnames";
import { ImageUploadDialog } from "components";
import { GetAreaPropHighlights } from "services/others";
import Base from "common/constants";
import { GetStateStorage, IsValidSessionSwitch } from "common/storage";
import moment from "moment";
import { GetPmaAdminDataAPI } from "services/agent";
import {
  UpdatePropertyHighlights,
  PropertyHighlightPhotoOptimization,
  UpdatePropertyHighlightsForSingleArea,
} from "services/agent";
import { showErrorNotification, showInfoNotification } from "common/helpers";
import { showSuccessNotification } from "common/helpers";
import { withRouter } from "react-router-dom";
import { Store } from "Store";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdfOutlined";
import { MediaViewComponent } from "components";
import DocumentTitle from "react-document-title";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { propertyUtil } from "views/Pma/PropertyUtil";
import HighlightRow from "./HighlightRow";
import PropHighlightsEditProperty from "./PropHighlightsEditProperty";
import PropHighlightsAddProperty from "./PropHighlightsAddProperty";
import YETwoPropertyHighlights from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoFirstPage/YETwoPropertyHighlights";
import ErrorModal from "./ErrorModal";
import HighlightTitleDialog from "./HighlightTitleDialog";
import HighlightFooterDialog from "./HighlightFooterDialog";
import { isUserAdmin } from "common/roles/role";
require("../../Pma/PmaEditor/YearEnd/YETwo/ye2styles.scss");

const PropertyHighlights = (props) => {
  const { classes } = props;
  const { dispatch } = useContext(Store);
  const isAValidSessionSwitch = IsValidSessionSwitch();
  const { userData } = GetStateStorage();
  const regexWithPDF = new RegExp(/^.*\.(PDF|pdf)$/);
  const maxHighlightTitleLength = 52;
  const maxHighlightSubtitleLength = 360;
  const maxHighlightFooter1Length = 110; //60
  const maxHighlightFooter2Length = 30;
  const maxPropertiesNum = 4;

  const [showEditDialog, setEditDialog] = React.useState(false);
  const [showAddPropDialog, setAddPropDialog] = React.useState(false);
  const [showHighlightTitleDialog, setHighlightTitleDialog] = React.useState(
    false
  );
  const [showHighlightFooterDialog, setHighlightFooterDialog] = React.useState(
    false
  );
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalText, setErrorModalText] = useState("");
  const [goToPma, setGoToPma] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [singleAreaSubmit, setSingleAreaSubmit] = useState(false);
  const [openUploadImageDialog, setUploadImageDialog] = React.useState(false);
  const [openViewImageDialog, setViewImageDialog] = React.useState(false);
  const [uploadImageKey, setUploadImageKey] = React.useState(null);
  const [uploadCurrentImage, setUploadCurrentImage] = React.useState(null);
  const [oldTitle, setOldTitle] = React.useState({
    propertyHighlightsTitle: "",
    propertyHighlightsSubtitle: "",
  });
  const [oldFooter, setOldFooter] = React.useState({
    propertyHighlightsFooterText1: "",
    propertyHighlightsFooterText2: "",
  });
  const [editPropFormData, setEditPropFormData] = React.useState({
    address: "",
    bedBaths: "",
    brokerageName: "",
    cityName: "",
    daysOnMarket: "",
    displayOrder: "",
    isBuyingAgent: false,
    isSystemSelected: false,
    isSellingAgent: true,
    listDate: "",
    listPrice: "",
    listingAgentLicenseID: "",
    listingAgentName: "",
    lotSizeSquareFt: "",
    photoURL: "",
    saleDate: "",
    salePrice: "",
    sellingAgentLicenseID: "",
    sellingAgentName: "",
    squareFeet: "",
    status: "",
    streetDirPrefix: "-",
    streetName: "",
    streetNumber: "",
    streetSuffix: "",
    unitNumber: "",
    view: "",
    justAdded: false,
  });

  const [oldPropData, setOldPropData] = React.useState({
    address: "",
    bedBaths: "",
    brokerageName: "",
    cityName: "",
    daysOnMarket: "",
    displayOrder: "",
    isBuyingAgent: false,
    isSystemSelected: false,
    isSellingAgent: true,
    listDate: "",
    listPrice: "",
    listingAgentLicenseID: "",
    listingAgentName: "",
    lotSizeSquareFt: "",
    photoURL: "",
    saleDate: "",
    salePrice: "",
    sellingAgentLicenseID: "",
    sellingAgentName: "",
    squareFeet: "",
    status: "",
    streetDirPrefix: "-",
    streetName: "",
    streetNumber: "",
    streetSuffix: "",
    unitNumber: "",
    view: "",
    justAdded: false,
  });
  const emptyProp = {
    address: "",
    bedBaths: "",
    brokerageName: "",
    cityName: "",
    daysOnMarket: "",
    displayOrder: "",
    isBuyingAgent: false,
    isSystemSelected: false,
    isSellingAgent: true,
    listDate: "",
    listPrice: "",
    listingAgentLicenseID: "",
    listingAgentName: "",
    lotSizeSquareFt: "",
    photoURL: "",
    saleDate: "",
    salePrice: "",
    sellingAgentLicenseID: "",
    sellingAgentName: "",
    squareFeet: "",
    status: "",
    streetDirPrefix: "-",
    streetName: "",
    streetNumber: "",
    streetSuffix: "",
    unitNumber: "",
    view: "",
    justAdded: false,
  };
  const [propHighlightData, setPropHighlightData] = React.useState([]);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const isAdminAccess =
    isAValidSessionSwitch ||
    isUserAdmin(userData.listgenRoleID);
  //
  // const reorderPhotoList = (photoDetails) => {
  //   console.log("inside reorder", photoDetails);
  //
  //   let selected = photoDetails.filter((prop) => prop.isSystemSelected === true);
  //   if (selected.length === 0) {
  //     console.log("****no selected here");
  //     //  let mapToIndex = selected.map((prop, index) => (prop.displayOrder = index));
  //   }
  //   let unselected = photoDetails.filter((prop) => prop.isSystemSelected === false);
  //   let propsNeeded = 5 - selected.length;
  //   if (selected.length < 5) {
  //     let removeTopSales = unselected.slice(0, propsNeeded);
  //     let makeSelected = removeTopSales.map(
  //       (prop) => (prop = { ...prop, isSystemSelected: true })
  //     );
  //     selected = [...selected, ...makeSelected];
  //   }
  //   let sortSelected = selected.map(
  //     (prop, index) => (prop = { ...prop, displayOrder: index })
  //   );
  //   let transformUnselected = unselected
  //     .slice(propsNeeded)
  //     .map(
  //       (prop, index) =>
  //         (prop = { ...prop, displayOrder: index + selected.length })
  //     );
  //   let updatedPropInfo = [...sortSelected, ...transformUnselected];
  //
  //   return updatedPropInfo;
  // };

  const reorderSelected = (photoDetails) => {
    let selected = photoDetails.filter(
      (prop) => prop.isSystemSelected === true
    );

    if (selected.length === 0) {
      let createSelected = photoDetails
        .slice(0, maxPropertiesNum)
        .map((prop) => (prop.isSystemSelected = true));
    }
    if (selected.length === 5) {
      let foundCount = 0;

      for (let i = 0; i < photoDetails.length; i++) {
        let property = photoDetails[i];
        if (foundCount === 4) {
          photoDetails[i].isSystemSelected = false;
          foundCount++;
        } else if (foundCount < 5) {
          foundCount++;
        }
      }
    }
    let unselected = photoDetails.filter(
      (prop) => prop.isSystemSelected === false || !prop.isSystemSelected
    );

    let selectedWithSameOrder = selected
      .map((prop) => prop.displayOrder)
      .filter((order) => order === 0).length;
    if (selectedWithSameOrder > 1) {
      let sortSelected = selected.map(
        (prop, index) => (prop = { ...prop, displayOrder: index })
      );
      return [...sortSelected, ...unselected];
    } else {
      return photoDetails;
    }
  };
  // const reorderSelected = (photoDetails) => {
  //   console.log("REORDER SELECTED photoDetails", photoDetails);
  //   let selected = [];
  //
  //   for (let i = 0; i < photoDetails.length; i++) {
  //     let property = photoDetails[i];
  //     console.log("property is", property.isSystemSelected);
  //     let isChecked = property["isSystemSelected"] === true;
  //
  //     console.log("**is checked", isChecked);
  //     if (property["isSystemSelected"] == true) {
  //       console.log("555is true");
  //       selected.push(property);
  //     }
  //   }
  //   // let selectedSecond = propHighlightData.photoDetails.filter(
  //   //   (property) => property.isSystemSelected === true
  //   // );
  //   //console.log("selectedSecond", selectedSecond);
  //   // photoDetails.slice().filter((prop) => {
  //   //   console.log("prop", prop.isSystemSelected);
  //   //   let isPropSelected =
  //   //     prop.isSystemSelected && prop.isSystemSelected === true;
  //   //   console.log("isPropSelected", isPropSelected);
  //   //   return isPropSelected;
  //   // }) || [];
  //   console.log("selected", selected);
  //
  //   // const selected = photoDetails
  //   //   .slice()
  //   //   .filter((prop) => prop.isSystemSelected == true)
  //   //   .slice(0, 4);
  //   // console.log("selected", selected);
  //
  //   if (selected.length === 0) {
  //     console.log("****selected is 0");
  //     // photoDetails.map((prop) => prop.isSystemSelected == false);
  //     let createSelected = photoDetails
  //       .slice(0, maxPropertiesNum)
  //       .forEach((prop) => (prop.isSystemSelected = true));
  //     console.log("createSelected", createSelected);
  //   }
  //
  //   let unselected = photoDetails.filter(
  //     (prop, index) =>
  //       prop.isSystemSelected === false ||
  //       (prop.isSystemSelected === true && index === 4)
  //   );
  //
  //   for (let i = 0; i < unselected.length; i++) {
  //     let prop = unselected[i];
  //     console.log("**prop", prop);
  //     if (prop.isSystemSelected === true) {
  //       prop.isSystemSelected = false;
  //     }
  //   }
  //
  //   console.log("unselected", unselected);
  //
  //   let selectedWithSameOrder = selected
  //     .slice()
  //     .map((prop) => prop.displayOrder)
  //     .filter((order) => order === 0).length;
  //   console.log("***selectedWithSameOrder", selectedWithSameOrder);
  //   if (selectedWithSameOrder > 1) {
  //     console.log("same order");
  //     let sortSelected = selected
  //       .slice()
  //       .map((prop, index) => (prop = { ...prop, displayOrder: index }));
  //     console.log("***sortSelected", sortSelected);
  //     let newArray = [...sortSelected, ...unselected];
  //     console.log("newArray", newArray);
  //     return newArray;
  //   } else {
  //     console.log("selected is", selected);
  //     console.log("unselected", unselected);
  //     let sortSelected = selected
  //       .slice()
  //       .map((prop, index) => (prop = { ...prop, displayOrder: index }));
  //     console.log("else is", sortSelected);
  //     return [...sortSelected, ...unselected];
  //   }
  // };
  const getAreaPropHighlights = () => {
    const { roundSalePrice } = propertyUtil;
    const { isMemberOfTeam, listgenUserID } = userData;
    setIsLoading(true);
    const thisArea = props.match.params.areaID;
    //use the below for single areas
    GetAreaPropHighlights(userData.listgenUserID, thisArea)
      // GetAreaPropHighlights(userData.listgenUserID, userData.ownedMLSID[0])
      .then((data) => {
        if (Object.keys(data).length > 0) {
          const {
            photoDetails,
            homesSoldPreviousYear,
            homesSoldCurrentYear,
            totalSalePricePreviousYear,
            totalSalePriceCurrentYear,
            propertyHighlightsTitle,
            propertyHighlightsSubtitle,
            propertyHighlightsFooterText1,
            propertyHighlightsFooterText2,
          } = data;

          let updatedPropInfo = reorderSelected(photoDetails);

          let uniqueProps = getUniqueProps(updatedPropInfo);
          let isDianneJohnson = listgenUserID === "100988";
          let propSoldTotal = +totalSalePriceCurrentYear;
          let constructedTitle;
          // let constructedTitle = `A sampling of some of the beautiful homes ${
          //   isMemberOfTeam || isDianneJohnson ? "we" : "I"
          // } represented for both Sellers and Buyers`;
          let constructedSubtitle;
          // let constructedSubtitle =
          //   "A Notable Year of High Home Prices, Fast Transactions, and Happy Homeowners";
          let hasTitle =
            propertyHighlightsTitle && propertyHighlightsTitle.length > 1
              ? true
              : false;
          let hasSubtitle =
            propertyHighlightsSubtitle && propertyHighlightsSubtitle.length > 1
              ? true
              : false;
          let updatedData = {
            ...data,
            photoDetails: uniqueProps,
            propertyHighlightsTitle: hasTitle
              ? propertyHighlightsTitle
              : constructedTitle,
            propertyHighlightsSubtitle: hasSubtitle
              ? propertyHighlightsSubtitle
              : constructedSubtitle,
          };
          setPropHighlightData(updatedData);
          setEditPropFormData({
            ...editPropFormData,
          });
        } else {
          setPropHighlightData([]);
        }
      })
      .catch((err) => {
        setPropHighlightData([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (
      propHighlightData &&
      Object.keys(propHighlightData).length > 1 &&
      isSubmitting === true
    ) {
      console.log("about to go to submit, in use effect", singleAreaSubmit);
      onPropHighlightSubmit(singleAreaSubmit);
    }
  }, [isSubmitting]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(propHighlightData).length === 0) {
      getAreaPropHighlights(userData.listgenUserID, userData.ownedMLSID[0]);
    }
  }, []);

  const openUploadMedia = async (key, currentImage) => {
    console.log("openUploadMedia", openUploadMedia);
    setUploadImageDialog(true);
    setUploadCurrentImage(currentImage);
    setUploadImageKey(key);
  };

  const handlePropertyHighlightUpload = (uploadedImageUrl) => {
    console.log("uploadedImageUrl", uploadedImageUrl);
    console.log("**current edit prop form data", editPropFormData);

    if (uploadedImageUrl) {
      let changedObj = {
        ...editPropFormData,
        photoURL: uploadedImageUrl.optimizedPhotoURL,
        optimizedPhotoURL: uploadedImageUrl.optimizedPhotoURL,
        justAdded: true,
      };
      setEditPropFormData(changedObj);
    } else {
      console.log("having an issue");
    }
    // if (uploadedImageUrl) {
    //   console.log("**changedObj", changedObj);
    //   setEditPropFormData(changedObj);
    //   setEditPropFormData({
    //     ...editPropFormData,
    //     photoURL: uploadedImageUrl.optimizedPhotoURL,
    //     optimizedPhotoURL: uploadedImageUrl.optimizedPhotoURL,
    //     justAdded: true,
    //   });
    // } else {
    //   console.log("having an issue");
    // }

    setUploadImageDialog(false);
    setUploadCurrentImage(null);
    setUploadImageKey(null);
    setShowError("");
    console.log("after adding photo", editPropFormData);
  };

  const openViewMedia = async (currentImage) => {
    setViewImageDialog(true);
    setUploadCurrentImage(currentImage);
  };

  const closeViewMedia = async () => {
    setViewImageDialog(false);
    setUploadCurrentImage(null);
  };

  const handleOpenEditDialog = (prop) => {
    console.log("opening edit dialog prop is", prop);
    const propObj = prop;
    setOldPropData({
      ...propObj,
      streetSuffix: propObj.streetSuffix === "-" ? "" : propObj.streetSuffix,
      unitNumber: propObj.unitNumber === "-" ? "" : propObj.unitNumber,
      salePrice: propObj.salePrice === "-" ? "1" : propObj.salePrice,
      listPrice: propObj.listPrice === "-" ? "1" : propObj.listPrice,
    });
    setEditPropFormData({
      ...propObj,
      streetSuffix: propObj.streetSuffix === "-" ? "" : propObj.streetSuffix,
      unitNumber: propObj.unitNumber === "-" ? "" : propObj.unitNumber,
      salePrice: propObj.salePrice === "-" ? "1" : propObj.salePrice,
      listPrice: propObj.listPrice === "-" ? "1" : propObj.listPrice,
    });
    setEditDialog(true);
  };

  const handleErrorModal = (type) => {
    if (type === "close") {
      setShowErrorModal(false);
      setErrorModalText("false");
    }
  };

  const isValidTopFiveForm = () => {
    let isValid = true;
    const {
      areaID,
      averageSalePricePreviousYear,
      averageSalePriceCurrentYear,
      countyID,
      homesSoldPreviousYear,
      homesSoldCurrentYear,
      listgenUserAreaID,
      listgenUserID,
      photoDetails,
      photoDetailsRunDate,
      propertyHighlightsSubtitle,
      propertyHighlightsTitle,
      propertyHighlightsFooterText1,
      propertyHighlightsFooterText2,
      totalSalePricePreviousYear,
      totalSalePriceCurrentYear,
    } = propHighlightData;

    // console.log(propHighlightData);
    // console.log(
    //   areaID,
    //   averageSalePricePreviousYear,
    //   averageSalePriceCurrentYear,
    //   countyID,
    //   homesSoldPreviousYear,
    //   homesSoldCurrentYear,
    //   listgenUserAreaID,
    //   listgenUserID,
    //   photoDetails,
    //   photoDetailsRunDate,
    //   propertyHighlightsSubtitle,
    //   propertyHighlightsTitle,
    //   propertyHighlightsFooterText1,
    //   propertyHighlightsFooterText2,
    //   totalSalePricePreviousYear,
    //   totalSalePriceCurrentYear
    // );
    if (
      !areaID ||
      // !averageSalePricePreviousYear ||
      // !averageSalePriceCurrentYear ||
      !countyID ||
      // !homesSoldPreviousYear ||
      // !homesSoldCurrentYear ||
      !listgenUserAreaID ||
      !listgenUserID ||
      !photoDetails ||
      !photoDetailsRunDate
      // !propertyHighlightsSubtitle ||
      // !propertyHighlightsTitle ||
      // propertyHighlightsFooterText1 ||
      // propertyHighlightsFooterText2 ||
      // !totalSalePricePreviousYear ||
      // !totalSalePriceCurrentYear
    ) {
      console.log("***false valid");
      isValid = false;
    }
    console.log("is Valid====", isValid);
    return isValid;
  };

  const handleFormClose = () => {
    setAddPropDialog(false);
    setEditDialog(false);
  };
  const onHighlightTitleClose = () => {
    setHighlightTitleDialog(false);
    setOldTitle({
      propertyHighlightsTitle: "",
      propertyHighlightsSubtitle: "",
    });
  };
  const onHighlightTitleOpen = () => {
    setHighlightTitleDialog(true);
    setOldTitle({
      propertyHighlightsTitle: propHighlightData.propertyHighlightsTitle,
      propertyHighlightsSubtitle: propHighlightData.propertyHighlightsSubtitle,
    });
  };
  const onHighlightFooterClose = () => {
    setHighlightFooterDialog(false);
    setOldFooter({
      propertyHighlightsFooterText1: "",
      propertyHighlightsFooterText2: "",
    });
  };
  const onHighlightFooterOpen = () => {
    console.log("inside highlightfooterOpen", propHighlightData);
    setHighlightFooterDialog(true);
    setOldFooter({
      propertyHighlightsFooterText1:
        propHighlightData.propertyHighlightsFooterText1,
      propertyHighlightsFooterText2:
        propHighlightData.propertyHighlightsFooterText2,
    });
  };
  const handleAddPropDialog = () => {
    let selected =
      (photoDetails &&
        photoDetails.filter(
          (property) => property.isSystemSelected === true
        )) ||
      [];
    if (selected.length >= maxPropertiesNum) {
      setErrorModalText("Max properties");
      setShowErrorModal(true);
    } else {
      setAddPropDialog(true);
      setEditPropFormData(emptyProp);
    }
  };

  const handleAddProperty = (oneAreaSubmit) => {
    const {
      streetNumber,
      streetName,
      cityName,
      salePrice,
      brokerageName,
      photoURL,
      status,
      listPrice,
    } = editPropFormData;
    console.log("***ediutpropformdata", editPropFormData);
    if (
      !streetNumber ||
      !streetName ||
      !cityName ||
      !status ||
      // (!salePrice && status === "Sold") ||
      // (!listPrice && (status === "Pending" || "Active" || "Contingent")) ||
      !brokerageName
      //|| !photoURL
    ) {
      console.log("about to be missing inputs");
      setErrorModalText("Missing inputs");
      setShowErrorModal(true);
    } else {
      let numberPattern = /\d+/g;
      //Removes all commas, dollar sign and non-numeric characters from user entered sale price
      let salePriceOnlyNumbers = (
        (editPropFormData.salePrice || 0) + ""
      ).replace(/\D/g, "");
      let selected = propHighlightData.photoDetails.filter(
        (property) => property.isSystemSelected === true
      );
      let changedObj = {
        ...editPropFormData,
        salePrice: salePriceOnlyNumbers || 0,
        isSystemSelected: true,
        displayOrder: selected.length,
      };
      setEditPropFormData(changedObj);
      setPropHighlightData({
        ...propHighlightData,
        photoDetails: [...propHighlightData.photoDetails, changedObj],
      });
      console.log("inside handle add property");
      setSingleAreaSubmit(oneAreaSubmit);
      setIsSubmitting(true);

      setAddPropDialog(false);
    }
  };
  const wholePageSubmit = (oneAreaSubmit) => {
    console.log("oneAreaSubmit", oneAreaSubmit);
    setSingleAreaSubmit(oneAreaSubmit);
    setIsSubmitting(true);
    setGoToPma(true);
  };
  const handleAddTitle = (overMaxTitle, overMaxSubtitle, oneAreaSubmit) => {
    console.log("inside handle add title");
    if (overMaxTitle || overMaxSubtitle) {
      setErrorModalText("Max title");
      setShowErrorModal(true);
    } else {
      setSingleAreaSubmit(oneAreaSubmit);
      setIsSubmitting(true);
      setHighlightTitleDialog(false);
    }
  };

  const handleAddFooter = (overMaxFooter1, overMaxFooter2, oneAreaSubmit) => {
    console.log("inside handle add footer");
    if (overMaxFooter1 || overMaxFooter2) {
      setErrorModalText("Max title");
      setShowErrorModal(true);
    } else {
      setSingleAreaSubmit(oneAreaSubmit);
      setIsSubmitting(true);
      setHighlightFooterDialog(false);
    }
  };

  const onPropHighlightSubmit = (useSingleAreaSubmit) => {
    const { photoDetails } = propHighlightData;
    let selected = photoDetails.filter(
      (property) => property.isSystemSelected === true
    );

    console.log("selected in submit", selected);

    let isValid = isValidTopFiveForm();
    console.log("isValid", isValid);
    console.log("is it valid in prophighlightsubmit?", isValid);
    if (!isValid) {
      console.log("is not valid);");
      setErrorModalText("Issue submitting form");
      setShowErrorModal(true);
    } else {
      console.log("in the else");
      //now start loading and submit the form
      try {
        // const stateData = GetStateStorage();
        // const agentData = stateData.userData;
        //check if state data not founf
        //const { mlsAreas, switchArea } = this.props;
        // /**Get session storage */
        // console.log("in the try block");
        //  let paramAreaID = this.props.match.params.areaID;
        // console.log("match****", this.props);
        // console.log("paramAreaID", paramAreaID);
        // let navbarData =
        //   agentData && Array.isArray(agentData.navbarInfo)
        //     ? agentData.navbarInfo.filter(
        //         (area) => area.mlsAreaID === paramAreaID
        //       )[0]
        //     : {};
        console.log("prophighlightData", propHighlightData);
        setBtnLoading(true);
        //use the below only for single area
        //UpdatePropertyHighlightsForSingleArea(propHighlightData)
        if (singleAreaSubmit || useSingleAreaSubmit) {
          console.log("updatingPropertyForSingleArea");
          UpdatePropertyHighlightsForSingleArea(propHighlightData)
            .then((data) => {
              console.log("data is", data);
              showSuccessNotification(
                props,
                `Your property highlights have been saved`
              );
              setBtnLoading(false);
              //
              setIsSubmitting(false);
              // console.log("props before set timeout", props);
              if (goToPma) {
                setTimeout(() => {
                  props.history.push(`/pma/${props.match.params.areaID}`);
                }, 1300);
              }
            })
            .catch((err) => {
              console.log("in another catch");
              showErrorNotification(
                props,
                "Something went wrong while processing your request."
              );
              setBtnLoading(false);
            });
        } else {
          console.log("updating property for all areas");
          UpdatePropertyHighlights(propHighlightData)
            .then((data) => {
              console.log("data is", data);
              showSuccessNotification(
                props,
                `Your property highlights have been saved`
              );
              setBtnLoading(false);
              //
              setIsSubmitting(false);
              // console.log("props before set timeout", props);
              if (goToPma) {
                setTimeout(() => {
                  props.history.push(`/pma/${props.match.params.areaID}`);
                }, 1300);
              }
            })
            .catch((err) => {
              console.log("in another catch");
              showErrorNotification(
                props,
                "Something went wrong while processing your request."
              );
              setBtnLoading(false);
            });
        }
      } catch (err) {
        console.log("in the catch");
        showErrorNotification(props, "Unable to process request.");
        setBtnLoading(false);
      }
    }
  };

  const onEditFormSubmit = (oneAreaSubmit) => {
    const { photoDetails } = propHighlightData;
    console.log("***EDITING PROPERTY oneAreaSubmit", oneAreaSubmit);
    console.log("**inside on edit form submit", editPropFormData);
    const {
      streetNumber,
      streetName,
      cityName,
      salePrice,
      brokerageName,
      photoURL,
      status,
    } = editPropFormData;
    let editedSalePrice = editPropFormData.salePrice;
    console.log("editedSalePrice", editedSalePrice);
    // setEditPropFormData({
    //   ...editPropFormData,
    //   salePrice: editPropFormData.salePrice,
    //   listPrice: editPropFormData.listPrice
    // });
    if (
      !streetNumber ||
      !streetName ||
      !cityName ||
      (!salePrice && status === "Sold") ||
      !brokerageName ||
      !photoURL ||
      !status
    ) {
      console.log("streetNumber", streetNumber);
      console.log("streetName", streetName);
      console.log("cityName", cityName);
      console.log("brokerageName", brokerageName);
      console.log("salePrice", salePrice);
      console.log("photoURL", photoURL);
      console.log("status", status);
      setErrorModalText("Missing inputs");
      setShowErrorModal(true);
    } else {
      const changeProperty = photoDetails.map((prop) =>
        (prop.streetName === editPropFormData.streetName &&
          prop.unitNumber === editPropFormData.unitNumber &&
          prop.streetNumber === editPropFormData.streetNumber) ||
        (prop.streetNumber === editPropFormData.streetNumber &&
          prop.optimizedPhotoURL === editPropFormData.optimizedPhotoURL) ||
        (prop.photoURL === editPropFormData.photoURL &&
          prop.streetName === editPropFormData.streetName &&
          prop.salePrice === editPropFormData.salePrice) ||
        (prop.streetName === editPropFormData.streetName &&
          prop.streetNumber === editPropFormData.streetNumber &&
          prop.salePrice === editPropFormData.salePrice)
          ? editPropFormData
          : prop
      );

      //
      // let change3 = photoDetails.map((prop) => {
      //   if (
      //     //compare fields that cannot be edited by the agent
      //     prop.view === editPropFormData.view &&
      //     prop.bedBaths === editPropFormData.bedBaths &&
      //     prop.listingAgentLicenseID === editPropFormData.listingAgentLicenseID
      //   ) {
      //     return editPropFormData;
      //   } else {
      //     console.log("return prop", prop);
      //     return prop;
      //   }
      // });
      //    console.log("change3", change3);
      console.log("about to change", changeProperty);
      setSingleAreaSubmit(oneAreaSubmit);
      setPropHighlightData({
        ...propHighlightData,
        photoDetails: changeProperty,
      });
      console.log("after setting highlights", photoDetails);

      setIsSubmitting(true);
      handleFormClose();
    }
  };

  const handleDragEnd = (result) => {
    const {
      areaID,
      countyID,
      listgenUserAreaID,
      listgenUserID,
      photoDetailsRunDate,
      photoDetails,
    } = propHighlightData;

    // dropped outside the list
    let selected = photoDetails.filter(
      (prop) => prop.isSystemSelected === true
    );

    let sortSelected = selected.sort((a, b) => a.displayOrder - b.displayOrder);

    let mapToIndex = selected.map((prop, index) => (prop.displayOrder = index));

    let unselected = photoDetails.filter(
      (prop) => prop.isSystemSelected === false || !prop.isSystemSelected
    );

    if (!result.destination) {
      return;
    }
    let dataToSort = Array.from(selected);
    let startIndex = result.source.index;
    let endIndex = result.destination.index;
    let [startData] = selected.splice(startIndex, 1);
    selected.splice(endIndex, 0, startData);
    let changeOrders = selected.map(
      (prop, index) => (prop.displayOrder = index)
    );
    let newList = [...selected, ...unselected];

    let constructedHighlights = {
      areaID,
      countyID,
      listgenUserAreaID,
      listgenUserID,
      photoDetails: newList,
      photoDetailsRunDate,
    };

    setPropHighlightData({
      ...propHighlightData,
      photoDetails: newList,
    });
    showInfoNotification(
      props,
      `Please SAVE YOUR CHANGES!  Click on the blue LOOKS GOOD – SUBMIT button at the bottom of the screen.`,
      20000,
      "bottom",
      "right"
    );
  };

  const filterSelectedProps = (propList) => {
    let selected = propList.filter(
      (property) => property.isSystemSelected === true
    );
    return selected.sort((a, b) => a.displayOrder - b.displayOrder);
  };
  const filterUnselectedProps = (propList) => {
    let unselected = propList.filter(
      (property) => property.isSystemSelected !== true
    );
    return unselected.sort((a, b) => a.sortOrder - b.sortOrder);
  };

  const handleSelect = (property, select) => {
    console.log("property is", property);
    console.log("select is", select);

    let selected = photoDetails.filter(
      (property) => property.isSystemSelected === true
    );
    console.log("selected is", selected);
    let uniqueSelect = new Set(selected);
    if (select === true && selected.length >= maxPropertiesNum) {
      setErrorModalText("Max properties");
      setShowErrorModal(true);
    } else if (select === false && selected.length === 1) {
      console.log("inside selected false and length 1");
      setErrorModalText("Min properties");
      setShowErrorModal(true);
    } else {
      let propData = photoDetails;
      let selected = photoDetails.filter(
        (prop) => prop.isSystemSelected === true
      );
      console.log("selected props", selected);
      let newDisplayOrder;
      if (select && !selected) {
        newDisplayOrder = 0;
      } else if (select && selected.length > 0) {
        newDisplayOrder = selected.length;
      } else {
        newDisplayOrder = 0;
      }
      const updatedPropList = propData.map((prop) =>
        prop.streetName === property.streetName &&
        prop.streetNumber === property.streetNumber &&
        prop.listDate === property.listDate &&
        prop.optimizedPhotoURL === property.optimizedPhotoURL
          ? {
              ...prop,
              isSystemSelected: select ? true : false,
              displayOrder: newDisplayOrder,
            }
          : prop
      );
      let updateSelected = updatedPropList.filter(
        (property) => property.isSystemSelected === true
      );
      if (!select) {
        let changeSelectOrders = updateSelected.map(
          (prop, index) => (prop.displayOrder = index)
        );
      }
      const {
        areaID,
        countyID,
        listgenUserAreaID,
        listgenUserID,
        photoDetailsRunDate,
      } = propHighlightData;
      let constructedHighlights = {
        areaID,
        countyID,
        listgenUserAreaID,
        listgenUserID,
        photoDetails: updatedPropList,
        photoDetailsRunDate,
      };
      setPropHighlightData({
        ...propHighlightData,
        photoDetails: updatedPropList,
      });
    }
  };

  const handleRepresentedOptions = () => {
    if (editPropFormData.isBuyingAgent && editPropFormData.isSellingAgent) {
      setEditPropFormData({
        ...editPropFormData,
        isSellingAgent: false,
      });
    } else if (editPropFormData.isBuyingAgent) {
      setEditPropFormData({
        ...editPropFormData,
        isSellingAgent: true,
        isBuyingAgent: false,
      });
    } else if (editPropFormData.isSellingAgent) {
      setEditPropFormData({
        ...editPropFormData,
        isSellingAgent: true,
        isBuyingAgent: true,
      });
    }
  };

  const getUniqueProps = (props) => {
    let final = [];
    let addressList = [];
    for (let i = 0; i < props.length; i++) {
      let prop = props[i];
      let address = `${prop.streetNumber} ${prop.streetName} ${prop.unitNumber} ${prop.isSystemSelected} ${prop.displayOrder}`;
      if (!addressList.includes(address)) {
        final.push(prop);
        addressList.push(address);
      } else {
        console.log("****does contain unique props", final);
      }
    }
    return final;
  };
  const handleInputChange = (e) => {
    const target = e.target;
    let value = target.value;
    const name = target.name;
    console.log("target", target);
    console.log("value", value);
    console.log("name", name);
    if (e.target.name === "salePrice" || e.target.name === "listPrice") {
      console.log("inside sale or list");

      value = Number(value);
    }
    console.log("name", name);
    setEditPropFormData({
      ...editPropFormData,
      [e.target.name]: value,
    });
    console.log("editPropFormData", editPropFormData);
  };

  const handleTitleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setPropHighlightData({ ...propHighlightData, [e.target.name]: value });
  };

  const handleFooterChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setPropHighlightData({ ...propHighlightData, [e.target.name]: value });
  };
  const handleStatusOptions = () => {
    console.log("inside STATUS");
    console.log("editPropFormData", editPropFormData);
    // ["Sold", "Pending", "Contingent", "Active"];
    if (
      editPropFormData.status === "Sold" ||
      !editPropFormData.status ||
      editPropFormData.status === "-"
    ) {
      setEditPropFormData({
        ...editPropFormData,
        status: "Pending",
      });
    } else if (editPropFormData.status === "Pending") {
      setEditPropFormData({
        ...editPropFormData,
        status: "Contingent",
      });
    } else if (editPropFormData.status === "Contingent") {
      setEditPropFormData({
        ...editPropFormData,
        status: "Active",
      });
    } else if (editPropFormData.status === "Active") {
      setEditPropFormData({
        ...editPropFormData,
        status: "Sold",
      });
    }
  };
  const radioSaleChange = (event) => {
    const { brokerageName } = userData;

    let val = event.target.value;
    if (val === "my-sale") {
      setEditPropFormData({
        ...editPropFormData,
        brokerageName,
        soldByOtherAgent: false,
      });
    } else {
      setEditPropFormData({
        ...editPropFormData,
        soldByOtherAgent: true,
      });
    }
  };

  const handleStatusChange = (e) => {
    console.log("handling status change");
    setEditPropFormData({
      ...editPropFormData,
      status: e.target.value,
    });
  };
  const resetDisplayOrder = () => {
    const { photoDetails } = propHighlightData;
    let updatedPropList = photoDetails.map((property) => ({
      ...property,
      displayOrder: 0,
    }));

    setPropHighlightData({
      ...propHighlightData,
      photoDetails: updatedPropList,
    });
  };
  const scrollDownToProperties = () => {
    window.scrollTo({
      top: 970,
      behavior: "auto",
    });
  };
  const {
    photoDetails,
    areaID,
    propertyHighlightsTitle,
    propertyHighlightsSubtitle,
    propertyHighlightsFooterText1,
    propertyHighlightsFooterText2,
  } = propHighlightData;
  const { formatSuffix } = propertyUtil;
  const selectedProps =
    photoDetails &&
    photoDetails.length > 1 &&
    filterSelectedProps(photoDetails);
  const unselectedProps =
    photoDetails &&
    photoDetails.length > 1 &&
    filterUnselectedProps(photoDetails);
  let showButton = true;
  const thisArea = props.match.params.areaID;

  return (
    <Fragment>
      {openViewImageDialog ? (
        <MediaViewComponent
          mediaURL={uploadCurrentImage}
          mediaTitle={"Property Highlight"}
          onOpen={() => {}}
          onClose={closeViewMedia}
        />
      ) : (
        ""
      )}
      {openUploadImageDialog ? (
        <ImageUploadDialog
          openDialog={true}
          onSubmit={handlePropertyHighlightUpload}
          onClose={handlePropertyHighlightUpload}
          isMyAccountUpload={isAValidSessionSwitch}
          imageType={"propertyHighlight"}
          listgenUserID={userData.listgenUserID} //not required  because designation Logo doesnt required any image upload
          currentImage={uploadCurrentImage}
          teamMode={false}
        />
      ) : (
        ""
      )}

      <DocumentTitle title="Property Highlights">
        <DashboardLayout title="" isCompleteInvisible={true}>
          {showErrorModal ? (
            <ErrorModal
              errorModalText={errorModalText}
              handleErrorModal={handleErrorModal}
              maxPropertiesNum={maxPropertiesNum}
            />
          ) : (
            ""
          )}
          {showAddPropDialog ? (
            <PropHighlightsAddProperty
              // onCloseFn={handleFormClose}
              // openUploadMedia={openUploadMedia}
              // handleInputChange={handleInputChange}
              // editPropFormData={editPropFormData}
              // handleRepresentedOptionsFunc={handleRepresentedOptions}
              // handleAddProperty={handleAddProperty}

              onCloseFn={handleFormClose}
              openUploadMedia={openUploadMedia}
              handleInputChange={handleInputChange}
              handleStatusChange={handleStatusChange}
              editPropFormData={editPropFormData}
              handleStatusOptionsFunc={handleStatusOptions}
              handleRepresentedOptionsFunc={handleRepresentedOptions}
              listgenUserID={userData.listgenUserID}
              handleAddProperty={handleAddProperty}
              userData={userData}
              agentData={userData}
              //radioSaleChangeFunc={radioSaleChange}
            />
          ) : (
            ""
          )}
          {showEditDialog ? (
            <PropHighlightsEditProperty
              onCloseFn={handleFormClose}
              openUploadMedia={openUploadMedia}
              handleInputChange={handleInputChange}
              editPropFormData={editPropFormData}
              handleRepresentedOptionsFunc={handleRepresentedOptions}
              listgenUserID={userData.listgenUserID}
              onEditFormSubmit={onEditFormSubmit}
              userData={userData}
              agentData={userData}
              oldPropData={oldPropData}
              areaID={areaID}
              handleStatusChange={handleStatusChange}
            />
          ) : (
            ""
          )}
          {showHighlightTitleDialog ? (
            <HighlightTitleDialog
              propertyHighlightsTitle={propertyHighlightsTitle}
              propertyHighlightsSubtitle={propertyHighlightsSubtitle}
              handleTitleChange={handleTitleChange}
              onHighlightTitleClose={onHighlightTitleClose}
              oldTitle={oldTitle}
              maxHighlightTitleLength={maxHighlightTitleLength}
              maxHighlightSubtitleLength={maxHighlightSubtitleLength}
              handleAddTitle={handleAddTitle}
            />
          ) : (
            ""
          )}
          {showHighlightFooterDialog ? (
            <HighlightFooterDialog
              propertyHighlightsFooterText1={propertyHighlightsFooterText1}
              propertyHighlightsFooterText2={propertyHighlightsFooterText2}
              handleFooterChange={handleFooterChange}
              onHighlightFooterClose={onHighlightFooterClose}
              oldFooter={oldFooter}
              maxHighlightFooter1Length={maxHighlightFooter1Length}
              maxHighlightFooter2Length={maxHighlightFooter2Length}
              handleAddFooter={handleAddFooter}
            />
          ) : (
            ""
          )}
          <Portlet className={classes.contentPadded}>
            <PortletContent noPadding className="portlet-content">
              <div className={classes.propHighlightContainer}>
                <div className={classes.propHighlightTitleContainer}>
                  <Link
                    to={`/pma/${thisArea}`}
                    className="property-highlights-back-button"
                  >
                    <p>Back to PMA</p>
                  </Link>
                  <div className={classNames(classes.propHighlightTitle)}>
                    Showcase Your Sold Properties
                  </div>
                  <div className={classes.highlightTitleDivider}></div>
                  <div className={classes.highlightSubtitle}>
                    Select {maxPropertiesNum} Properties from list below that
                    you would like to feature
                  </div>
                </div>
              </div>
              {/*<div
                style={{ fontSize: "50px" }}
                onClick={() => resetDisplayOrder()}
              >
                Reset displayOrder
              </div>*/}

              {isLoading ? (
                <div className="prop-highlight-table-empty-container">
                  <p>Loading Properties ....</p>
                </div>
              ) : (
                <div className="prop-highlight-table-container">
                  {/*<div className="unique-btn" onClick={() => getUniqueProps()}>
                  <p>UNIQUE</p>
                </div>*/}
                  <div className="property-highlights-header-container">
                    <div
                      className="property-highlights-edit-button"
                      onClick={scrollDownToProperties}
                    >
                      <p>Edit My {maxPropertiesNum} Sold Properties</p>
                    </div>
                    {showButton && (
                      <div
                        className="property-highlights-submit-button"
                        onClick={() => wholePageSubmit(false)}
                      >
                        <p>Submit For All Areas</p>
                      </div>
                    )}
                    {showButton && (
                      <div
                        className="property-highlights-submit-button"
                        onClick={() => wholePageSubmit(true)}
                      >
                        <p>Submit for This Area Only</p>
                      </div>
                    )}
                  </div>

                  {photoDetails && (
                    <YETwoPropertyHighlights
                      currentSoldListings={
                        photoDetails.length === 1 ? photoDetails : selectedProps
                      }
                      mlsAreaID={areaID}
                      isAgentPage={true}
                      setHighlightTitleDialog={setHighlightTitleDialog}
                      setHighlightFooterDialog={setHighlightFooterDialog}
                      onHighlightTitleOpen={onHighlightTitleOpen}
                      onHighlightFooterOpen={onHighlightFooterOpen}
                      title={propertyHighlightsTitle}
                      subtitle={propertyHighlightsSubtitle}
                      agentData={userData}
                      footerText1={propertyHighlightsFooterText1}
                      footerText2={propertyHighlightsFooterText2}
                      openEditDialogFn={handleOpenEditDialog}
                    />
                  )}
                  <div className="property-highlights-footer-container">
                    {isBtnLoading ? (
                      <div
                        className={classNames(
                          classes.progressWrapper,
                          classes.displyFlex,
                          classes.centerJustify
                        )}
                      >
                        <CircularProgress />
                      </div>
                    ) : showButton ? (
                      <div>
                        <div
                          className="property-highlights-submit-button"
                          onClick={() => wholePageSubmit(false)}
                        >
                          <p>Submit for All Areas</p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {showButton && (
                      <div
                        className="property-highlights-submit-button"
                        onClick={() => wholePageSubmit(true)}
                      >
                        <p>Submit for This Area Only</p>
                      </div>
                    )}
                  </div>
                  <div
                    className="add-property-row"
                    onClick={() => handleAddPropDialog(true)}
                  >
                    <div className="add-property-button">
                      <div>+</div>
                    </div>
                    <div className="add-property-text">
                      Click here to add a property that was sold off-market or
                      is not shown here.
                    </div>
                  </div>
                  <div className="prop-highlight-table-title-container">
                    <div className="prop-highlight-table-title-text">
                      YOUR MOST RECENT SALES
                    </div>
                    <div className="prop-highlight-table-subtitle-text flash-text">
                      <p>
                        Select the {maxPropertiesNum} properties you would like
                        to feature.
                      </p>
                      <p>
                        Using the table below, you can drag and drop your
                        properties into any order.
                      </p>
                    </div>
                  </div>
                  <div className="prop-highlight-table">
                    <div className="prop-highlight-thead">
                      <div>
                        {/*<p>Photo</p>
                        <p>Size</p>*/}
                      </div>
                      <div></div>
                      <div className="prop-highlight-thead-col">
                        <p>Select</p>
                        <p>Property</p>
                      </div>
                      <div>
                        <p></p>
                      </div>
                      <div></div>
                      <div>
                        <p>Represented</p>
                      </div>
                      <div>
                        <p>Address</p>
                      </div>
                      <div>
                        <p>List</p>
                        <p>Price</p>
                      </div>
                      <div>
                        <p>Sale</p>
                        <p>Price</p>
                      </div>
                      <div>
                        <p>Sale</p>
                        <p>Date</p>
                      </div>
                      {/*<div>
                      <p>Alternate</p>
                      <p>Photo</p>
                    </div>*/}
                    </div>

                    {photoDetails &&
                      photoDetails.length === 1 &&
                      photoDetails[0].isSystemSelected === true && (
                        <DragDropContext
                          onDragEnd={handleDragEnd}
                          className="drag-drop-context"
                        >
                          <Droppable
                            droppableId="lg-property-highlight"
                            className="droppable-prop"
                          >
                            {(provided, snapshot) => (
                              <div
                                className="draggable-div"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getPHListStyle(snapshot.isDraggingOver)}
                              >
                                <Draggable
                                  className="draggable-container"
                                  draggableId={`${photoDetails[0].streetNumber}-${photoDetails[0].streetName}-${photoDetails[0].unitNumber}`}
                                  index={photoDetails[0].displayOrder}
                                  key={`${photoDetails[0].streetNumber}-${photoDetails[0].streetName}-${photoDetails[0].unitNumber}-${photoDetails[0].optimizedPhotoURL}`}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className="highlight-row-container"
                                      key={`${photoDetails[0].streetNumber}-${photoDetails[0].streetName}-${photoDetails[0].unitNumber}-${photoDetails[0].optimizedPhotoURL}`}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <HighlightRow
                                        prop={photoDetails[0]}
                                        index={photoDetails[0].displayOrder}
                                        areaID={areaID}
                                        handleSelect={handleSelect}
                                        openEditDialogFn={handleOpenEditDialog}
                                        handleStatusChange={handleStatusChange}
                                      />
                                    </div>
                                  )}
                                </Draggable>

                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      )}

                    {photoDetails && photoDetails.length > 1 && (
                      <DragDropContext
                        onDragEnd={handleDragEnd}
                        className="drag-drop-context"
                      >
                        <Droppable
                          droppableId="lg-property-highlight"
                          className="droppable-prop"
                        >
                          {(provided, snapshot) => (
                            <div
                              className="draggable-div"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getPHListStyle(snapshot.isDraggingOver)}
                            >
                              {selectedProps.map((prop, index) => {
                                return (
                                  <Draggable
                                    className="draggable-container"
                                    // key={prop.displayOrder}
                                    draggableId={`${prop.streetNumber}-${prop.streetName}-${index}-${prop.unitNumber}-${prop.optimizedPhotoURL}`}
                                    // draggableId={`lg-ag-${prop.displayOrder}`}
                                    // draggableId={index.toString()}
                                    index={index}
                                    key={`${prop.streetNumber}-${prop.streetName}-${prop.unitNumber}-{index}-${prop.optimizedPhotoURL}`}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        className="highlight-row-container"
                                        key={`${prop.streetNumber}-${prop.streetName}-${prop.unitNumber}-{index}-${prop.optimizedPhotoURL}`}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <HighlightRow
                                          prop={prop}
                                          index={index}
                                          areaID={areaID}
                                          handleSelect={handleSelect}
                                          openEditDialogFn={
                                            handleOpenEditDialog
                                          }
                                          handleStatusChange={
                                            handleStatusChange
                                          }
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    )}
                  </div>

                  {photoDetails && photoDetails.length > 1 && (
                    <div className="prop-highlight-table unselected-table">
                      {unselectedProps.map((prop, index) => {
                        return (
                          <div className="drag-drop-context" key={index}>
                            <div className="droppable-prop">
                              <div className="draggable-div">
                                <div className="draggable-container">
                                  <div className="highlight-row-container">
                                    <HighlightRow
                                      prop={prop}
                                      index={index}
                                      areaID={areaID}
                                      handleSelect={handleSelect}
                                      openEditDialogFn={handleOpenEditDialog}
                                      showButton={showButton}
                                      handleStatusChange={handleStatusChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {photoDetails &&
                    photoDetails.length === 1 &&
                    photoDetails[0].isSystemSelected === false && (
                      <div className="prop-highlight-table unselected-table">
                        <div
                          className="drag-drop-context"
                          key={`${photoDetails[0].streetName}-${photoDetails[0].unitNumber}-${photoDetails[0].optimizedPhotoURL}`}
                        >
                          <div className="droppable-prop">
                            <div className="draggable-div">
                              <div className="draggable-container">
                                <div className="highlight-row-container">
                                  <HighlightRow
                                    prop={photoDetails[0]}
                                    index={0}
                                    areaID={areaID}
                                    handleSelect={handleSelect}
                                    openEditDialogFn={handleOpenEditDialog}
                                    handleStatusChange={handleStatusChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              )}
            </PortletContent>
          </Portlet>
        </DashboardLayout>
      </DocumentTitle>
    </Fragment>
  );
};

PropertyHighlights.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withSnackbar,
  withStyles(
    (theme) => ({
      ...styles(theme),
      ...styles2(theme),
    }),
    {
      withTheme: true,
    },
    withRouter
  )
)(PropertyHighlights);
