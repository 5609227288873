import React, { useEffect } from 'react';
import { connect } from "react-redux";
import styles from "./styles";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import { MailProDialog } from '../MailProDialog/MailProDialog';
import { MailProDialogHeader } from '../MailProDialog/MailProDialogHeader';
import { MailProDialogBody } from '../MailProDialog/MailProDialogBody';
import MailProButton from '../UI/MailProButton';
import MailProCheckbox from '../UI/MailProCheckbox';
import classNames from 'classnames';
import HomeValuationRatingStarsView from './HomeValuationRatingStarsView';
import MailProDatePicker from '../UI/MailProDatePicker';
import { renderLogicUtil } from 'views/Pma/RenderLogicUtil';
import { propertyUtil } from 'views/Pma/PropertyUtil';
import { BestHomeValuationTag, HomeValuationList, checkForHomeValuationErrors, getEmptyHomeValuationData } from './HomeValuationUtils';
import { GetStateStorage } from 'common/storage';
import MailProTextarea from '../UI/MailProTextarea';
import { MailProErrorFormatter } from '../UI/MailProErrorFormatter';
import { pmaActions } from '_store/_actions';
import { red } from '@material-ui/core/colors';
import { resetHomeValuation } from './HomeValuationServices';
import { CommonConfirmationDialogV2Component } from 'components';


const HomeValuationRatingDialog = ({ classes, property, children, agentData, isMailProLocked, ...rest }) => {
    const { mailerProID, mailingDetails, hoSubmittedDetails } = property;
    const {
        SiteNumber,
        SiteStreet,
        SiteCity,
        SiteState,
        SiteZip,
    } = mailingDetails || {};

    const { submittedFullName } = hoSubmittedDetails || {};

    const initialState = {
        mailerProID: mailerProID,
        isHomeValuationGivenByAgent: property.isHomeValuationGivenByAgent,
        likelyhoodOfListing: property.likelyhoodOfListing,
        approxDateOfListing: property.approxDateOfListing,
        homeValuationGivenDate: property.homeValuationGivenDate,
        agentCommentsOnHO: property.agentCommentsOnHO
    }

    const isBlocked = isMailProLocked;

    const [open, setOpen] = React.useState(false);
    const [errors, setErrors] = React.useState({ homeValuation: [], likelihood: [] });
    const [combinedErrors, setCombinedErrors] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);

    const [homeValuationData, setHomeValuation] = React.useState({ ...initialState });

    const handleClose = () => {
        handleResetandClose(homeValuationData)
    }

    const handleResetandClose = (newData) => {
        resetErrors()
        setOpen(false)
        setLoading(false)
        setHomeValuation(Object.assign({}, newData))
    }

    const handleDataUpdate = (key, value) => {
        const oldData = { ...homeValuationData };
        if (key === 'isHomeValuationGivenByAgent') {
            if (!value) {
                oldData.likelyhoodOfListing = null;
                oldData.approxDateOfListing = null;
                oldData.homeValuationGivenDate = null;
            }
        }
        //if no rating selected keep no value for approxDateOfListing
        //if rating selected than if its not 3,4,5 means no date value will be there
        if (key === 'likelyhoodOfListing') {
            //&& (!value || !["3", "4", "5"].includes(value))
            oldData.approxDateOfListing = null;
        }
        oldData[key] = value
        setHomeValuation(oldData)
        resetErrors()
    }

    const addAError = (newErrors) => {
        setErrors({ ...errors, ...newErrors });
        let tempErrors = combinedErrors;
        for (const key in newErrors) {
            if (newErrors.hasOwnProperty(key)) {
                if (newErrors.hasOwnProperty(key) && Array.isArray(newErrors[key]) && newErrors[key].length > 0) {
                    tempErrors = [...newErrors[key]];
                }
            }
        }
        setCombinedErrors(tempErrors);
    }

    const resetErrors = () => {
        setErrors({ homeValuation: [], likelihood: [] });
        setCombinedErrors([]);
    }

    const checkABlockError = (blockType) => {
        //Block Values: 'homeValuation' | 'likelihood'
        return errors[blockType] && errors[blockType].length > 0;
    }

    const updateTagFor4and5Rating = (property) => {
        let { tags } = property.changeDetails;
        if (['4', '5'].includes(homeValuationData.likelyhoodOfListing) && renderLogicUtil.getPropertyTotalTagCount(property) <= 3) {
            tags = Array.isArray(tags) ? tags : []
            const newTags = [...new Set([...tags, ...[BestHomeValuationTag]])]
            return newTags
        }
        return tags
    }


    const onSubmit = async () => {
        if (isBlocked) {
            addAError({ "common": ["Mailpro has been locked for this mailing."] })
        } else {

            const errors = checkForHomeValuationErrors(homeValuationData)
            if (errors.isError) {
                addAError(errors)
                return;
            }


            setLoading(true);
            const { propertyData } = rest;
            const { agentID, areaID, imbarcode, isPropertyMapped, mailerProID, mailingStatus, numericIMBarcode, parcelNumber, changeDetails } = property;

            const userData = GetStateStorage();

            const tempTags = updateTagFor4and5Rating(property);
            let updatedChangeDetails = Object.assign({}, changeDetails, {
                tags: tempTags,
            });
            let actualHomeowners = propertyUtil.getActualHomeowners(propertyData);
            let agentList = propertyUtil.getActualAgents(propertyData).length;
            let unblockedHO = propertyUtil.getActualUnblockedHomeowners(
                actualHomeowners
            ).length;

            const totalUnblocked = agentList + unblockedHO;

            let updatedHOData = {};
            const xPropertyData = propertyData.slice()
            const newPropertyData = xPropertyData.map(property => {
                if (property.mailerProID === mailerProID) {
                    property.changeDetails = updatedChangeDetails;
                    property.isHomeValuationGivenByAgent = homeValuationData.isHomeValuationGivenByAgent;
                    property.likelyhoodOfListing = homeValuationData.likelyhoodOfListing;
                    property.approxDateOfListing = homeValuationData.approxDateOfListing;
                    property.homeValuationGivenDate = homeValuationData.homeValuationGivenDate;
                    property.agentCommentsOnHO = homeValuationData.agentCommentsOnHO;
                    updatedHOData = property;
                }
                return property;
            });

            updatedHOData["listgenUserID"] = userData.userID ? userData.userID : "";
            updatedHOData["numOfPMAs"] = totalUnblocked;

            await rest.updateHomeownerProp(updatedHOData);
            rest.resetMailproCurrentHeaderSort()
            await rest.updateCompleteMailerProDataAsync(
                newPropertyData,
                userData && userData.userData ? userData.userData : {}
            );
            rest.quickUpdatePropertyData && rest.quickUpdatePropertyData(homeValuationData);
            setLoading(false);
            setTimeout(() => {
                handleResetandClose(homeValuationData);
            }, 300)


        }
    }

    const resetValuation = async () => {
        if (isBlocked) {
            addAError({ "common": ["Mailpro has been locked for this mailing."] })
        } else {
            const { propertyData } = rest;
            const { mailerProID } = property;
            setLoading(true);
            resetHomeValuation(mailerProID).then(async () => {
                const xPropertyData = propertyData.slice();
                const userData = GetStateStorage();
                const emptyHomeValuationData = getEmptyHomeValuationData(mailerProID);
                const newPropertyData = xPropertyData.map(property => {
                    if (property.mailerProID === mailerProID) {
                        property.changeDetails = {
                            ...property.changeDetails,
                        }
                        property = { ...property, ...emptyHomeValuationData }
                    }
                    return property;
                });
                rest.resetMailproCurrentHeaderSort();
                await rest.updateCompleteMailerProDataAsync(
                    newPropertyData,
                    userData && userData.userData ? userData.userData : {}
                );
                rest.quickUpdatePropertyData && rest.quickUpdatePropertyData(emptyHomeValuationData);
                setTimeout(() => {
                    handleResetandClose({});
                }, 300)
            }
            ).catch((error) => {
                console.error("Error in resetting the valuation.", error)
                addAError({ "common": ["Error in resetting the valuation."] })
            }
            ).finally(() => {
                setLoading(false);
            });

        }

    }

    return (
        <div>
            <div onClick={() => setOpen(true)}>{children}</div>
            {open && (
                <MailProDialog
                    open={open}
                    classes={{
                        paper: classes.homeValuationDialogPaper
                    }}>
                    <MailProDialogHeader>
                        <div className={classes.ratingDialogHeader}>
                            <div className={classes.ratingDialogSubmittedFullName}>{submittedFullName}</div>
                            <div className={classes.ratingDialogSubHeader}>
                                {propertyUtil.emptyValueCheck(SiteNumber).toUpperCase()}{" "}{propertyUtil.emptyValueCheck(SiteStreet).toUpperCase()}&nbsp;&nbsp;&nbsp;
                                {propertyUtil.emptyValueCheck(SiteCity).toUpperCase()}{", "}{propertyUtil.emptyValueCheck(SiteState).toUpperCase()}{" "}{propertyUtil.emptyValueCheck(SiteZip).toUpperCase()}
                            </div>
                        </div>

                    </MailProDialogHeader>
                    <MailProDialogBody>
                        <MailProErrorFormatter errors={combinedErrors} key={'hv-box-1'} />
                        <div>
                            <div className={classNames(classes.ratingBox, classes.centerFlex, (checkABlockError('homeValuation') ? classes.errorBox : null))}>
                                <MailProCheckbox
                                    label={<span className={classes.providedHomeValuation}>I have provided this Homeowner a Home Valuation on</span>}
                                    checked={homeValuationData.isHomeValuationGivenByAgent}
                                    onChange={(event) => handleDataUpdate('isHomeValuationGivenByAgent', event.target.checked)}
                                />
                                <span style={{ width: '300px' }}>
                                    <MailProDatePicker
                                        defaultValue={homeValuationData.homeValuationGivenDate}
                                        handleDateChange={(value) => handleDataUpdate('homeValuationGivenDate', value)}
                                        disabled={!homeValuationData.isHomeValuationGivenByAgent}
                                        placeholder='Select the date you provided a Home Valuation'
                                        fullWidth={true}
                                    />
                                </span>
                            </div>

                            <div className={classes.ratingListTitle}>Rate the likelihood of this Homeowner listing their property:</div>
                            <div className={classNames(classes.ratingBox, classes.ratingCheckboxList, (checkABlockError('likelihood') ? classes.errorBox : null))}>
                                {HomeValuationList.map((rating, iKey) => {
                                    const ratingValue = rating.key
                                    return <div className={classes.ratingCheckboxListInner} key={iKey}>

                                        <MailProCheckbox
                                            label={undefined}
                                            checked={homeValuationData.likelyhoodOfListing == ratingValue}
                                            onChange={(event) => handleDataUpdate('likelyhoodOfListing', (event.target.checked ? ratingValue : null))}
                                        />

                                        <div className={classes.ratingCheckboxListInnerStar}>
                                            {ratingValue > 0 && <HomeValuationRatingStarsView
                                                key={"MailProStar-" + ratingValue}
                                                ratingValue={ratingValue}
                                                selected={homeValuationData.likelyhoodOfListing == ratingValue}
                                            />}
                                        </div>

                                        <div className={classes.ratingCheckboxListInnerText}>{rating.value}</div>
                                        <div className={classes.ratingCheckboxListInnerDate}>
                                            {ratingValue > 2 && <MailProDatePicker
                                                defaultValue={homeValuationData.likelyhoodOfListing == ratingValue ? homeValuationData.approxDateOfListing : null}
                                                handleDateChange={(value) => handleDataUpdate('approxDateOfListing', value)}
                                                disabled={homeValuationData.likelyhoodOfListing != ratingValue}
                                                placeholder={"Optional: Click to select an expected listing date"}
                                                clearLabel="Skip Listing Date Selection"
                                                cancelLabel={"Close"}
                                            />}
                                        </div>
                                    </div>
                                })}
                            </div>
                            <div className={classes.commentsTitle}>COMMENTS</div>
                            <div>
                                <MailProTextarea
                                    rows={3}
                                    fullWidth={true}
                                    onChange={(event) => handleDataUpdate('agentCommentsOnHO', event.target.value)}
                                    value={homeValuationData.agentCommentsOnHO}
                                />
                            </div>
                        </div>
                        <div className={classes.footer}>
                            <div>
                                <MailProButton
                                    key={"mp_new_add_group_cance;"}
                                    variant="outlined"
                                    label={"CANCEL"}
                                    customStyle={{ fontSize: "12px", fontWeight: 700 }}
                                    onClick={handleClose}
                                    disabled={isLoading}
                                />
                            </div>
                            <div>
                                <MailProButton
                                    key={"mp_new_update_group"}
                                    variant="filled"
                                    label={"UPDATE"}
                                    customStyle={{ fontSize: "12px", fontWeight: 700 }}
                                    onClick={onSubmit}
                                    disabled={isLoading}
                                />
                            </div>
                            <div>
                                <CommonConfirmationDialogV2Component
                                    title={"Are you sure you want to delete this Home Valuation?"}
                                    subtitle={"You are about to delete this home valuation request. This action cannot be undone."}
                                    methodToCallOnConfirmation={() => { resetValuation() }}
                                    key={'MailPro-HomeValuation-Delete'}
                                    cancelButtonText={'CANCEL'}
                                    confirmationButtonText={'YES, DELETE'}
                                >
                                    <MailProButton
                                        key={"mp_new_add_group_cance;"}
                                        variant="text"
                                        label={"DELETE"}
                                        customStyle={{ fontSize: "12px", fontWeight: 700, color: red[400], textDecoration: 'none' }}
                                        onClick={() => { }}
                                        disabled={isLoading}
                                    />
                                </CommonConfirmationDialogV2Component>
                            </div>


                        </div>
                    </MailProDialogBody>

                </MailProDialog>
            )}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        propertyData: state.pma.propertyData,
        currentArea: state.pma.currentArea,
        mlsAreas: state.pma.mlsAreas,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHomeownerProp: hoData =>
            dispatch(pmaActions.updateHomeownerProp(hoData)),
        updateCompleteMailerProDataAsync: (propertyData, agentData) => {
            dispatch(pmaActions.updateCompleteMailerProDataAsync(propertyData, agentData));
        },
        resetMailproCurrentHeaderSort: () => {
            dispatch(pmaActions.resetMailproCurrentHeaderSort());
        },
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles), withSnackbar)(HomeValuationRatingDialog));