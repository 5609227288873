import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Button, withStyles } from "@material-ui/core";
import { Typography, CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles from "./styles.scss";
import { Dashboard as DashboardLayout } from "layouts";
import { withSnackbar } from "notistack";
import { Portlet } from "components";
import { PortletContent } from "components";
import { PortletLabel } from "components";
import { PortletHeader } from "components";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import { pmaActions } from "../../_store/_actions";
import DocumentTitle from "react-document-title";
import theme from "theme";
import classNames from "classnames";
import querystring from "querystring";

import { isCommonEmptyCheck } from "common/helpers";

import { showErrorNotification, showSuccessNotification } from "common/helpers";
import { handleLogoutFn } from "common/helpers";
import { withRouter, Link } from "react-router-dom";
import { IsValidSessionSwitch } from "common/storage";
import { IsValidLoginAuth } from "common/storage";
import { GetStateStorage } from "common/storage";
import Base from "common/constants";
import { InlineWidget } from "react-calendly";

class ScheduleDemo extends Component {
  render() {
    return (
      <div className="schedule-demo">
        <InlineWidget url="https://calendly.com/listgen-natalie/listgen-demo-meeting" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return {};
}

const enhance = compose(
  withSnackbar,
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ScheduleDemo);
