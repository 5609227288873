/* Root Reducer */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { pmaReducer } from './pma.reducer';
import { homeReducer } from './home.reducer';
import { roiReducer } from './roi.reducer';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  pma: pmaReducer,
  home: homeReducer,
  roi: roiReducer,
});

export default rootReducer;