import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { StoreProvider } from "./Store";
//for pma Cover letter
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
/**common css files */
import "./index.css";

/**Client */
import { Provider } from "react-redux";
import { store } from "./_store";
import disableConsoleLogs from "common/utils/DisableConsoleLogs";

/**Disable console logs */
disableConsoleLogs();

ReactDOM.render(
  <StoreProvider>
    <Provider store={store}>
        <App />
    </Provider>
  </StoreProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
