import { roiActionConstants } from "_constants";
import { IsValidLoginAuth } from "common/storage";

const initState = {
  selectedROIAreas: [], //roi variables // ROI
  pendingROIAreas: [], // ROI
  licensedROIAreas: [], // ROI
  areaCityDataForCounty: {}, // ROI
  roiEnlargeView: false, //isFullValidSession ? false : true//by default not ellarged ROI
  roiLandingPageLimitedAccess: true, // !isFullValidSession,//assuming it would be open session ROI
  roiAreaIntervelId: null, // ROI
};

export function roiReducer(state = initState, action) {
  switch (action.type) {
    case roiActionConstants.INIT_ROI_OPEN_ACCESS_VIEW:
      const isFullValidSession = IsValidLoginAuth();
      return {
        ...state,
        roiLandingPageLimitedAccess: !isFullValidSession, //this helps to determine url (lp one or admin one), in case of roi access we need to hid top portlet bar also
        //if its open session then set the roiEnlargeView to true always otherwise old value
        roiEnlargeView: true, //as of now we want for everyone//!isFullValidSession ? true : state.roiEnlargeView
      };

    case roiActionConstants.SET_ROI_ENLARGE_VIEW:
      return {
        ...state,
        roiEnlargeView: action.payload,
      };

    case roiActionConstants.SET_ROI_AREA_INTERVEL_ID:
      return {
        ...state,
        roiAreaIntervelId: action.payload,
      };

    case roiActionConstants.ADD_ROI_AREA_CITY_DATA_FOR_COUNTY:
      return {
        ...state,
        areaCityDataForCounty: Object.assign({}, action.payload),
      };

    case roiActionConstants.ADD_SELECTED_AREA:
      const { selectedROIAreas } = state;
      if (
        selectedROIAreas.some((area) => area.areaID === action.payload.areaID)
      ) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          selectedROIAreas: [...state.selectedROIAreas, action.payload],
        };
      }

    case roiActionConstants.DELETE_SELECTED_AREA:
      let filtered = state.selectedROIAreas.filter(
        (area) => area.areaID !== action.payload
      );
      return {
        ...state,
        selectedROIAreas: filtered,
      };

    case roiActionConstants.HANDLE_ROI_AREA_ALLOCATION_RESPONSE:
      //retrieve pending and selected areas
      //we have to make sequence also for selected areas
      let oldData = state.selectedROIAreas;
      let newData = action.payload.selectedAreas;
      let finalArray = new Array(newData.length); //final array length would be equal to new data
      let appendArray = [];
      oldData = oldData.map((item) => item.areaID);
      for (let item of newData) {
        let positionInOldArray = oldData.indexOf(item.areaID);
        if (positionInOldArray !== -1) {
          finalArray[positionInOldArray] = item;
        } else {
          //add in append list
          appendArray.push(item);
        }
      }
      //now there is a chance if new data length is less than old data then we might have empty data key in final array remove them
      finalArray = finalArray.filter((item) => item && item.areaID);
      return {
        ...state,

        pendingROIAreas: action.payload.pendingAreas,
        selectedROIAreas: [...finalArray, ...appendArray],
        licensedROIAreas: action.payload.licensedAreas,
      };
    default:
      return state;
  }
}
