import React, { Component, Fragment, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  GetOAuthToken,
  GetStateStorage,
  IsValidLoginAuth,
  IsValidLPROIAuth,
  IsValidSemiAuth,
  IsValidSessionSwitch,
} from "common/storage";
import Base from "common/constants";
/**
 * General Website Routes (Non-Admin)
 * Description:- Routes that visible in registration, login related, website layout routes, general app required routes.
 */
import MyListingPage from "views/Pma/PmaEditor/Quarterly/TestRouter";
import SignIn from "./views/SignIn";
import ForgotPassword from "views/ForgotPassword";
import ResetPassword from "views/ResetPassword";
import Legal from "views/Legal";
import Home from "views/Home";
import About from "views/About";
import Pricing from "views/Pricing";
import NotFound from "./views/NotFound";
import Logout from "views/Logout";

// <!--Registration Routes + Validation Screen Routes -->
import TokenValidator from "views/TokenValidator";
import AgentDetails from "views/AgentDetails";
import RegisterAgent from "views/RegisterAgent";
import NewRegisterAgent from "views/NewRegisterAgent";

// <!-- PMA ROUTES -->
//Note:- Enabled lazy load. Reason is large size.
//import connectedAgentPMA from 'views/Pma';
import SubdivisionHacienda from "views/Pma/PmaEditor/SubdivisionHacienda";
import SubdivisionDelMesa from "views/Pma/PmaEditor/SubdivisionDelMesa";
import PMA from "views/Pma/PmaEditor";
import MailProMain from "views/Pma/MailPro";
import NewAgentStart from "views/NewAgentStart";
import DataVerification from "views/Pma/DataVerification/AgentDataVerification";
import DataVerificationTest from "views/Pma/DataVerification";
//import SendToPrint from 'views/Pma/SendToPrint';
///import TestPrint from './views/Pma/TestPrint';
import PrintPMA from "views/Pma/PmaEditor/PrintPMA";
import YearEndPrint from "views/Pma/PmaEditor/YearEndPrint";
import PrintSpeedometerPMA from "views/Pma/PmaEditor/PrintSpeedometerPMA";
import PrintYearEndPMA from "views/Pma/PmaEditor/PrintYearEndPMA";
import PrintMarketTrendsPMA from "views/Pma/PmaEditor/PrintMarketTrendsPMA";
import PrintTableQuadrantsPMA from "views/Pma/PmaEditor/PrintTableQuadrants";
import PrintBubbleTablePMA from "views/Pma/PmaEditor/PrintBubbleTable";
import PrintBubbleCompare from "views/Pma/PmaEditor/PrintBubbleCompare";
import PrintMarketIndicators from "views/Pma/PmaEditor/PrintMarketIndicators";
/**
 * (Agent + Admin) Admin Account Routes
 * Note:- Screens that both agent and adminc an see.
 */
import Dashboard from "views/Dashboard";
import Account from "views/Account";
import Schedule from "views/Schedule";
import CreditCardManager from "views/CreditCardManager";
import PmaInvoice from "views/PmaInvoice";
//import PrintSchedule from 'views/Schedule/PrintSchedule'; RETIRED
import AgentStrengthsScreen from "views/PmaAdmin/AgentStrengths";
// import AgentAdvantagesScreen from "views/PmaAdmin/AgentAdvantages";
import ClientTestimonialsScreen from "views/PmaAdmin/ClientTestimonials";
import AgentMissionStatementsScreen from "views/PmaAdmin/AgentMissions";
import PropertyHighlightsScreen from "views/PmaAdmin/PropertyHighlights";
import YearEndStatement from "views/PmaAdmin/YearEndStatement";
import QuadrantEditor from "views/PmaAdmin/QuadrantEditor";
import AgentFocus from "views/PmaAdmin/AgentFocus";
import AgentPerformance from "views/PmaAdmin/AgentPerformance";
import ScheduleDemo from "views/NewAgentStart/ScheduleDemo";
/**
 * Pure Admin Account Routes
 * Admin only. Only use lazyload.
 */
/**
 * IF YOUR COMPONENT USING CONTEXT STORE THEN DONT USE LAZY LOADING WITH THEM.
 */
import CreateAgent from "views/CreateAgent";
import { isUserAdmin, isUserAgent, isUserBrokerAdmin } from "common/roles/role";

const authMiddleware = (checkAuth, requireSemiAuth, requireFullAuth) => {
  // if (window.location.pathname !== '/login') {

  // };
  let isValidLogin = IsValidLoginAuth();
  let isValidSemi = IsValidSemiAuth();
  if (requireSemiAuth && isValidSemi) {
    return true;
  } else if (requireFullAuth && isValidLogin) {
    return true;
  } else if (requireSemiAuth && isValidLogin) {
    return true;
  } else {
    return false;
  }
};

const oneWayAuth = () => {
  let isValidLogin = IsValidLoginAuth();
  let isValidSemi = IsValidSemiAuth();
  if (isValidSemi || isValidLogin) {
    return true;
  }
  return false;
};

/**
 * Private(Auth) routes
 * isaAgentScreen :- If screen can be only use by agnet access/ by agent
 * isaAdminScreen :- If screen can be only use by admin access/ by admin
 */

const PrivateRoute = ({
  component: Component,
  requireSemiAuth,
  requireFullAuth,
  isaAgentScreen,
  isaAdminScreen,
  isaBrokerAdminScreen,
  ...rest
}) => {
  const authed = authMiddleware(true, requireSemiAuth, requireFullAuth);
  const isValidSessionSwitch = IsValidSessionSwitch();
  const oauthData = JSON.parse(GetOAuthToken());
  //Revisit all these rules for the broker admin PROGRAM switch TOOOODOOO
  //if it's isAgentOnly then either we can open in valid session switch. For others we cannot open in session switch mode
  //IF it's a AUTH route
  //There is no depenedency between agent and broker admin so here we going to compare (agent | admin | broker admin))
  //Broker admin is special kinf of agent that can admin other agent for counties i.e. like a broker
  //BTW a admin can open all the broker admin screens but not directly. Either through session switch or TODO some other means
  //Condition 1: if its a valid session switch then (admin screen and broker admin) should not be visible only agent screen would be visible
  //Condition 2: if its a admin session (no session switch) and screen is a (agent screen or broker admin screen only)
  //Condition 3: if its a agent session and opened screen is a (admin screen or broker admin screen) not a agent screen
  //Condition 4: If its a broker admin session (no session switch)  and screen is a admin or agent  not broker admin

  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          (isValidSessionSwitch &&
            (isaAdminScreen || isaBrokerAdminScreen) &&
            !isaAgentScreen) ||
          (!isValidSessionSwitch &&
            oauthData && isUserAdmin(oauthData.roleID) &&
            !isaAdminScreen &&
            (isaAgentScreen || isaBrokerAdminScreen)) ||
          (!isValidSessionSwitch &&
            oauthData &&
            isUserAgent(oauthData.roleID) &&
            (isaAdminScreen || isaBrokerAdminScreen) &&
            !isaAgentScreen) ||
          (!isValidSessionSwitch &&
            oauthData &&
            isUserBrokerAdmin(oauthData.roleID) &&
            (isaAdminScreen || isaAgentScreen) &&
            !isaBrokerAdminScreen) ? (
            <Redirect to={"/dashboard?mode=1"} />
          ) : (
            //You dont have access to requested page because your access role has changed. If it's a admin session redirect to mailpro insight page otherwise dashboard page with access message
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={"/login?mode=3"}
          />
        )
      }
    />
  );
};

/**
 * @name AuthRedirectRoute
 *  @description It can be used to out screen like password reset, forgot password etc. Make sure we dont have any semi, full or any landing page session for this. This one can be used for no kind of session.
 * @param {*} param0
 * @returns
 */
const AuthRedirectRoute = ({ component: Component, ...rest }) => {
  let isValid = oneWayAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        isValid === false ? (
          <Component {...props} />
        ) : (
          <Redirect exact to={{ path: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

/**
 * @name LandingPageRedirectRoute
 *  @description It can be used to oout screen like password reset, forgot password etc. Make sure we dont have any semi, full. This one case be used for landing page session only. It can be used for OB landing page and ROI screens.
 * isValidLPROISession will check for the full auth and semi auth also
 * @param {*} param0
 * @returns
 */
const LandingPageRedirectRoute = ({ component: Component, ...rest }) => {
  let isValidLPROISession =  IsValidLPROIAuth();
  //isValidLPROISession = true;
  return (
    <Route
      {...rest}
      render={(props) =>
        isValidLPROISession ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login?mode=2" />
          // state: { from: props.location }
        )
      }
    />
  );
};

class Routes extends Component {
  componentWillMount() {}

  componentDidMount() {}

  render() {
    return (
      <Switch>
        {/* DEFAULT REDIRECT ROUTES */}
        <Redirect
          exact
          from="/"
          to={oneWayAuth() ? "/home" : "/login?mode=0"}
        />
        {/* Testing routes */}
        {/* <Route exact component={Testing} path="/testing" /> */}
        {/*  */}
        {/* General Routes */}
        {/*  */}
        <AuthRedirectRoute exact component={SignIn} path="/login" />
        <AuthRedirectRoute
          exact
          component={ForgotPassword}
          path="/password/forgot"
        />
        <AuthRedirectRoute
          exact
          component={ResetPassword}
          path="/password/reset"
        />
        {/* <Route exact component={SignIn} path="/login" /> */}
        {/* <Route exact component={ForgotPassword} path="/password/forgot" />
        <Route exact component={ResetPassword} path="/password/reset" /> */}
        <Route
          path="/agent/open/website/:website"
          component={(website) => (window.location = website)}
        />
        {/* <PrivateRoute exact requireSemiAuth={false} requireFullAuth={true} isaAgentScreen={true} isaAdminScreen={true} path="/session/switch" component={SessionSwitch} /> */}
        <PrivateRoute
          exact
          requireSemiAuth={true}
          requireFullAuth={false}
          xxx={1111}
          isaAgentScreen={true}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
          path="/home"
          component={Home}
        />
        <PrivateRoute
          exact
          requireSemiAuth={true}
          requireFullAuth={false}
          isaAgentScreen={true}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
          path="/pricing"
          component={Pricing}
        />
        <PrivateRoute
          exact
          requireSemiAuth={true}
          requireFullAuth={false}
          isaAgentScreen={true}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
          path="/about"
          component={About}
        />
        {/* <PrivateRoute
          exact
          requireSemiAuth={true}
          requireFullAuth={false}
          isaAgentScreen={true}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
          path="/help"
          component={Help}
        /> */}
        <PrivateRoute
          exact
          requireSemiAuth={true}
          requireFullAuth={false}
          isaAgentScreen={true}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
          path="/legal"
          component={Legal}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          isaAgentScreen={true}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
          path="/schedule"
          component={Schedule}
        />
        <Route exact component={NotFound} path="/404" />
        <Route exact component={Logout} path="/logout" />
        {/*  */}
        {/* Register Agent Routes + Validation Screen Routes + Sales + Onboarding landing page routes */}
        {/*  */}
        <Route exact component={TokenValidator} path="/validateAgentToken" />
        <Route exact path="/start" component={NewAgentStart} />
        <PrivateRoute
          exact
          requireSemiAuth={true}
          requireFullAuth={false}
          path="/agent/register"
          component={RegisterAgent}
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
          //component={lazy(() => import("./views/RegisterAgent"))}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={false}
          path="/agent/new-register"
          component={NewRegisterAgent}
          isaAgentScreen={false}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        />
        {/* this would be a part of lp roi session */}
        {/* <LandingPageRedirectRoute
          exact
          //requireSemiAuth={false}
          //requireFullAuth={false}
          //component={SalesLandingPage}
          component={lazy(() => import("./views/ROILandingPage"))}
          path="/lp/agent/welcomepage"
        /> */}
        <Route
          exact
          //requireSemiAuth={false}
          //requireFullAuth={false}
          //component={SalesLandingPage}
          component={lazy(() => import("./views/OBLandingPage"))}
          path="/lp/schedule/presentation"
        />
        {/*  */}
        {/* //
        // PMA Routes
        // */}
        {/*  */}
        <Route
          exact
          component={PrintPMA}
          //component={lazy(() => import("./views/Pma/PrintPMA"))}
          path="/printpma/:listgenUserID/:mailproid"
        />
        <PrivateRoute
          exact
          component={PrintPMA}
          //component={lazy(() => import("./views/Pma/PrintPMA"))}
          path="/agent/pma/preview/:listgenUserID/:mailproid"
          requireSemiAuth={false}
          requireFullAuth={true}
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        />
        <Route
          exact
          component={ScheduleDemo}
          path="/schedule-demo"
          // requireSemiAuth={false}
          // requireFullAuth={false}
          // isaAgentScreen={false}
          // isaAdminScreen={false}
          // isaBrokerAdminScreen={false}
        />
        <Route
          exact
          component={PrintSpeedometerPMA}
          //component={lazy(() => import("./views/Pma/PrintPMA"))}
          path="/printpma/speedometer/:brokerage/:listgenUserID/:mailproid"
        />
        <Route
          exact
          component={YearEndPrint}
          path="/printpma/year-end/:brokerage/:listgenUserID/:mailproid"
        />
        <Route
          exact
          component={PrintYearEndPMA}
          path="/newpma/:listgenUserID/year-end/:mailproid"
        />
        <Route
          exact
          component={PrintMarketTrendsPMA}
          path="/printpma/market-trends/:brokerage/:listgenUserID/:mailproid"
        />
        <Route
          exact
          component={PrintMarketIndicators}
          path="/printpma/market-indicators/:brokerage/:listgenUserID/:mailproid"
        />
        <Route
          exact
          component={PrintTableQuadrantsPMA}
          path="/printpma/table-quadrants/:brokerage/:listgenUserID/:mailproid"
        />
        <Route
          exact
          component={PrintBubbleTablePMA}
          path="/printpma/bubble-table/:brokerage/:listgenUserID/:mailproid"
        />
        <Route
          exact
          component={PrintBubbleCompare}
          path="/printpma/bubble-compare/:brokerage/:listgenUserID/:mailproid"
        />
        {/* <Route
          component={PrintPMA}
          //component={lazy(() => import("./views/Pma/PrintPMA"))}
          path="/printpma/:version/:listgenUserID/:mailproid"
        /> */}
        {/* <Route
        exact
        component={PrintSchedule}
        path="/printschedule" /> */}
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/pma/subdivision/hacienda/:subName/:areaID"
          component={SubdivisionHacienda}
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
          //component={lazy(() => import("./views/Pma"))}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/pma/subdivision/delmesa/:subName/:areaID"
          component={SubdivisionDelMesa}
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
          //component={lazy(() => import("./views/Pma"))}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/pma/:areaID"
          component={PMA}
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
          //component={lazy(() => import("./views/Pma"))}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/pma"
          component={PMA}
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
          //component={lazy(() => import("./views/Pma"))}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/mailpro/:areaID"
          component={MailProMain}
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
          //component={lazy(() => import("./views/Pma"))}
        />
        {/*<PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/mailpro"
          component={MailProMain}
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
          //component={lazy(() => import("./views/Pma"))}
        />*/}
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/data-verification/:cityID/:areaID"
          component={DataVerification}
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
          //component={lazy(() => import("./views/Pma/DataVerification"))}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/test-data-verification/:cityID/:areaID"
          component={DataVerificationTest}
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
          //component={lazy(() => import("./views/Pma/DataVerification"))}
        />
        {/* <PrivateRoute
          exact
          requireSemiAuth={true}
          requireFullAuth={false}
          path="/pma/:isEditorMode"
          component={lazy(() => import("./views/Pma"))}
          isAgentOnly={true}
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        /> */}
        {/* <PrivateRoute
          exact
          requireSemiAuth={true}
          requireFullAuth={false}
          path="/send-to-print"
          component={lazy(() => import("./views/Pma/SendToPrint"))}
          isAgentOnly={true}
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        /> */}
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/invoice"
          component={PmaInvoice}
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          component={AgentStrengthsScreen}
          //component={lazy(() => import("./views/PmaAdmin/AgentStrengths"))}
          path="/agent/mystrengths"
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          component={PropertyHighlightsScreen}
          //component={lazy(() => import("./views/PmaAdmin/PropertyHighlights"))}
          path="/agent/property-highlights/:areaID"
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          component={PropertyHighlightsScreen}
          //component={lazy(() => import("./views/PmaAdmin/PropertyHighlights"))}
          path="/agent/property-highlights"
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          component={QuadrantEditor}
          path="/agent/quadrant-editor/:areaID/:type/:position"
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          component={AgentFocus}
          path="/agent-focus"
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        />
        //need to change auth back to true
        <PrivateRoute
          // exact
          requireSemiAuth={true}
          // requireFullAuth={true}
          requireFullAuth={false}
          component={YearEndStatement}
          path="/agent/year-end-statement/:listgenUserID/:year"
          //path="/agent/year-end-statement/listgenUserID=100040/2022"
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        />
        {/* <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          component={AgentAdvantagesScreen}
          path="/agent/myadvantages"
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        /> */}
        <PrivateRoute
          requireSemiAuth={false}
          requireFullAuth={true}
          exact
          component={ClientTestimonialsScreen}
          //component={lazy(() => import("./views/PmaAdmin/ClientTestimonials"))}
          path="/agent/clienttestimonials"
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          requireSemiAuth={false}
          requireFullAuth={true}
          exact
          component={AgentMissionStatementsScreen}
          //component={lazy(() => import("./views/PmaAdmin/ClientTestimonials"))}
          path="/agent/missionstatements"
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          requireSemiAuth={false}
          requireFullAuth={true}
          exact
          component={lazy(() => import("./views/PmaAdmin/AgentROIForm"))}
          path="/agent/roi/form"
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          requireSemiAuth={false}
          requireFullAuth={true}
          exact
          component={AgentPerformance}
          path="/agent/agent-performance"
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        />
        {/*  */}
        {/* Both Admin + Agent + Other  routes */}
        {/*  */}
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/dashboard"
          component={Dashboard}
          isaAgentScreen={true}
          isaAdminScreen={true}
          isaBrokerAdminScreen={true}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/account"
          component={Account}
          isaAgentScreen={true}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        {/* For agent, not required for the county admin */}
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/agent/details/ag/:listgenUserID"
          component={lazy(() => import("./views/AgentDetails"))}
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        />
        {/* For Admin,not required for the county admin */}
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/agent/details/ad/:listgenUserID"
          component={lazy(() => import("./views/AgentDetails"))}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        {/* For agent side. ag means agent, not required for the county admin */}
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/cards/credit/ag/:listgenUserID/:agentFirstName/:agentLastName/:emailID"
          component={CreditCardManager}
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        />
        {/* For Admin Side. ad means admin, not required for the county admin */}
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/cards/credit/ad/:listgenUserID/:agentFirstName/:agentLastName/:emailID"
          component={CreditCardManager}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        {/* //Not In Use right now */}
        {/* //No screen for agent as of now for special payments, not required for the county admin */}
        {/* <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/specialpayments/ag/:listgenUserID/:agentFirstName/:agentLastName/:emailID/:ownedMLSID"
          //component={SpecialPayment}
          component={lazy(() => import("./views/SpecialPayment"))}
          isaAgentScreen={true}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        /> */}
        {/* For Admin, not required for the county admin */}
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/specialpayments/ad/:listgenUserID/:agentFirstName/:agentLastName/:emailID/:ownedMLSID"
          //component={SpecialPayment}
          component={lazy(() => import("./views/SpecialPayment"))}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/specialpayments/v1/ad/:listgenUserID/:agentFirstName/:agentLastName/:emailID/:ownedMLSID"
          //component={SpecialPayment}
          component={lazy(() => import("./views/SpecialPaymentV1"))}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        {/* //Not In Use right now, for Agent,  not required for the county admin */}
        {/* <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/agent/invoice/history/ag/:listgenUserID/:agentFirstName/:agentLastName/:emailID"
          component={InvoiceHistory}
          isaAgentScreen={false}
          isaAdminScreen={false}
          isaBrokerAdminScreen={false}
        /> */}
        {/* For Admin,  not required for the county admin */}
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/agent/invoice/history/ad/:listgenUserID/:agentFirstName/:agentLastName/:emailID"
          component={lazy(() => import("./views/InvoiceHistory"))}
          //component={InvoiceHistory}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        {/*  */}
        {/* <!--- Pure Admin Routes --> */}
        {/*  */}
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/agent/create"
          component={CreateAgent} //Exceptional case using too much context Store.
          //component={lazy(() => import("./views/CreateAgent"))}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/agent/search"
          //component={SearchAgent}
          component={lazy(() => import("./views/SearchAgent"))}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/manage/bridge/agents"
          //component={SearchAgent}
          component={lazy(() => import("./views/MLSAgentsAdmin"))}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/agent/print/manage"
          component={lazy(() => import("./views/PrintManager"))}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/agent/pma/schedulesv1"
          component={lazy(() => import("./views/AgentPmaSchedulesV1"))}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/admin/mailing/schedule"
          component={lazy(() => import("./views/Schedule/admin"))}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        {/* <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/admin/hoasubdivion"
          //component={HoaSubdivisonComplex}
          component={lazy(() => import("./views/HoaSubdivisonComplex"))}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        /> */}
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/agent/mailpro/insights"
          //component={MailproInsights}
          component={lazy(() => import("./views/MailproInsightsV1"))}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/lg/constants"
          //component={LgConstants}
          component={lazy(() => import("./views/LgConstants"))}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        {/* Sales reports routes */}
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/sales/report/agentperformance"
          //component={AgentPerformance}
          component={lazy(() =>
            import("./views/SalesReports/AgentPerformance")
          )}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/agent/help/request"
          //component={HelpRequest}
          component={lazy(() => import("./views/HelpRequest"))}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/onboarding/invite/agents"
          //component={ROIFullScreen}
          component={lazy(() =>
            import("./views/AgentOnbordingPortal/OBAgentInvite")
          )}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute //PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/onboarding/roi/invite/agents"
          //component={ROIFullScreen}
          component={lazy(() =>
            import("./views/AgentOnbordingPortal/OBAgentROIInvite")
          )}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/onboarding/presentation/agents"
          //component={ROIFullScreen}
          component={lazy(() =>
            import("./views/AgentOnbordingPortal/OBAgentPresentations")
          )}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/onboarding/view/brokerages"
          //component={ROIFullScreen}
          component={lazy(() =>
            import("./views/AgentOnbordingPortal/OBViewBrokerages")
          )}
          isaAgentScreen={false}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        <Route
          exact
          path="/agent/printroi/:countyID/:countyName/:areaID"
          component={lazy(() => import("./views/AgentROI/AreaROIPreview"))}
        />
        {/* For admin. aoi and areas-by-city require for open access also */}
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/agent/roi/:areaID?emailAddress=:emailAddress&listgenUserFirstName=:listgenUserFirstName&listgenUserLastName=:listgenUserLastName&brokerageName=:brokerageName&countyID=:countyID&countyName=:countyName&listgenUserID=:listgenUserID&agentLicenseNumber=:agentLicenseNumber"
          component={lazy(() => import("./views/AgentROI/AreaROIPreview"))}
          isaAgentScreen={true}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/agent/roi/:areaID"
          component={lazy(() => import("./views/AgentROI/AreaROIPreview"))}
          isaAgentScreen={true}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/agent/areas-by-city"
          component={lazy(() => import("./views/AgentROI"))}
          isaAgentScreen={true}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        <PrivateRoute
          exact
          requireSemiAuth={false}
          requireFullAuth={true}
          path="/compass-rev-share-report"
          component={lazy(() => import("./views/CompassRevShareReport"))}
          isaAgentScreen={true}
          isaAdminScreen={true}
          isaBrokerAdminScreen={false}
        />
        {/* For Landing Page access */}
        <LandingPageRedirectRoute
          exact
          path="/lp/open/agent/roi/:areaID"
          component={lazy(() => import("./views/AgentROI/AreaROIPreview"))}
        />
        <LandingPageRedirectRoute
          exact
          path="/lp/open/agent/areas-by-city"
          component={lazy(() => import("./views/AgentROI"))}
        />
        {/* <!-- END !--> */}
        <Route
          exact
          //component={PMAUrlSwitcher}
          component={lazy(() => import("./views/PMAUrlSwitcher"))}
          path="/pmaurlswitcher"
        />
        {/* <!-- General Routes --> */}
        <Redirect to="/404" />
      </Switch>
    );
  }
}

export default Routes;
