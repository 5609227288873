// Todo: change ready to mail to .every()

import React, { Fragment, createRef } from "react";
import { connect } from "react-redux";
import { pmaActions } from "../../../_store/_actions";
import { GetStateStorage } from "common/storage";
import {
  Button,
  Typography,
  CircularProgress,
  Tooltip,
  ClickAwayListener,
  Backdrop,
  Chip,
  Avatar,
} from "@material-ui/core";
import { MailingHeaderSearchBox } from "../MailPro/components/MailingHeaderSearchBox";
import { propertyUtil } from "../PropertyUtil";
import classNames from "classnames";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import compose from "recompose/compose";
import { withSnackbar } from "notistack";
import { showSuccessNotification } from "common/helpers";
import { renderLogicUtil } from "../RenderLogicUtil";
import { withRouter } from "react-router-dom";
import AnniversaryAutomatorDialog from "../MailPro/components/Notes/AnniversaryAutomator/AnniversaryAutomatorDialog";
import moment from "moment";
import { MailProConstants } from "../MailPro/PmaMailingList/MailProConstants";
import { NotesCameFrom } from "../MailPro/components/Notes/Common/NotesConfig";
import { noteUtils } from "../MailPro/components/Notes/Common/NoteUtils";
import ApproveMailingListDialog from "../MailPro/components/ApproveMailingListDialog/ApproveMailingListDialog";
import { toLowerCase } from 'common/utils/format';

class NavbarFilterBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showConfirmationDialog: false,
      tempShowApprovalTooltip: true,
      tempDisableApprovalButton: true,
    };
    this.approveMailingList = this.approveMailingList.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.defaultSort = this.defaultSort.bind(this);
    this.filterSort = this.filterSort.bind(this);
    this.continueEditingMode = this.continueEditingMode.bind(this);
    this.finishedEditingMode = this.finishedEditingMode.bind(this);
    /**
     * Ref variables for popover
     */
    this.popoverRef = createRef();
  }

  componentDidMount() {
    this.props.enableMailProApprovalProcess && !this.props.currentArea.isAreaPaused &&
      setTimeout(() => {
        this.setState({
          tempDisableApprovalButton: false,
        });
      }, 5000);
  }

  handleSearch = (searchValue) => {
    this.props.updateHOSearch(searchValue);
  };

  filterSort = (e) => {
    this.props.updateHOFilter(e.target.value);
  };

  defaultSort = async () => {
    /**On click of RESET button we need to sort by active status */
    await this.props.resetProMailHeaderandAllFilters();
  };

  approveMailingListConfirmation = () => {
    this.setState({
      showConfirmationDialog: true,
    });
  };

  continueEditingMode = () => {
    this.setState({
      showConfirmationDialog: false,
    });
  };

  finishedEditingMode = () => {
    this.setState(
      {
        showConfirmationDialog: true,
      },
      () => {
        this.approveMailingList();
      }
    );
  };

  async approveMailingList() {
    try {
      this.setState(
        {
          isLoading: true,
        },
        async () => {
          const { mlsAreas, currentArea, propertyData } = this.props;

          const { mlsAreaID } = currentArea;
          let totalProperties = propertyData.length;
          const actualHomeowners = propertyData.filter(
            (prop) => parseInt(prop.agentID) === 0
          );
          const agentList = propertyData
            .filter((prop) => parseInt(prop.agentID) > 0)
            .map((item) => item.mailerProID);
          let mlsAreasCopy = mlsAreas.slice();
          const index = mlsAreas
            .map((e) => e.mlsAreaID)
            .indexOf(currentArea.mlsAreaID);
          let updatedMLSProperty = Object.assign({}, mlsAreas[index], {
            mailingStatus: "Approved",
          });
          mlsAreasCopy[index] = updatedMLSProperty;
          //console.log('mlsAreasCopy from Mailing header', mlsAreasCopy);

          let unblockedHO = actualHomeowners
            .filter((prop) => {
              if (!prop.changeDetails) {
                return true;
              }
              if (prop.changeDetails && prop.changeDetails.blocked === null) {
                return true;
              }
              if (!prop.changeDetails.blocked) {
                return true;
              }
              return false;
            })
            .map((item) => item.mailerProID);
          //console.log('unblockedHO', unblockedHO);
          const IDsToSend = [...unblockedHO, ...agentList];
          //console.log('IDsToSend', IDsToSend);
          const approveObj = {
            areaID: mlsAreaID,
            mailProIDs: IDsToSend,
          };
          /**check for all area approved also */
          let allAreasApproved = true;
          let updatedMLSAreasCopy = mlsAreasCopy.map((item, key) => {
            if (
              (item.numOfPMASending === null || item.numOfPMASending === 0) &&
              key === index
            ) {
              item.numOfPMASending = Array.isArray(IDsToSend)
                ? IDsToSend.length
                : 0;
            } else if (key !== index && item.numOfPMASending === null) {
              item.numOfPMASending = 0;
            }
            /**check for all area approved logic also */
            if (
              !item.mailingStatus ||
              item.mailingStatus !== "Approved" ||
              item.mailingStatus === null ||
              item.mailingStatus === ""
            ) {
              allAreasApproved = false;
            }
            return item;
          });
          await this.props.approveMailingList(approveObj);
          await this.props.updateNavbar(updatedMLSAreasCopy);
          //check fro all area approval
          if (allAreasApproved) {
            console.log("allAreas are approved");
            await this.changeMailProStatus();
          }
          setTimeout(async () => {
            //request arent async/await so put in timeout
            this.setState({
              isLoading: false,
              showConfirmationDialog: false,
            });
            /**Apply the login here for all approved one */
            //If all areas approved no need to enable approvalSwitchStepAreaFn
            if (!allAreasApproved) {
              //not all areas are approved let check if enable approval flag is true then call
              this.approvalSwitchStepAreaFn();
            }
          }, 4000);
        }
      );
    } catch (e) {
      //console.log('error in approving mailng list', e);
      showSuccessNotification(this.props, "Unable to approve area.");
      this.setState({
        isLoading: false,
        showConfirmationDialog: false,
      });
    }
  }

  changeMailProStatus = async () => {
    const stateData = GetStateStorage();
    const { listgenUserID } = stateData.userData;
    await this.props.changeMailProStatus(listgenUserID, "allAreasApproved");
    return;
  };

  closeApprovalTool = () => {
    this.setState({
      tempShowApprovalTooltip: false,
    });
  };

  approvalSwitchStepAreaFn = () => {
    let { mlsAreas, currentArea, enableMailProApprovalProcess } = this.props;
    //activate this process if we enabled the approval process and in getOneUnapprovedAreaData function check for isAreaPaused as well
    if (enableMailProApprovalProcess) {
      let newAreaData = propertyUtil.getOneUnapprovedAreaData(
        mlsAreas,
        currentArea.mlsAreaID
      );
      if (newAreaData) {
        showSuccessNotification(this.props, "Area approved. Loading...");
        setTimeout(() => {
          this.props.switchStep(0);
          this.props.changeCurrentArea(newAreaData.areaIndex, newAreaData.area);
          this.props.history.push("/pma");
        }, 1000);
      } else {
        showSuccessNotification(this.props, "All area(s) are approved.");
      }
    }
  };

  handleNewMailingChipButtons = (mode) => {
    this.props.explicitEnableNewMailProInitFn(true);
    this.props.disableNewMailingForCurrentSessionFn(false);
    this.props.setNewMailProInitDialogModeFn(mode);
  };

  render() {
    let {
      hoSearch,
      hoFilter,
      hoQuickFilter,
      propertyData,
      mlsAreas,
      currentArea,
      mailProStatus,
      agentID,
      currentPeriod,
      classes,
      enableMailProApprovalProcess,
      mailingVersion,
      version,
    } = this.props;
    /**get current area */
    /**now check for status */
    const readyToMail = mlsAreas.every(
      (area) => area.mailingStatus === "Approved"
    );
    let agentListCount = 0;
    const actualHomeowners = propertyData.filter((prop) => {
      if (parseInt(prop.agentID) === 0) {
        return true;
      }
      agentListCount += 1;
      return false;
    });

    let countHOMap = {
      all: actualHomeowners.length + agentListCount,
      blocked: 0,
      active: agentListCount,
      delete: 0,
      congrats: 0,
      totalAnniversaries: 0, // this is starting anniversaries for a printing period
      anniversary: 0, // this will become totalAnniversaries - anniversaryNotesWritten. It will become 0 when all anniversary notes are written
      expired: 0,
      absentee: 0,
      cancelled: 0,
      duplicateMailingAddress: 0,
      homeValuation: 0,
      condoBlocked: 0,
    };
    let shouldAnniversaryCountButtonFlash = false;

    actualHomeowners.map((prop) => {
      if (prop.HOQuickFilterData.isBlocked) {
        countHOMap["blocked"] += 1;
      }
      if (prop.HOQuickFilterData.duplicateMailingAddress) {
        countHOMap["duplicateMailingAddress"] += 1;
      }
      if (prop.HOQuickFilterData.isDeleted) {
        countHOMap["delete"] += 1;
      }
      if (prop.HOQuickFilterData.isActive) {
        countHOMap["active"] += 1;
      }
      if (prop.isRequestedForValuation) {
        countHOMap["homeValuation"] += 1;
      }
      
      if (toLowerCase(prop.changeDetails.blocked) === "blocked condos") {
        countHOMap["condoBlocked"] += 1;

      }
      // isCongrats, isAnniversary, isExpired, isAbsentee, isCancelled
      // if (
      //   prop.HOQuickFilterData.isCongrats &&
      //   renderLogicUtil.checkPropertyStatuswrtHOFilterStatus(prop, hoFilter)
      // ) {
      //   countHOMap["congrats"] += 1;
      // }
      if (
        prop.HOQuickFilterData.isAnniversary &&
        renderLogicUtil.checkPropertyStatuswrtHOFilterStatus(prop, hoFilter)
      ) {
        //!shouldAnniversaryCountButtonFlash
        countHOMap["totalAnniversaries"] += 1;
        if (noteUtils.getAPropertyPendingAnniversaryNoteStatuswrtHOFilter(prop, NotesCameFrom.Anniversary, hoFilter)) {
          //disable anniversary button when all anniversary homeowners has a anniversary note written
          shouldAnniversaryCountButtonFlash = true;
          //here we will be taking care of skip anniversary note as well
          countHOMap["anniversary"] += 1;
        }
      }
      // if (
      //   prop.HOQuickFilterData.isExpired &&
      //   renderLogicUtil.checkPropertyStatuswrtHOFilterStatus(prop, hoFilter)
      // ) {
      //   countHOMap["expired"] += 1;
      // }
      if (
        prop.HOQuickFilterData.isAbsentee &&
        renderLogicUtil.checkPropertyStatuswrtHOFilterStatus(prop, hoFilter)
      ) {
        countHOMap["absentee"] += 1;
      }
      // if (
      //   prop.HOQuickFilterData.isCancelled &&
      //   renderLogicUtil.checkPropertyStatuswrtHOFilterStatus(prop, hoFilter)
      // ) {
      //   countHOMap["cancelled"] += 1;
      // }
    });

    const newMailingHOPropertiesCount = renderLogicUtil.countMailProInitProperties(
      propertyData,
      true,
      MailProConstants.newMailing,
      this.props.hoFilter
    );
    const newlyConstructedHOPropertiesCount = renderLogicUtil.countMailProInitProperties(
      propertyData,
      true,
      MailProConstants.isNewProperty,
      this.props.hoFilter
    );

    return (
      <Fragment>
        {/* check for confirmation dialog */}
        {this.state.showConfirmationDialog ? (
          <ApproveMailingListDialog
            approveMailingListFn={this.finishedEditingMode}
            continueEditingModeFn={this.continueEditingMode}
            mlsAreas={mlsAreas}
            hoFilter={hoFilter}
            areaName={
              currentArea &&
              currentArea.mlsNeighborhood &&
              currentArea.mlsNeighborhood
            }
            {...this.props}
          />
        ) : (
          ""
        )}
        <div className={classes.mailingHeader}>
          <div className={classes.lasttoFirstButtonSectionUpdated}>
            {this.state.isLoading ? (
              <div className={classes.headerLoader}>
                <CircularProgress />
              </div>
            ) : (
              <Fragment>
                {!enableMailProApprovalProcess ? (
                  <Fragment>
                    {/* && Number(this.props.newMailingHOPropertiesCount) > 0 */}
                    {/* <div className={classes.blockedHeaderNotice}>
                          <Typography variant="body2" className={classNames(classes.whiteColor)}>
                          <p>You have {this.props.newMailingHOPropertiesCount} new Homeowner{this.props.newMailingHOPropertiesCount > 1 ? "s" : ''} in MailPro. </p>
                            <p>You need to go through that list to approve your MailPro. </p>
                          </Typography>
                      </div> */}
                  </Fragment>
                ) : (
                  <Fragment>
                    {mailProStatus === "allAreasApproved" || readyToMail ? (
                      <Fragment>
                        <div className={classes.blockedHeaderSuccess}>
                          <Typography
                            variant="body2"
                            className={classNames(
                              classes.whiteColor,
                              classes.blockMarginRight
                            )}
                          >
                            <p>Thank you! All your areas have been approved.</p>
                            <p>
                              When your PMAs are being printed, MailPro is
                              temporarily locked.{" "}
                            </p>
                          </Typography>
                          <a
                            href={`https://api.listgen.com/provisionalInvoice/${
                              this.props.agentID
                            }/${moment(new Date()).format("DD")}/${moment(
                              new Date()
                            ).format("MM")}/${moment(new Date()).format(
                              "YYYY"
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              size="small"
                              variant="contained"
                              className={classNames(
                                classes.marginLeftViewInvoice
                              )}
                              color="primary"
                            >
                              View Estimated Invoice
                            </Button>
                          </a>
                        </div>
                      </Fragment>
                    ) : currentArea.mailingStatus == "Approved" ? (
                      <div>
                        <Typography
                          variant="subtitle2"
                          className={classes.alreadyApproved}
                        >
                          Area{" "}
                          {currentArea &&
                            currentArea.mlsNeighborhood &&
                            currentArea.mlsNeighborhood}{" "}
                          has been Approved
                        </Typography>
                      </div>
                    ) : !this.state.tempDisableApprovalButton ? (
                      <Fragment>
                        <Backdrop
                          className={classes.approvalBackdrop}
                          open={
                            enableMailProApprovalProcess &&
                            this.state.tempShowApprovalTooltip
                          }
                          //onClick={event => this.closeApprovalTool()}
                        ></Backdrop>
                        <ClickAwayListener onClickAway={() => {}}>
                          <Tooltip
                            interactive
                            arrow={true}
                            PopperProps={{
                              disablePortal: true,
                            }}
                            //onClose={this.closeApprovalTool}
                            open={
                              enableMailProApprovalProcess &&
                              this.state.tempShowApprovalTooltip
                            }
                            placement="bottom"
                            classes={{
                              //popper: '',
                              arrow: classes.approvalTooltipArrow,
                              tooltip: classes.approvalTooltipMain,
                              tooltipArrow: classes.tooltipArrow,
                              arrow: classes.tooltipArrowMain,
                            }}
                            //disableFocusListener
                            //disableHoverListener
                            //disableTouchListener
                            title={
                              <Fragment>
                                <p>
                                  Please click on the above green{" "}
                                  <span className={classes.approvalGreenBg}>
                                    APPROVE
                                  </span>{" "}
                                  tab if you've finished your Global Note and/or
                                  any Personal Notes and/or any Changes for Area
                                </p>
                                <p>
                                  {currentArea &&
                                    currentArea.mlsNeighborhood &&
                                    currentArea.mlsNeighborhood}{" "}
                                  .
                                </p>
                                <br />
                                {/*<p className={classes.approvalRedColor}>
                                    Today is the deadline for approving your
                                    PMA!
                                  </p>*/}
                                {Array.isArray(mlsAreas) &&
                                mlsAreas.length > 1 ? (
                                  <Fragment>
                                    <br />
                                    <p>
                                      Please click the{" "}
                                      <span className={classes.approvalGreenBg}>
                                        APPROVE
                                      </span>{" "}
                                      button for each of your areas.
                                    </p>
                                  </Fragment>
                                ) : (
                                  ""
                                )}
                                <div
                                  className={classes.approvalButtonContainer}
                                >
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="default"
                                    onClick={(event) =>
                                      this.closeApprovalTool()
                                    }
                                    className={classes.approvalButton}
                                  >
                                    Continue Editing
                                  </Button>
                                </div>
                              </Fragment>
                            }
                          >
                            <div
                              className={
                                enableMailProApprovalProcess &&
                                this.state.tempShowApprovalTooltip
                                  ? classes.approvalBackdropHighlight
                                  : ""
                              }
                            >
                              <Button
                                variant="contained"
                                className={classNames(
                                  classes.approveMailingList
                                )}
                                onClick={(event) => {
                                  event.currentTarget.style.animation = 0;
                                  this.approveMailingListConfirmation();
                                  this.closeApprovalTool(); //call this one also so ig agent directly click on approval button so backdrop can be hided.
                                }}
                                ref={this.popoverRef}
                                disabled={this.state.tempDisableApprovalButton}
                              >
                                Click to Approve the{" "}
                                {moment(new Date()).format("MMMM")} Mailing for
                                This Area
                              </Button>
                            </div>
                          </Tooltip>
                        </ClickAwayListener>
                      </Fragment>
                    ) : null}
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
          <div className={classes.secondBlockedFilterSection}>
            <MailingHeaderSearchBox
              onSearchChange={this.handleSearch}
              onResetClick={this.defaultSort}
              onFilterClick={this.filterSort}
              hoSearch={hoSearch}
              hoFilter={hoFilter}
              showDeleteFilter={currentArea && currentArea.showDeleteButton}
              showDuplicateMailingAddressFilter={true} //currentArea && currentArea.mlsAreaID === "160"}
              countHOMap={countHOMap}
              updateHOAdvanceFilter={this.props.updateHOAdvanceFilter}
              updateHOAdvanceFilterOptionData={
                this.props.updateHOAdvanceFilterOptionData
              }
              hoAdvanceFilter={this.props.hoAdvanceFilter}
              propertyData={propertyData}
              agentData={this.props.agentData}
            />
          </div>
          <div className={classes.firstToLastBlockedTextSectionUpdated}>
            <div
              style={{
                display: "grid",
                gridTemplateRows: "auto auto",
                gridAutoFlow: "column",
                overflow: "visible",
                gridGap: "4px 16px",
                alignItems: "center",
              }}
            >
              <AnniversaryAutomatorDialog
                agentData={this.props.agentData}
                defaultOpen={false}
                mlsAreas={mlsAreas}
                currentArea={currentArea}
                cameFrom="Anniversary"
                key={"anniversary_automator_from_navbar"}
                isMailProLocked={this.props.isMailProLocked}
              >
                <Chip
                  size="small"
                  avatar={<Avatar>{countHOMap.anniversary}</Avatar>}
                  label="HAPPY ANNIVERSARY!"
                  clickable
                  onDelete={
                    hoQuickFilter === "anniversary"
                      ? (event) => this.props.updateHOQuickFilter("")
                      : null
                  }
                  color={
                    hoQuickFilter === "anniversary" ? "primary" : "default"
                  }
                  variant={
                    hoQuickFilter === "anniversary" ? "default" : "outlined"
                  }
                  onClick={(event) =>
                    this.props.updateHOQuickFilter("anniversary")
                  }
                  style={{ width: "fit-content" }}
                  classes={{
                    root: classes.boldFontWeight,
                    clickableColorPrimary: classes.chipClickablePrimary,
                    avatarSmall: classes.avatarChip,
                    clickable: classes.clickableChip,
                    deleteIconColorPrimary: classes.deleteIconColorPrimary,
                  }}
                  disabled={countHOMap.totalAnniversaries === 0}//we cannot use anniversary here as it will be 0 when all anniversary notes are written and it will disable the button. But agent can still write notes for anniversary homeowners or see / sort them.
                  className={
                    countHOMap.anniversary !== 0 &&
                    shouldAnniversaryCountButtonFlash
                      ? classes.flashButton
                      : null
                  }
                />
              </AnniversaryAutomatorDialog>
              <Chip
                size="small"
                avatar={<Avatar>{countHOMap.absentee}</Avatar>}
                label="ABSENTEE HOMEOWNERS"
                clickable
                onDelete={
                  hoQuickFilter === "absentee"
                    ? (event) => this.props.updateHOQuickFilter("")
                    : null
                }
                color={hoQuickFilter === "absentee" ? "primary" : "default"}
                variant={hoQuickFilter === "absentee" ? "default" : "outlined"}
                onClick={(event) => this.props.updateHOQuickFilter("absentee")}
                style={{ width: "fit-content" }}
                classes={{
                  root: classes.boldFontWeight,
                  clickableColorPrimary: classes.chipClickablePrimary,
                  avatarSmall: classes.avatarChip,
                  clickable: classes.clickableChip,
                  deleteIconColorPrimary: classes.deleteIconColorPrimary,
                }}
                disabled={countHOMap.absentee === 0}
              />
              {/* <Chip
                size="small"
                avatar={<Avatar>{countHOMap.congrats}</Avatar>}
                label="WELCOME TO THE NEIGHBOURHOOD!"
                clickable
                onDelete={
                  hoQuickFilter === "congrats"
                    ? (event) => this.props.updateHOQuickFilter("")
                    : null
                }
                color={hoQuickFilter === "congrats" ? "primary" : "default"}
                variant={hoQuickFilter === "congrats" ? "default" : "outlined"}
                onClick={(event) => this.props.updateHOQuickFilter("congrats")}
                classes={{
                  root: classes.boldFontWeight,
                  clickableColorPrimary: classes.chipClickablePrimary,
                  avatarSmall: classes.avatarChip,
                  clickable: classes.clickableChip,
                  deleteIconColorPrimary: classes.deleteIconColorPrimary,
                }}
                disabled={countHOMap.congrats === 0}
                style={{
                  gridColumnStart: 1,
                  gridRowStart: 3,
                  gridColumnEnd: 3,
                  width: "fit-content",
                }}
              /> */}
              {newMailingHOPropertiesCount > 0 && (
                <Chip
                  size="small"
                  avatar={<Avatar>{newMailingHOPropertiesCount || 0}</Avatar>}
                  label={`NEW HOMEOWNER${
                    newMailingHOPropertiesCount > 1 ? `S` : ``
                  }`} //${newMailingHOPropertiesCount || 0}
                  clickable
                  onDelete={
                    hoQuickFilter === MailProConstants.newMailing
                      ? (event) => this.props.updateHOQuickFilter("")
                      : null
                  }
                  color={
                    hoQuickFilter === MailProConstants.newMailing
                      ? "primary"
                      : "default"
                  }
                  variant={
                    hoQuickFilter === MailProConstants.newMailing
                      ? "default"
                      : "outlined"
                  }
                  onClick={(event) =>
                    this.handleNewMailingChipButtons(
                      MailProConstants.newMailing
                    )
                  }
                  classes={{
                    root: classes.boldFontWeight,
                    clickableColorPrimary: classes.chipClickablePrimary,
                    avatarSmall: classNames(
                      classes.avatarChip,
                      classes.newPropertyChipBg
                    ),
                    clickable: classes.clickableNewPropertyChip,
                    deleteIconColorPrimary: classes.deleteIconColorPrimary,
                  }}
                  className={classes.flashButton}
                  disabled={!newMailingHOPropertiesCount}
                  style={{ width: "fit-content" }}
                />
              )}
              {newlyConstructedHOPropertiesCount > 0 && (
                <Chip
                  size="small"
                  avatar={
                    <Avatar>{newlyConstructedHOPropertiesCount || 0}</Avatar>
                  }
                  label={`NEW ${
                    newlyConstructedHOPropertiesCount > 1
                      ? `PROPERTIES`
                      : `PROPERTY`
                  } TO ADD`} //${newlyConstructedHOPropertiesCount || 0}
                  clickable
                  onDelete={
                    hoQuickFilter === MailProConstants.isNewProperty
                      ? (event) => this.props.updateHOQuickFilter("")
                      : null
                  }
                  color={
                    hoQuickFilter === MailProConstants.isNewProperty
                      ? "primary"
                      : "default"
                  }
                  variant={
                    hoQuickFilter === MailProConstants.isNewProperty
                      ? "default"
                      : "outlined"
                  }
                  onClick={(event) =>
                    this.handleNewMailingChipButtons(
                      MailProConstants.isNewProperty
                    )
                  }
                  classes={{
                    root: classNames(classes.boldFontWeight),
                    clickableColorPrimary: classes.chipClickablePrimary,
                    avatarSmall: classNames(
                      classes.avatarChip,
                      classes.newlyConstructedChipBg
                    ),
                    clickable: classes.clickableNewlyConstructedChip,
                    deleteIconColorPrimary: classes.deleteIconColorPrimary,
                  }}
                  className={classes.flashButton}
                  disabled={!newlyConstructedHOPropertiesCount}
                  style={{ width: "fit-content" }}
                />
              )}

              {/* : ''} */}
              {/* {countHOMap.expired > 0 ? */}
              {/* <Chip
                size="small"
                avatar={<Avatar>{countHOMap.expired}</Avatar>}
                label="EXPIRED LISTING"
                clickable
                onDelete={
                  hoQuickFilter === "expired"
                    ? (event) => this.props.updateHOQuickFilter("")
                    : null
                }
                color={hoQuickFilter === "expired" ? "primary" : "default"}
                variant={hoQuickFilter === "expired" ? "default" : "outlined"}
                onClick={(event) => this.props.updateHOQuickFilter("expired")}
                style={{ width: "fit-content" }}
                classes={{
                  root: classes.boldFontWeight,
                  clickableColorPrimary: classes.chipClickablePrimary,
                  avatarSmall: classes.avatarChip,
                  clickable: classes.clickableChip,
                  deleteIconColorPrimary: classes.deleteIconColorPrimary,
                }}
                disabled={countHOMap.expired === 0}
              />
              <Chip
                size="small"
                avatar={<Avatar>{countHOMap.cancelled}</Avatar>}
                label="CANCELLED LISTING"
                clickable
                onDelete={
                  hoQuickFilter === "cancelled"
                    ? (event) => this.props.updateHOQuickFilter("")
                    : null
                }
                color={hoQuickFilter === "cancelled" ? "primary" : "default"}
                variant={hoQuickFilter === "cancelled" ? "default" : "outlined"}
                onClick={(event) => this.props.updateHOQuickFilter("cancelled")}
                style={{ width: "fit-content" }}
                classes={{
                  root: classes.boldFontWeight,
                  clickableColorPrimary: classes.chipClickablePrimary,
                  avatarSmall: classes.avatarChip,
                  clickable: classes.clickableChip,
                  deleteIconColorPrimary: classes.deleteIconColorPrimary,
                }}
                disabled={countHOMap.cancelled === 0}
              /> */}
              {/* : ''} */}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    mlsAreas: state.pma.mlsAreas,
    hoSearch: state.pma.hoSearch,
    hoFilter: state.pma.hoFilter,
    hoQuickFilter: state.pma.hoQuickFilter,
    hoAdvanceFilter: state.pma.hoAdvanceFilter,
    currentArea: state.pma.currentArea,
    currentPeriod: state.pma.currentPeriod,
    version: state.pma.version,
    mailingVersion: state.pma.mailingVersion,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mailProHeaderSort: (sorted, mailProHeader) =>
      dispatch(pmaActions.mailProHeaderSort(sorted, mailProHeader)),
    updateHOSearch: (searchTerm) => {
      dispatch(pmaActions.updateHOSearch(searchTerm));
    },
    updateHOFilter: (filterStatus) => {
      dispatch(pmaActions.updateHOFilter(filterStatus));
    },
    updateHOQuickFilter: (quickFilter) => {
      dispatch(pmaActions.updateHOQuickFilter(quickFilter));
    },
    updateHOAdvanceFilter: (advanceFilter) => {
      dispatch(pmaActions.updateHOAdvanceFilter(advanceFilter));
    },
    updateHOAdvanceFilterOptionData: (dataJson) => {
      dispatch(pmaActions.updateHOAdvanceFilterOptionData(dataJson));
    },
    resetProMailHeaderandAllFilters: () => {
      dispatch(pmaActions.resetProMailHeaderandAllFilters());
    },
    approveMailingList: (approveObj) => {
      dispatch(pmaActions.approveMailingList(approveObj));
    },
    changeLoading: (status) => {
      dispatch(pmaActions.changeLoading(status));
    },
    updateHomeownerProp: (hoData) =>
      dispatch(pmaActions.updateHomeownerProp(hoData)),
    updateNavbar: (mlsAreas) => dispatch(pmaActions.updateNavbar(mlsAreas)),
    changeMailProStatus: (listgenUserID, mailProStatus) =>
      dispatch(pmaActions.changeMailProStatus(listgenUserID, mailProStatus)),
    switchAreaWithStep: (area, step) =>
      dispatch(pmaActions.switchAreaWithStep(area, step)),
    switchStep: (step) => dispatch(pmaActions.switchStep(step)),
    explicitEnableNewMailProInitFn: (data) =>
      dispatch(pmaActions.explicitEnableNewMailProInitFn(data)),
    disableNewMailingForCurrentSessionFn: (data) =>
      dispatch(pmaActions.disableNewMailingForCurrentSessionFn(data)),
    setNewMailProInitDialogModeFn: (data) =>
      dispatch(pmaActions.setNewMailProInitDialogModeFn(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(withRouter, withStyles(styles), withSnackbar)(NavbarFilterBox));
