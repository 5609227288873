import React, { Component, Fragment } from "react";
import { Link, NavLink } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles, IconButton } from "@material-ui/core";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@material-ui/core";
import {
  DashboardOutlined as DashboardIcon,
  PeopleOutlined as PeopleIcon,
  ShoppingBasketOutlined as ShoppingBasketIcon,
  LockOpenOutlined as LockOpenIcon,
  TextFields as TextFieldsIcon,
  ImageOutlined as ImageIcon,
  InfoOutlined as InfoIcon,
  AccountBoxOutlined as AccountBoxIcon,
  SettingsOutlined as SettingsIcon,
  Search as SearchIcon,
  Money as MoneyIcon,
  ContactSupport as ContactSupportIcon,
  Flag as FlagIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Home as HomeIcon,
  Input as InputIcon,
  Person as PersonIcon,
  CalendarToday,
  CreditCard as CreditCardIcon,
  CenterFocusStrong as CenterFocusStrongIcon,
  AddComment as FeedbackIcon,
  Adjust as AdjustIcon,
  Highlight as HighlightIcon,
} from "@material-ui/icons";
import styles from "./styles";
import { IsValidLoginAuth, IsValidSemiAuth } from "common/storage";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import Base from "common/constants";
import { connect } from "react-redux";
import { GetStateStorage, IsValidNavbarData } from "common/storage";
import HelpV1Wrapper from "views/Help/HelpV1Wrapper";
import { isUserAdmin, isUserAgent } from "common/roles/role";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    /** check for auth */
    this.isValidLogin = IsValidLoginAuth();
    this.isValidSemiLogin = IsValidSemiAuth();
  }

  handleSignOut = () => {
    const { history } = this.props;

    //localStorage.setItem('isAuthenticated', false);
    //ResetAllLocalStorages();
    history.push("/logout?mode=1");
  };

  render() {
    const {
      classes,
      className,
      handleDrawerClose,
      localStateData,
    } = this.props;
    const stateData = GetStateStorage();
    const agentData = (stateData || {}).userData;
    let navbarData =
      agentData && Array.isArray(agentData.navbarInfo)
        ? agentData.navbarInfo[0]
        : {};

    const listgenLogo = "/images/listgen-logo.png";
    const listgenAltLogo = "/images/listgen-text-advanced.PNG";
    const listgenLogoAlt = "ListGen Logo";
    const rootClassName = classNames(classes.root, className);
    // console.log("******userData2", navbarInfo);
    return (
      <nav className={rootClassName}>
        <div className={classes.drawerHeader}>
          <div className={classes.logoWrapper}>
            <Link className={classes.logoLink} to="/home">
              <img
                alt="ListGen logo"
                className={classes.logoImage}
                src={listgenAltLogo}
              />
            </Link>
          </div>
          <IconButton
            onClick={(event) => handleDrawerClose()}
            color="primary"
            size="small"
          >
            {/* <ChevronLeftIcon />  */}
            Close
          </IconButton>
        </div>
        <Divider className={classes.logoDivider} />
        {/* <div className={classes.profile}>
        </div>
        <Divider className={classes.profileDivider} /> */}
        <List component="div" disablePadding>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/home"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Home"
            />
          </ListItem>
          {(!this.isValidLogin && this.isValidSemiLogin) ||
          (this.isValidLogin &&
            !this.isValidSemiLogin &&
            localStateData &&
            localStateData.userRole &&
            isUserAdmin(localStateData.userRole)) ? (
            <Fragment>
              {/* <ListItem
                activeClassName={classes.activeListItem}
                className={classes.listItem}
                component={NavLink}
                to="/pricing"
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <MoneyIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="Pricing"
                />
              </ListItem> */}
              {/* <ListItem
                activeClassName={classes.activeListItem}
                className={classes.listItem}
                component={NavLink}
                to="/about"
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="About Us"
                />
              </ListItem> */}
              <ListItem
                activeClassName={classes.activeListItem}
                className={classes.listItem}
                component={NavLink}
                to="/schedule"
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <CalendarToday />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="Mailing Schedule"
                />
              </ListItem>
              <HelpV1Wrapper>
              <ListItem
                activeClassName={classes.activeListItem}
                className={classes.listItem}
                //component={NavLink}
                //to="/help"
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <ContactSupportIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="Support"
                />
              </ListItem>
              </HelpV1Wrapper>
            </Fragment>
          ) : (
            ""
          )}

          {!this.isValidLogin && this.isValidSemiLogin ? (
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/agent/register"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Register"
              />
            </ListItem>
          ) : (
            ""
          )}

          {this.isValidLogin && !this.isValidSemiLogin ? (
            <Fragment>
              {localStateData &&
              localStateData.userRole &&
              isUserAgent(localStateData.userRole) ? (
                <Fragment>
                   <HelpV1Wrapper>
                  <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    //component={NavLink}
                    //to="/help"
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <ContactSupportIcon />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="Support"
                    />
                  </ListItem>
                  </HelpV1Wrapper>
                  <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to={`/pma/${navbarData.mlsAreaID}`}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="View PMA"
                    />
                  </ListItem>
                  <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to={`/mailpro/${navbarData.mlsAreaID}`}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="View MailPro"
                    />
                  </ListItem>
                  <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to={"/agent/missionstatements"}
                    classes={{ gutters: classes.listItemGutter }}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <AdjustIcon />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="Mission Statements"
                    />
                  </ListItem>
                  {/*<ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to={`/agent/property-highlights`}
                    classes={{ gutters: classes.listItemGutter }}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <HighlightIcon />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="Property Highlights"
                    />
                  </ListItem>*/}
                  {/* <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to={"/agent/mystrengths"}
                    classes={{ gutters: classes.listItemGutter }}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <CenterFocusStrongIcon />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="Edit Designations"
                    />
                  </ListItem> */}
                  <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to={"/agent/clienttestimonials"}
                    classes={{ gutters: classes.listItemGutter }}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <FeedbackIcon />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="Add Testimonials"
                    />
                  </ListItem>
                </Fragment>
              ) : (
                ""
              )}
            </Fragment>
          ) : (
            ""
          )}
        </List>
        {this.isValidLogin || this.isValidSemiLogin ? (
          <Fragment>
            <Divider className={classes.listDivider} />
            <List
              component="div"
              disablePadding
              // subheader={
              //   <ListSubheader className={classes.listSubheader}>
              //     Others
              //   </ListSubheader>
              // }
            >
              {!this.isValidLogin && this.isValidSemiLogin ? (
                <ListItem
                  className={classes.listItem}
                  component={NavLink}
                  to="/legal"
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    primary="Legal Terms"
                  />
                </ListItem>
              ) : (
                ""
              )}
              {this.isValidLogin && !this.isValidSemiLogin ? (
                <Fragment>
                  <ListItem
                    className={classes.listItem}
                    component={NavLink}
                    to="/account"
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="My Account"
                    />
                  </ListItem>
                  {localStateData &&
                  localStateData.userRole &&
                  isUserAgent(localStateData.userRole) ? (
                    <Fragment>
                      <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to={"/agent/details/ag/" + btoa(localStateData.userID)}
                        classes={{ gutters: classes.listItemGutter }}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <AccountBoxIcon />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.listItemText }}
                          primary="Update Profile"
                        />
                      </ListItem>
                      <ListItem
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLink}
                        to={`/cards/credit/ag/${btoa(
                          localStateData.userID
                        )}/${btoa(null)}/${btoa(null)}/${btoa(null)}`}
                        classes={{ gutters: classes.listItemGutter }}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <CreditCardIcon />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.listItemText }}
                          primary="Manage Credit Cards"
                        />
                      </ListItem>
                    </Fragment>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  <ListItem
                    className={classes.listItem}
                    onClick={(event) => this.handleSignOut()}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <InputIcon />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary="Logout"
                    />
                  </ListItem>
                </Fragment>
              ) : (
                ""
              )}
            </List>
          </Fragment>
        ) : (
          ""
        )}
      </nav>
    );
  }
}

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

// export default compose(withRouter, withStyles(styles))(Sidebar);

const enhance = compose(withRouter, withStyles(styles));
export default enhance(Sidebar);
