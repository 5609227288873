import React, { Component } from "react";

class BackPageImageContainer extends Component {
  render() {
    const { listgenUserID } = this.props.agentData;
    console.log("*listgenUserID", listgenUserID);
    if (listgenUserID === "106550") {
      return (
        <div className="kateryna-and-trish-september-2024-container">
          <img
            src="https://pma-coverphotos.s3.amazonaws.com/non-covers/Oct2024-ListGenBackCover_Christina+Ng_edit.jpg"
            className="kateryna-and-trish-september-img"
            alt="kateryna-september-img"
          />
        </div>
      );
    } else if (listgenUserID === "102286" || listgenUserID === "105047") {
      return (
        <div className="kateryna-september-2024-container">
          <img
            src="https://pma-coverphotos.s3.amazonaws.com/Oct2024-BackCover_KaterynaDavydova.jpg"
            className="kateryna-september-img"
            alt="kateryna-september-img"
          />
        </div>
      );
    } else if (listgenUserID === "106421") {
      return (
        <div className="kateryna-september-2024-container">
          <img
            src="https://pma-coverphotos.s3.amazonaws.com/Oct2024-BackCover_JulieFlouty.jpg"
            className="kateryna-september-img"
            alt="kateryna-september-img"
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default BackPageImageContainer;
