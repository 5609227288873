import React, { Component, useEffect, useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter,
} from 'components';
import {
  CreditCard as CreditCardIcon,
  History as HistoryIcon,
} from '@material-ui/icons';

import styles from './styles';
import { withSnackbar } from 'notistack';
import { compose } from 'recompose';
import { Store } from 'Store';
import Base from 'common/constants';
import { isUserAdmin } from 'common/roles/role';

const QuickLinks = props => {
  const { state, dispatch } = useContext(Store);
  const { userData } = state;
  const { classes, className } = props;
  const rootClassName = classNames(classes.root, className);

  return (
    isUserAdmin(userData.listgenRoleID) ? (
      <div></div>
    ) : (
      <Portlet className={rootClassName}>
        <PortletHeader>
          <PortletLabel title="Quick Links" />
        </PortletHeader>
        <PortletContent noPadding>
          <div className={classes.linkSection}>
            <Link
              to={`/cards/credit/ag/${btoa(userData.listgenUserID)}/${btoa(
                null
              )}/${btoa(null)}/${btoa(null)}`}
              className={classes.linkColor}
            >
              <div className={classes.linkDiv}>
                <CreditCardIcon color="primary" />
                &nbsp; Manage Credit Cards
              </div>
            </Link>
          </div>
          {/* <div className={classes.linkSection}>
            <Link
              to={`/agent/invoice/history/${btoa(
                userData.listgenUserID
              )}/${btoa(null)}/${btoa(null)}/${btoa(null)}`}
              className={classes.linkColor}
            >
              <div className={classes.linkDiv}>
                <HistoryIcon color="primary" />
                &nbsp; Invoice History
              </div>
            </Link>
          </div> */}
        </PortletContent>
      </Portlet>
    )
  );
};

QuickLinks.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles)
)(QuickLinks);
