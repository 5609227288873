import React, { Component } from "react";
import FirstLeftHeader from "views/Pma/PmaEditor/CommonEditor/FirstLeftHeader";
import HOAddress from "views/Pma/PmaEditor/CommonEditor/HOAddress";
import QVFourLogoAndAvatar from "./QVFourLogoAndAvatar";
import QVFourTestimonial from "./QVFourTestimonial";
import QVThreeEconomists from "views/Pma/PmaEditor/Quarterly/QVThree/QVThreeFirstPage/QVThreeEconomists";
import QuadrantContainer from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/QVFourQuadrantContainer";
import Volunteer from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourFirstPage/Volunteer";
import BackpageBoxes from "views/Pma/PmaEditor/Quarterly/QVThree/QVThreeFirstPage/BackpageBoxes";
import GummowListingPackages from "views/Pma/PmaEditor/CustomAgentPages/GummowListingPackages";
import AnnetteQuadrants from "views/Pma/PmaEditor/CustomAgentPages/AnnetteQuadrants";
import BackPageImageContainer from "views/Pma/PmaEditor/CustomAgentPages/BackPageImageContainer";
import BrettNote from "views/Pma/PmaEditor/CustomAgentPages/BrettNote";

class QVFourFirstLeft extends Component {
  render() {
    const {
      printVersion,
      version,
      agentData,
      altAgentData,
      hoData,
      currentArea,
      mlsAreas,
      coverPageDetails,

      testimonials,
      quadrantModalOpen,
      quadrantDetails,
      volunteerDetails,
      bubbleBoxes,
      isDemo,
    } = this.props;
    const { listgenUserID } = agentData;
    const { mlsAreaID } = currentArea;

    let isGummow = listgenUserID === "x101566";
    let isAnnetteVait = listgenUserID === "101001";
    let isBrett = listgenUserID === "100227";
    return (
      <div className="qv4-first-left">
        <div className={`${quadrantModalOpen && "first-left-dim"}`}>
          <FirstLeftHeader
            agentData={agentData}
            printVersion={printVersion}
            currentArea={currentArea}
          />
          <QVFourLogoAndAvatar
            agentData={agentData}
            mlsAreaID={mlsAreaID}
            currentArea={currentArea}
            isDemo={isDemo}
            printVersion={printVersion}
          />
          <HOAddress
            hoData={hoData}
            version={version}
            agentData={agentData}
            currentArea={currentArea}
            printVersion={printVersion}
            mailInfoClass="qv4-mail-info-container-print"
            versionType="qv4"
            typeOfVersion="qv4"
            coverPageDetails={coverPageDetails}
          />
        </div>
        {isGummow ? (
          <>
            <GummowListingPackages />
          </>
        ) : isBrett ? (
          <BrettNote />
        ) : listgenUserID === "x106550" ||
          listgenUserID === "x102286" ||
          listgenUserID === "x105047" ||
          listgenUserID === "x106421" ? (
          <BackPageImageContainer agentData={agentData} />
        ) : isAnnetteVait ? (
          <AnnetteQuadrants />
        ) : (
          <Volunteer
            currentArea={currentArea}
            quadrantDetails={quadrantDetails}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            testimonials={testimonials}
            hoData={hoData}
            agentData={agentData}
            altAgentData={altAgentData}
            volunteerDetails={volunteerDetails}
          />
        )}

        {/*<QuadrantContainer
          currentArea={currentArea}
          quadrantDetails={quadrantDetails}
          mlsAreas={mlsAreas}
          printVersion={printVersion}
          testimonials={testimonials}
          hoData={hoData}
        />
        <BackpageBoxes
              currentArea={currentArea}
              agentData={agentData}
              bubbleBoxes={bubbleBoxes}
              mlsAreas={currentArea.mlsAreas}
              hoData={hoData}
              printVersion={printVersion}
            />
        <QVFourTestimonial
          testimonials={testimonials}
          agentData={agentData}
          printVersion={printVersion}
          currentArea={currentArea}
        />*/}
        {/*<QVFourMissionStatement
          missionStatement={missionStatement}
          currentArea={currentArea}
          agentData={agentData}
          mlsAreas={currentArea.mlsAreas}
          printVersion={printVersion}
        />*/}
        {/*{ mlsAreaID === "SBA-20FInv" &&
          quadrantDetails &&
          quadrantDetails.length ? (
          <>
            <QuadrantContainer
              currentArea={currentArea}
              quadrantDetails={quadrantDetails}
              mlsAreas={mlsAreas}
              printVersion={printVersion}
              testimonials={testimonials}
            />{" "}
            <QVFourTestimonial testimonials={testimonials} />
          </>
        ) : (
          <TaxRate agentData={agentData} printVersion={printVersion} />

        {/*<TaxRate agentData={agentData} printVersion={printVersion} />*/}
        {/*<ExpertsTrends agentData={agentData} printVersion={printVersion} />*/}
      </div>
    );
  }
}

export default QVFourFirstLeft;
