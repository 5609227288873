import React, { Component } from "react";
import "./customStyles.scss";
import "./customPrintStyles.scss";

class AnnetteQuadrants extends Component {
  render() {
    let showImage = false;
    if (showImage) {
      return (
        <div className="annette-quadrants-image-container">
          <img
            src="https://pma-coverphotos.s3.amazonaws.com/non-covers/annette-vait-june-2024-backpage.jpg"
            alt="Annette Quadrants"
            className="annette-quadrants-image"
          />
        </div>
      );
    } else {
      return (
        <>
          <div className="annette-quadrants">
            <div className="annette-quadrants-body">
              <div className="annette-body-title-section">
                <div className="annette-quadrants-body-title">
                  <p>Earning your real estate business...</p>
                </div>
              </div>
              <div className="annette-section-container">
                <div className="annette-section-row">
                  <div className="annette-quadrants-section">
                    <div className="annette-quadrants-section-subtitle">
                      Qualified Advocate
                    </div>

                    <div className="annette-quadrants-section-text">
                      <p>Certified Property Management Specialist,</p>
                      <p>Certified Probate & Trust Specialist,</p>
                      <p>Certified Public Accountant,</p>
                      <p>Realtor, Real Estate Broker,</p>
                      <p>House Renovator.</p>
                    </div>
                  </div>
                  <div className="annette-quadrants-section">
                    <div className="annette-quadrants-section-subtitle">
                      Design Consultation
                    </div>

                    <div className="annette-quadrants-section-text">
                      <p>Allow me to visit with you at your property</p>
                      <p>to provide a complimentary evaluation</p>
                      <p>of possible interior & exterior</p>
                      <p>cost-effective improvements</p>
                      <p>for your valuable asset.</p>
                    </div>
                  </div>
                </div>
                <div className="annette-section-row">
                  <div className="annette-quadrants-divider-section">
                    <div className="annette-quadrant-divider"></div>
                  </div>
                  <div className="annette-quadrants-divider-section">
                    {" "}
                    <div className="annette-quadrant-divider"></div>
                  </div>
                </div>
                <div className="annette-section-row">
                  <div className="annette-quadrants-section">
                    <div className="annette-quadrants-section-subtitle">
                      Broker Price Opinion
                    </div>

                    <div className="annette-quadrants-section-text">
                      <p>Allow me to prepare a</p>
                      <p>personalized homeowner insight</p>
                      <p>report that synthesizes sales, market</p>
                      <p>conditions, and your property's strengths.</p>
                    </div>
                  </div>
                  <div className="annette-quadrants-section">
                    <div className="annette-quadrants-section-subtitle">
                      Real Estate Planning
                    </div>

                    <div className="annette-quadrants-section-text">
                      <p>Allow me to provide you a Real</p>
                      <p>Estate Planning Guide as well as a</p>
                      <p>complimentary visit to discuss concrete</p>
                      <p>paths to achieve your real estate goals.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="annette-quadrants-logo-container">
              <img
                src="https://pma-coverphotos.s3.amazonaws.com/101624_AnnetteVait_LOGOblack.png"
                alt="annette Icons"
              />
            </div>
          </div>

          <div className="annette-testimonial">
            <img
              className="annette-testimonial-img"
              src="https://pma-coverphotos.s3.us-east-1.amazonaws.com/annette-Holiday+PromotionCover.png"
              alt="Annette Holiday Promotion"
            />
            {/*<div className="annette-testimonial-inner">
              <p className="annette-testimonial-description">
                ANNETTE’S SPECIAL OFFERING!
              </p>
              <p className="annette-testimonial-description-0">
                10% discount at <span>Santa Playa Mariscos</span>
              </p>
              <p className="annette-testimonial-description-1">
                Fresh Seafood with Mexican flair - just opened give it a try!
              </p>
              <p className="annette-testimonial-description-2">
                1230 State Street, 805-679-5657
              </p>
              <p className="annette-testimonial-description-3">
                Snap a picture of this offer and tell them Annette sent you!
              </p>
              <p className="annette-testimonial-description-4">
                Good for Nov 1 2024 - Jan 1 2025
              </p>
            </div>*/}
          </div>
        </>
      );
    }
    //console.log("inside quadrant");
  }
}

export default AnnetteQuadrants;
