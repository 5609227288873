import React, { Component, Fragment } from "react";
import VolunteerEditorModal from "./VolunteerEditorModal";
import { pmaActions } from "_store/_actions";
import { connect } from "react-redux";
import AddAreasModal from "views/Pma/PmaEditor/TableQuadrants/TableQuadrantsPageOne/AddAreasModal";
import VolunteerBGImg from "./images.jpg";

class Volunteer extends Component {
  state = {
    editQuadrantFormData: null,
    originalEditQuadrantFormData: null,
    oldQuadrantData: [],
    oldEditForm: [],
    selectedQuadrantNumber: null,
    titleEdit: false,
    maxMainTitleLength: 30,
    addAreasModalOpen: false,
    filteredAreas: {},
    maxMainSubtitleLength: 70,
    savingMessageShowing: false,
  };

  handleFormClose = (quadNum) => {
    const { volunteerDetails, currentArea } = this.props;
    const { editQuadrantFormData, oldQuadrantData, oldEditForm } = this.state;

    let foundIndex = oldQuadrantData.findIndex(
      (x) => x.bpciAreaID == editQuadrantFormData.bpciAreaID
    );

    this.setState(
      {
        titleEdit: false,
        addAreasModalOpen: false,
        savingMessageShowing: false,
        editQuadrantFormData: oldEditForm,
      },
      () => {
        this.props.setVolunteerDetails(oldQuadrantData);
      }
    );

    let newObj = [...volunteerDetails]
      .filter((quadrantObj) => quadrantObj.bpciAreaID === currentArea.mlsAreaID)
      .map((i) => ({ ...i }))[0];

    let thisObj = [...volunteerDetails]
      .filter((quadrantObj) => quadrantObj.bpciAreaID === currentArea.mlsAreaID)
      .map((i) => ({ ...i }))[0];

    this.setState({
      oldQuadrantData: [...volunteerDetails].map((i) => ({ ...i })),
      editQuadrantFormData: [...volunteerDetails]
        .filter(
          (quadrantObj) => quadrantObj.bpciAreaID === currentArea.mlsAreaID
        )
        .map((i) => ({ ...i }))[0],
    });

    this.props.updateQuadrantModalOpen(false);
  };
  onEditFormSubmit = () => {
    const { editQuadrantFormData, oldQuadrantData } = this.state;
    let foundIndex = oldQuadrantData.findIndex(
      (x) => x.bpciAreaID == editQuadrantFormData.bpciAreaID
    );
    oldQuadrantData[foundIndex] = editQuadrantFormData;
    this.props.setVolunteerDetails(oldQuadrantData);
    this.props.updateQuadrantModalOpen(false);

    // this.setState({
    //   savingMessageShowing: true,
    // });
    //
    // setInterval(() => {
    //   this.setState({ savingMessageShowing: false });
    //   this.props.updateQuadrantModalOpen(false);
    // }, 5000);

    // console.log("oldQuadrantData", oldQuadrantData);
  };
  openSelectedQuadrant = (quadrantNumber, titleEdit) => {
    const { editQuadrantFormData, oldQuadrantData, oldEditForm } = this.state;

    let foundIndex = oldQuadrantData
      .slice()
      .findIndex((x) => x.bpciAreaID == editQuadrantFormData.bpciAreaID);
    let currentOldData = oldQuadrantData.slice()[foundIndex];

    this.setState(
      {
        selectedQuadrantNumber: quadrantNumber,
        titleEdit: titleEdit,

        savingMessageShowing: false,
        oldEditForm: currentOldData,
      },
      () => {
        this.props.updateQuadrantModalOpen(true);
      }
    );
  };
  componentDidMount = () => {
    if (this.props.printVersion) {
      require("./print.scss");
    } else {
      require("./editor.scss");
    }

    const { volunteerDetails, currentArea } = this.props;

    this.props.updateQuadrantModalOpen(false);

    let newObj = [...volunteerDetails]
      .filter((quadrantObj) => quadrantObj.bpciAreaID === currentArea.mlsAreaID)
      .map((i) => ({ ...i }))[0];

    if (!newObj && currentArea.mlsAreaID.includes("DEMO")) {
      newObj = volunteerDetails[0];
    }
    let includesDemo = currentArea.mlsAreaID.includes("DEMO");
    // console.log("includesDemo", includesDemo);
    // console.log("newObj", newObj);
    let thisObj = [...volunteerDetails]
      .filter((quadrantObj) => quadrantObj.bpciAreaID === currentArea.mlsAreaID)
      .map((i) => ({ ...i }))[0];

    this.setState({
      oldQuadrantData: [...volunteerDetails].map((i) => ({ ...i })),
      editQuadrantFormData: [...volunteerDetails]
        .filter(
          (quadrantObj) => quadrantObj.bpciAreaID === currentArea.mlsAreaID
        )
        .map((i) => ({ ...i }))[0],
    });
    // if (!this.state.editQuadrantFormData) {
    //   console.log("no edit");
    //   this.setState({
    //     editQuadrantFormData: volunteerDetails[0],
    //   });
    // }
    //  console.log("after mounting", this.state);
    // this.setState({
    //   oldQuadrantData: volunteerDetails,
    //   editQuadrantFormData: volunteerDetails,
    // });
  };
  handleRestoreDefault = () => {
    const {
      selectedQuadrantNumber,
      editQuadrantFormData,
      oldQuadrantData,
      titleEdit,
    } = this.state;
    let past = oldQuadrantData[selectedQuadrantNumber];
    if (titleEdit) {
      this.setState({
        // mainTitle: oldTitle,
        // subtitle: oldSubtitle,
      });
    } else if (selectedQuadrantNumber >= 0) {
      let newObj = [...editQuadrantFormData].map((i) => ({ ...i }));
      // console.log("regular obj", newObj);
      let oldSelection = [...oldQuadrantData].map((i) => ({
        ...i,
      }))[selectedQuadrantNumber];
      // console.log("oldSelection", oldSelection);
      newObj[selectedQuadrantNumber].text = oldSelection.text;
      // console.log("after obj", newObj);
      this.setState({
        editQuadrantFormData: newObj,
      });
    }
  };
  handleInputChange = (e) => {
    const {
      editQuadrantFormData,
      oldQuadrantData,
      titleEdit,
      oldEditForm,
    } = this.state;
    const target = e.target;
    const value = target.value;
    const name = target.name;
    // console.log("target", target);
    // console.log("value", value);
    // console.log("name", name);

    let clonedEditQuadrantFormData = Object.assign(editQuadrantFormData, {
      [name]: value,
    });

    this.setState({
      editQuadrantFormData: clonedEditQuadrantFormData,
    });
  };
  selectOrganizationText = (organization, selectedQuadrantNumber) => {
    const { editQuadrantFormData } = this.state;
    const { bpciText, bpciHeader, bpciFooter } = organization;
    let titleToChange = `bpciHeading${selectedQuadrantNumber}`;
    let textToChange = `bpciText${selectedQuadrantNumber}`;
    let websiteURLToChange = `bpciFooter${selectedQuadrantNumber}`;

    let clonedEditQuadrantFormData = Object.assign(editQuadrantFormData, {
      [titleToChange]: bpciHeader,
      [textToChange]: bpciText,
      [websiteURLToChange]: bpciFooter,
    });

    this.setState({
      editQuadrantFormData: clonedEditQuadrantFormData,
    });
  };
  applyToAllAreas = () => {
    console.log("applyToAllAreas");
  };

  render() {
    // console.log(this.props.currentArea);
    const { mlsAreaID } = this.props.currentArea;
    const {
      mlsAreas,
      quadrantModalOpen,
      currentArea,
      printVersion,
      hoData,
      agentData,
      altAgentData,
    } = this.props;
    //console.log("quadrantModalOpen", quadrantModalOpen);

    const {
      openSelectedQuadrant,
      handleInputChange,
      handleRestoreDefault,
      applyToAllAreas,
      selectOrganizationText,
    } = this;
    const {
      editQuadrantFormData,
      oldQuadrantData,
      selectedQuadrantNumber,

      titleEdit,

      addAreasModalOpen,
      maxMainTitleLength,
      maxMainSubtitleLength,
      savingMessageShowing,
    } = this.state;
    let subtitleStyles;
    if (
      mlsAreaID === "SCL-207A" ||
      mlsAreaID === "SCL-207B" ||
      mlsAreaID === "SCL-209A" ||
      mlsAreaID == "SCL-209B"
    ) {
      subtitleStyles = "no-capitalized-subtitle";
    }
    let quadrantStyles;
    if (mlsAreaID === "SMA-466A" || mlsAreaID === "SMA-492") {
      quadrantStyles = "smaller-lower-volunteers";
    } else if (mlsAreaID === "SMA-472") {
      quadrantStyles = "larger-font-volunteers";
    } else if (mlsAreaID === "SMA-437") {
      quadrantStyles = "less-title-more-text";
    }
    // else if (
    //   editQuadrantFormData &&
    //   editQuadrantFormData.bpciText4 &&
    //   editQuadrantFormData.bpciText4.length > 250
    // ) {
    //   console.log("bpciText4");
    // }
    // console.log("quadrant state", this.state);

    let showButtons = false;
    // let showButtons = currentArea.mailingStatus !== "Approved";

    if (editQuadrantFormData && Object.keys(editQuadrantFormData).length >= 1) {
      const {
        bpciAreaID,
        bpciAreaTitle,
        bpciAreaSubTitle,
        bpciHeading1,
        bpciText1,
        bpciHeading2,
        bpciText2,
        bpciHeading3,
        bpciText3,
        bpciHeading4,
        bpciText4,
      } = editQuadrantFormData;
      return (
        <Fragment>
          {addAreasModalOpen && (
            <AddAreasModal
              addAreasModalOpen={addAreasModalOpen}
              mlsAreas={mlsAreas}
              currentArea={currentArea}
              onCloseFn={this.handleFormClose}
              onEditFormSubmit={this.onEditFormSubmit}
              editQuadrantFormData={editQuadrantFormData}
            />
          )}
          {editQuadrantFormData &&
            Object.keys(editQuadrantFormData).length >= 1 &&
            quadrantModalOpen && (
              <VolunteerEditorModal
                oldQuadrantData={oldQuadrantData}
                onCloseFn={this.handleFormClose}
                handleInputChange={handleInputChange}
                editQuadrantFormData={editQuadrantFormData}
                onEditFormSubmit={this.onEditFormSubmit}
                selectedQuadrantNumber={selectedQuadrantNumber}
                handleRestoreDefault={handleRestoreDefault}
                titleEdit={titleEdit}
                mlsAreas={mlsAreas}
                maxMainTitleLength={maxMainTitleLength}
                maxMainSubtitleLength={maxMainSubtitleLength}
                savingMessageShowing={savingMessageShowing}
                selectOrganizationText={selectOrganizationText}
                currentArea={currentArea}
                agentData={agentData}
                altAgentData={altAgentData}
              />
            )}

          <div
            className={`${
              printVersion
                ? "print-volunteer-container"
                : "editor-volunteer-container"
            } ${quadrantStyles && quadrantStyles} `}
          >
            {!printVersion && showButtons && (
              <>
                <div
                  className="volunteer-btn volunteer-title-btn"
                  onClick={() => openSelectedQuadrant(null, true)}
                >
                  <div>Edit the Header and/or Subheader</div>
                </div>

                <div
                  className="volunteer-btn volunteer-btn-1"
                  onClick={() => openSelectedQuadrant(1)}
                >
                  <div>Edit Section</div>
                </div>

                <div
                  className="volunteer-btn volunteer-btn-2"
                  onClick={() => openSelectedQuadrant(2)}
                >
                  <div>Edit Section</div>
                </div>

                <div
                  className="volunteer-btn volunteer-btn-3"
                  onClick={() => openSelectedQuadrant(3)}
                >
                  <div>Edit Section</div>
                </div>

                <div
                  className="volunteer-btn volunteer-btn-4"
                  onClick={() => openSelectedQuadrant(4)}
                >
                  <div>Edit Section</div>
                </div>
              </>
            )}
            <img
              className="volunteer-bg-img"
              src="https://pma-coverphotos.s3.us-east-1.amazonaws.com/110524_BackCover_1.2MB.jpg"
            />
            <div className="volunteer">
              <div className="volunteer-title-container">
                <div
                  className="volunteer-title-text"
                  dangerouslySetInnerHTML={{ __html: bpciAreaTitle }}
                ></div>
                <div
                  className={`volunteer-subtitle-text ${subtitleStyles}`}
                  dangerouslySetInnerHTML={{ __html: bpciAreaSubTitle }}
                ></div>
              </div>

              <div className="volunteer-body">
                <div className="section-container">
                  <div className="section-row">
                    {[0, 1, 2, 3].map((quadrant, i) => {
                      let index = i + 1;

                      let quadNumTitle =
                        editQuadrantFormData[`bpciHeading${index}`];
                      let quadNumText =
                        editQuadrantFormData[`bpciText${index}`];
                      let quadWebsiteURL =
                        editQuadrantFormData[`bpciFooter${index}`];
                      return (
                        <div
                          className={`${
                            index == selectedQuadrantNumber &&
                            "selected-volunteer-section"
                          } volunteer-section section-${index}`}
                          key={index}
                        >
                          <div className="volunteer-section-title-and-text">
                            <p>
                              <span className="volunteer-section-text">
                                <strong>{quadNumTitle}</strong> {quadNumText}{" "}
                                <span className="volunteer-section-websiteURL">
                                  {quadWebsiteURL}
                                </span>
                              </span>
                            </p>

                            {/*<p>
                              <span
                                className="volunteer-section-title"
                                // dangerouslySetInnerHTML={{
                                //   __html: quadNumTitle,
                                // }}
                              >
                                {quadNumTitle}
                              </span>
                              <span
                                className="volunteer-section-text"
                                // dangerouslySetInnerHTML={{
                                //   __html: quadNumText,
                                // }}
                              >
                                {quadNumText}
                                <span
                                  className="volunteer-section-websiteURL"
                                  // dangerouslySetInnerHTML={{
                                  //   __html: quadWebsiteURL,
                                  // }}
                                >
                                  {quadWebsiteURL}
                                </span>
                              </span>
                            </p>*/}
                          </div>
                          <div className="volunteer-section-divider-container">
                            <img
                              className="volunteer-section-divider-img"
                              src="https://pma-coverphotos.s3.us-east-1.amazonaws.com/Divider-Line_brwn.png"
                              alt="Volunteer Divider"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="volunteer-footer">
              <p className="volunteer-footer-text">
                If you are interested in learning more about these
                organizations, let's connect!
              </p>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    quadrantModalOpen: state.pma.quadrantModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateQuadrantModalOpen: (isModalOpen) => {
      dispatch(pmaActions.updateQuadrantModalOpen(isModalOpen));
    },
    setVolunteerDetails: (volunteerDetails) => {
      dispatch(pmaActions.setVolunteerDetails(volunteerDetails));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Volunteer);
