import React, { Component, Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles, Slide } from "@material-ui/core";
import { compose } from "recompose";
import styles from "views/Pma/PmaEditor/TableQuadrants/table-quadrants-editor.scss";
import { withSnackbar } from "notistack";
import classNames from "classnames";
import { showSuccessNotification } from "common/helpers";
import { showErrorNotification } from "common/helpers";
import { propertyUtil } from "views/Pma/PropertyUtil";
import VolunteerOrganizations from "./VolunteerOrganizations";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VolunteerEditorModal = (props) => {
  const {
    oldQuadrantData,
    handleInputChange,
    editQuadrantFormData,
    onCloseFn,
    modalType,
    areaID,
    onEditFormSubmit,
    selectedQuadrantNumber,
    handleRestoreDefault,
    titleEdit,
    maxMainTitleLength,
    mlsAreas,
    maxMainSubtitleLength,
    savingMessageShowing,
    selectOrganizationText,
    currentArea,
    agentData,
    altAgentData,
  } = props;
  const transformInput = (str) => {
    let capital = str.charAt(0).toUpperCase() + str.slice(1);
    return capital;
  };
  // const { }

  if (titleEdit) {
    const { bpciAreaSubTitle, bpciAreaTitle } = editQuadrantFormData;

    let overMaxMainTitleLength =
      bpciAreaTitle &&
      bpciAreaTitle.length &&
      bpciAreaTitle.length >= maxMainTitleLength;

    let overMaxMainSubtitleLength =
      bpciAreaSubTitle &&
      bpciAreaSubTitle.length &&
      bpciAreaSubTitle.length >= maxMainSubtitleLength;
    return (
      <Fragment>
        <div className="edit-volunteer-container">
          <div className="edit-volunteer-form new-props">
            <div className="edit-volunteer-form-title-container">
              <p className="edit-volunteer-form-title-text">
                You Can Make Changes to the Header and Subheader Here
              </p>
            </div>

            <div className="edit-volunteer-form-body">
              <div className="edit-form-body-col">
                <div className="edit-form-thead">
                  <div>
                    <p>Header</p>
                  </div>
                </div>
                <div className="edit-form-input main-title-input">
                  <div className="address-input-col">
                    <input
                      type="text"
                      id={`main-title`}
                      name={`bpciAreaTitle`}
                      className="title-input"
                      value={bpciAreaTitle || ""}
                      onChange={handleInputChange}
                      placeholder={bpciAreaTitle}
                      maxLength={maxMainTitleLength}
                    ></input>
                    <label
                      htmlFor="quadrantsTitle"
                      className={
                        overMaxMainTitleLength ? "character-count-error" : ""
                      }
                    >
                      Character count:{" "}
                      {bpciAreaTitle && bpciAreaTitle.length
                        ? bpciAreaTitle.length
                        : "0"}
                      /{maxMainTitleLength}
                    </label>
                  </div>
                </div>
              </div>

              <div className="edit-form-body-col">
                <div className="edit-form-thead subtitle-thead">
                  <div>
                    <p>Subheader</p>
                  </div>
                </div>
                <div className="edit-form-input subtitle-input">
                  <div className="address-input-col">
                    <input
                      type="text"
                      id={`bpciAreaSubTitle`}
                      name={`bpciAreaSubTitle`}
                      className="title-input"
                      value={transformInput(bpciAreaSubTitle) || ""}
                      onChange={handleInputChange}
                      placeholder={bpciAreaSubTitle}
                      maxLength={maxMainSubtitleLength}
                    ></input>
                    <label
                      htmlFor="bpciAreaSubTitle"
                      className={
                        overMaxMainSubtitleLength ? "character-count-error" : ""
                      }
                    >
                      Character count:{" "}
                      {bpciAreaSubTitle && bpciAreaSubTitle.length
                        ? bpciAreaSubTitle.length
                        : "0"}
                      /{maxMainSubtitleLength}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="edit-form-button-container">
            <div className="button-col">
              <div
                className="edit-form-cancel-button"
                onClick={() => onCloseFn(selectedQuadrantNumber)}
              >
                <p>Cancel</p>
              </div>
            </div>
            <div className="button-col">
              <div
                className="edit-form-submit-button"
                onClick={onEditFormSubmit}
              >
                <p>Save</p>
              </div>
            </div>
          </div>

          {savingMessageShowing && (
            <div className="saving-message">Your work has been saved!</div>
          )}
        </div>
      </Fragment>
    );
  } else {
    let quadrantTitle =
      editQuadrantFormData[`bpciHeading${selectedQuadrantNumber}`];
    let quadrantText =
      editQuadrantFormData[`bpciText${selectedQuadrantNumber}`];

    let quadrantWebsiteURL =
      editQuadrantFormData[`bpciFooter${selectedQuadrantNumber}`];

    // let quadrantText = editQuadrantFormData[selectedQuadrantNumber].text;

    let maxQuadrantTextLength = 95;
    let overMaxQuadrantTextLength =
      quadrantText &&
      quadrantText.length &&
      quadrantText.length >= maxQuadrantTextLength;

    let maxQuadrantTitleLength = 45;
    let overMaxQuadrantTitleLength =
      quadrantTitle &&
      quadrantTitle.length &&
      quadrantTitle.length >= maxQuadrantTitleLength;

    let maxWebsiteURLTextLength = 40;
    let overMaxWebsiteURLLength =
      quadrantWebsiteURL &&
      quadrantWebsiteURL.length &&
      quadrantWebsiteURL.length >= maxWebsiteURLTextLength;

    return (
      <Fragment>
        <div className="edit-volunteer-container">
          <div className="edit-volunteer-form new-props">
            <div className="edit-volunteer-form-title-container">
              <p className="edit-volunteer-form-title-text">
                You can erase this organization and enter your own favorite
              </p>
            </div>
            <div className="edit-volunteer-form-body row-body">
              <div className="edit-form-body-col">
                <div className="edit-form-thead">
                  <div>
                    <p>Organization Name</p>
                  </div>
                </div>
                <div className="edit-form-input">
                  <div className="address-input-col">
                    <input
                      type="text"
                      id={`bpciHeading${selectedQuadrantNumber}`}
                      name={`bpciHeading${selectedQuadrantNumber}`}
                      className="title-input"
                      value={quadrantTitle || ""}
                      onChange={handleInputChange}
                      placeholder={quadrantTitle}
                      maxLength={maxQuadrantTitleLength}
                    ></input>
                    <label
                      htmlFor={`bpciHeading${selectedQuadrantNumber}`}
                      className={
                        overMaxQuadrantTitleLength
                          ? "character-count-error"
                          : ""
                      }
                    >
                      Character count:{" "}
                      {quadrantTitle && quadrantTitle.length
                        ? quadrantTitle.length
                        : "0"}
                      /{maxQuadrantTitleLength}
                    </label>
                  </div>
                </div>
              </div>
              <div className="textarea-column">
                <div className="edit-form-body-col">
                  <div className="edit-form-thead">
                    <div>
                      <p>Description of Organization</p>
                    </div>
                  </div>
                  <div className="edit-form-input">
                    <div className="address-input-col">
                      <textarea
                        placeholder={quadrantText}
                        onChange={handleInputChange}
                        name={`bpciText${selectedQuadrantNumber}`}
                        value={quadrantText || ""}
                        className="quadrant-textarea"
                        maxLength={maxQuadrantTextLength}
                      ></textarea>

                      <label
                        htmlFor={`bpciText${selectedQuadrantNumber}`}
                        className={
                          overMaxQuadrantTextLength
                            ? "character-count-error"
                            : ""
                        }
                      >
                        Character count:{" "}
                        {quadrantText && quadrantText.length
                          ? quadrantText.length
                          : "0"}
                        /{maxQuadrantTextLength}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="edit-form-body-col">
                  <div className="edit-form-thead">
                    <div>
                      <p>Website URL</p>
                    </div>
                  </div>
                  <div className="edit-form-input">
                    <div className="address-input-col">
                      <textarea
                        placeholder={quadrantWebsiteURL}
                        onChange={handleInputChange}
                        name={`bpciFooter${selectedQuadrantNumber}`}
                        value={quadrantWebsiteURL || ""}
                        className="websiteURL-textarea"
                        maxLength={maxWebsiteURLTextLength}
                      ></textarea>

                      <label
                        htmlFor={`bpciFooter${selectedQuadrantNumber}`}
                        className={
                          overMaxWebsiteURLLength ? "character-count-error" : ""
                        }
                      >
                        Character count:{" "}
                        {quadrantWebsiteURL && quadrantWebsiteURL.length
                          ? quadrantWebsiteURL.length
                          : "0"}
                        /{maxWebsiteURLTextLength}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="edit-form-button-container">
            <div className="button-col">
              <div
                className="edit-form-cancel-button"
                onClick={() => onCloseFn(selectedQuadrantNumber)}
              >
                <p>Cancel</p>
              </div>
              {/*<div className="restore-default" onClick={handleRestoreDefault}>
                <p>Restore Default</p>
              </div>*/}
            </div>
            <div className="button-col">
              <div
                className="edit-form-submit-button"
                onClick={onEditFormSubmit}
              >
                <p>Save</p>
              </div>
            </div>
          </div>
          {savingMessageShowing && (
            <div className="saving-message">Your work has been saved!</div>
          )}
          <VolunteerOrganizations
            selectOrganizationText={selectOrganizationText}
            selectedQuadrantNumber={selectedQuadrantNumber}
            currentArea={currentArea}
            agentData={agentData}
            altAgentData={altAgentData}
            editQuadrantFormData={editQuadrantFormData}
          />
        </div>
        {/*</Dialog>*/}
      </Fragment>
    );
  }
};

export default compose(withSnackbar, withStyles(styles))(VolunteerEditorModal);
