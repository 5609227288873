import { apiConstants as config } from "../_constants";
import { GetOAuthTokenByKey } from "common/storage";
// import { GetAuthorization } from '../common/helpers';
import axios from "common/interceptors";

export const pmaUtil = {
  getAgentQR,
  getRecentActivityPhotos,
  getPropertyPhotoDetails,
  getBubbleBoxDetails,
  getBubblePriceRange,
  setBubbleDetails,
  setMissionStatementDetails,
  setQuadrantDetails,
  getQuadrantDetails,
  getVolunteerDetails,
  setVolunteerDetails,
  getMarketTrends,
  getTableBubbleData,
  getDashBoardWithPriceRangeAndFeaturePhotos,
  getMissionStatementApi,
  getDonutAndTable,
  getDashboardDonutApi,
  getPmaDetailsApi,
  getTablePmaApi,
  getDataVerification,
  getSpeedometerData,
  getMayApi,
  getAprilApi,
  qAreaAndCityDetails,
  getYearEndDetails,
  getSAT,
  getSalePriceForCities,
  getAgentAdvantages,
  getAgentDesignations,
  getTestimonials,
  getPMACoverPhotoDetails,
  getQuarterAreaTable,
  getQuarterCityTable,
  getLocalSellingAgents,
  getMailerProDetails,
  updateHomeownerProp,
  updateQuickPersonalNotePS,
  updateMailProEntity,
  getCityAreaMap,
  getMailProIDDetails,
  getMarketActivity,
  getNavbarInfo,
  updateNavbar,
  getMQ2CityDetails,
  getMQCityDetails,
  approveMailingList,
  changeMailProStatus,
};

async function getAgentQR(agentDRENo, areaID) {
  try {
    //  console.log("*****inside util getAGentQR", agentDRENo, areaID);
    // call this API /admin/getMailerProIDForAgentDRENo(String agentDRENo, String areaID)
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        agentDRENo,
        areaID,
      },
      data: {},
      url: `${config.getAgentQR}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}

async function getRecentActivityPhotos(listgenUserAreaID) {
  try {
    // console.log("getRecentActivityPhotos", listgenUserAreaID);
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        listgenUserAreaID,
      },
      data: {},
      url: `${config.getRecentActivityPhotos}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}

async function getPropertyPhotoDetails(listgenUserAreaID) {
  try {
    // console.log("inside getBubblePriceRange util", getBubblePriceRange);
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        listgenUserAreaID,
      },
      data: {},
      url: `${config.getPropertyPhotoDetails}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}

async function getBubblePriceRange(
  areaAndCity,
  subDivision,
  listgenUserID,
  mailerProID
) {
  try {
    // console.log("inside getBubblePriceRange util", getBubblePriceRange);
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        areaCityPeriod: `${areaAndCity}`,
        subDivision,
        listgenUserID,
        mailerProID,
      },
      data: {},
      url: `${config.getPriceRangeDashboardPriceRangeWithBubbleData}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}

async function setMissionStatementDetails(missionStatementDetails) {
  console.log(
    "inside util set mission statement details",
    missionStatementDetails
  );
  try {
    const res = await axios({
      method: "POST",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {},
      data: missionStatementDetails,
      url: `${config.setMissionStatementDetails}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}

async function setBubbleDetails(bubbleDetails) {
  console.log("inside util", bubbleDetails);
  try {
    const res = await axios({
      method: "POST",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {},
      data: bubbleDetails,
      url: `${config.setBubbleDetails}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}
async function setVolunteerDetails(volunteerDetails) {
  console.log("inside util volunteerDetails", volunteerDetails);
  try {
    const res = await axios({
      method: "POST",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {},
      data: volunteerDetails,
      url: `${config.setVolunteerDetails}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}
async function getVolunteerDetails(pmaScheduleID, pmaMarketID) {
  // console.log("inside util for volunteer details", pmaScheduleID, marketID);
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        pmaScheduleID,
        pmaMarketID,
      },
      data: {},
      url: `${config.getVolunteerDetails}`,
    });
    return res;
  } catch (err) {
    console.log("error is", err);
    return null;
  }
}

async function setQuadrantDetails(quadrantDetails) {
  console.log("inside util", quadrantDetails);
  try {
    const res = await axios({
      method: "POST",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {},
      data: quadrantDetails,
      url: `${config.setQuadrantDetails}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}

async function getBubbleBoxDetails(listgenUserID) {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        listgenUserID,
      },
      data: {},
      url: `${config.getBubbleBoxDetails}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}

async function getQuadrantDetails(listgenUserID) {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        listgenUserID,
      },
      data: {},
      url: `${config.getQuadrantDetails}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}

async function getMarketTrends(
  areaAndCity,
  subDivision,
  listgenUserID,
  mailerProID
) {
  console.log("inside util for market trends");
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        areaCityPeriod: `${areaAndCity}`,
        subDivision,
        listgenUserID,
        mailerProID,
      },
      data: {},
      url: `${config.getMarketTrends}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}
async function getDonutAndTable(
  areaAndCity,
  subDivision,
  listgenUserID,
  mailerProID
) {
  //console.log("inside util for dashboard donut and table api");
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        areaCityPeriod: `${areaAndCity}`,
        subDivision,
        listgenUserID,
        mailerProID,
      },
      data: {},
      url: `${config.getDonutAndTable}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}
async function getPmaDetailsApi(
  areaAndCity,
  subDivision,
  listgenUserID,
  mailerProID
) {
  try {
    // console.log("inside util");
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        areaCityPeriod: `${areaAndCity}`,
        subDivision,
        listgenUserID,
        mailerProID,
      },
      data: {},
      url: `${config.getPmaDetailsApi}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}

async function getDashboardDonutApi(
  areaAndCity,
  subDivision,
  listgenUserID,
  mailerProID
) {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        areaCityPeriod: `${areaAndCity}`,
        subDivision,
        listgenUserID,
        mailerProID,
      },
      data: {},
      url: `${config.getDashboardDonutApi}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}

async function getMissionStatementApi(listgenUserID) {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        listgenUserID,
      },
      data: {},
      url: `${config.getMissionStatement}`,
    });
    return res;
  } catch (err) {
    console.log("issue with util getMisisonStatement", err);
    return null;
  }
}
async function getTableBubbleData(
  areaAndCity,
  subDivision,
  listgenUserID,
  mailerProID
) {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        areaCityPeriod: `${areaAndCity}`,
        subDivision,
        listgenUserID,
        mailerProID,
      },
      data: {},
      url: `${config.getTableBubbleData}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}

async function getTablePmaApi(
  areaAndCity,
  subDivision,
  listgenUserID,
  mailerProID
) {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        areaCityPeriod: `${areaAndCity}`,
        subDivision,
        listgenUserID,
        mailerProID,
      },
      data: {},
      url: `${config.getTablePmaApi}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}

async function getDataVerification(areaAndDate, subDivision) {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        areaIDRunDate: `${areaAndDate}`,
        subDivision,
      },
      data: {},
      url: `${config.getDataVerification}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}
//use for september
async function getDashBoardWithPriceRangeAndFeaturePhotos(
  cityAndDate,
  subDivision,
  listgenUserID,
  mailerProID
) {
  //console.log("inside util get december data");
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },

      params: {
        areaCityPeriod: `${cityAndDate}`,
        subDivision,
        listgenUserID,
        mailerProID,
      },
      data: {},
      url: `${config.getDecemberData}`,
    });
    return res;
  } catch (err) {
    return "There was an error with the December API in Util";
  }
}

async function getSpeedometerData(
  cityAndDate,
  subDivision,
  listgenUserID,
  mailerProID
) {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        areaCityPeriod: `${cityAndDate}`,
        subDivision,
        listgenUserID,
        mailerProID,
      },
      data: {},
      url: `${config.getSpeedometerData}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}

async function getMayApi(cityAndDate, subDivision, listgenUserID, mailerProID) {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        areaCityPeriod: `${cityAndDate}`,
        subDivision,
        listgenUserID,
        mailerProID,
      },
      data: {},
      url: `${config.getMayApi}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}

async function getAprilApi(cityAndDate, subDivision) {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        areaCityPeriod: `${cityAndDate}`,
        subDivision,
      },
      data: {},
      url: `${config.getAprilApi}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}

async function qAreaAndCityDetails(mlsAreaID, cityAndDate, subDivision) {
  // console.log("city and date util", cityAndDate);
  // console.log("subdivision util", subDivision);
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        areaCityPeriod: `${mlsAreaID}-${cityAndDate}`,
        subDivision,
      },
      data: {},
      url: `${config.qAreaAndCityDetails}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}

async function getYearEndDetails(date, listgenUserID) {
  // console.log('insie get year end util');
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        areaCityPeriod: date,
        listgenUserID,
      },
      data: {},
      url: `${config.getYearEndDetails}`,
    });
    return res;
  } catch (err) {
    return null;
  }
}

async function getSAT(hoID, listgenUserID) {
  // console.log('insie get agent advantages util');
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        listgenUserID,
        mailerProID: hoID,
      },
      data: {},
      url: `${config.getSAT}`,
    });
    return res;
  } catch (err) {
    //  console.log(
    //   'Something unexpected error received in get agentAdvantages.\n',
    //   err
    // );
    // console.log(err.message);
    return null;
  }
}

async function getSalePriceForCities(date) {
  //console.log('insie get agent advantages util');
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        avgSalePricePeriod: `Last12MonthsCityAverages-${date}`,
      },
      data: {},
      url: `${config.getSalePriceForCities}`,
    });
    return res;
  } catch (err) {
    //  console.log(
    //   'Something unexpected error received in get agentAdvantages.\n',
    //   err
    // );
    // console.log(err.message);
    return null;
  }
}

async function getAgentAdvantages(listgenUserID) {
  //console.log('insie get agent advantages util');
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        listgenUserID,
      },
      data: {},
      url: `${config.getAgentAdvantages}`,
    });
    return res;
  } catch (err) {
    //  console.log(
    //   'Something unexpected error received in get agentAdvantages.\n',
    //   err
    // );
    // console.log(err.message);
    return null;
  }
}

async function getAgentDesignations(listgenUserID) {
  //console.log('insie get agent designations util');
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        listgenUserID,
      },
      data: {},
      url: `${config.getAgentDesignations}`,
    });
    return res;
  } catch (err) {
    //  console.log(
    //   'Something unexpected error received in get agentDesignations.\n',
    //   err
    // );
    // console.log(err.message);
    return null;
  }
}

async function getTestimonials(listgenUserID) {
  //  console.log('config.getTestimonials', config.getTestimonials);
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        listgenUserID,
      },
      data: {},
      url: `${config.getTestimonials}`,
    });
    return res;
  } catch (err) {
    // console.log(
    //   'Something unexpected error received in get testimonials.\n',
    //   err
    // );
    // console.log(err.message);
    return null;
  }
}

async function getQuarterAreaTable(areaAndDate) {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      params: {
        pMAQuarterlyAreaIDRunDate: areaAndDate,
      },
      data: {},
      url: `${config.getQuarterAreaTable}`,
    });
    return res;
  } catch (err) {
    // console.log(
    //   'Something unexpected error received in getQuarterAreaTable.\n',
    //   err
    // );
    // console.log(err.message);
    return null;
  }
}

async function getPMACoverPhotoDetails(city) {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      params: {
        regionID: city,
        regionType: "city",
      },
      data: {},
      url: `${config.getPMACoverPhotoDetails}`,
    });
    return res;
  } catch (err) {
    console.log(
      "Something unexpected error received in getPMACoverPhotoDetails.\n",
      err
    );
    // console.log(err.message);
    return null;
  }
}

async function getLocalSellingAgents(areaAndDate) {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      params: {
        agentStatsAreaIDRunDate: areaAndDate,
      },
      data: {},
      url: `${config.getLocalSellingAgents}`,
    });
    return res;
  } catch (err) {
    console.log(
      "Something unexpected error received in getLocalSellingAgents.\n",
      err
    );
    return null;
  }
}

async function getQuarterCityTable(cityAndDate) {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      params: {
        pMAQuarterlyCityIDRunDate: cityAndDate,
      },
      data: {},
      url: `${config.getQuarterCityTable}`,
    });
    return res;
  } catch (err) {
    console.log(
      "Something unexpected error received in getQuarterCityTable.\n",
      err
    );
    //console.log(err.message);
    return null;
  }
}

async function getMailerProDetails(areaID) {
  //console.log("inside mailer pro util", areaID);
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      params: {
        areaID,
      },
      data: {},
      url: `${config.getMailerProDetails}`,
    });
    return res;
  } catch (err) {
    console.log(
      "Something unexpected error received getting mailer pro details util.\n",
      err
    );
    // console.log(err.message);
    return null;
  }
}

async function updateHomeownerProp(hoData) {
  try {
    const res = await axios({
      method: "POST",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      data: hoData,
      url: `${config.updateMailerProDetailsV1}`,
    });
    return res;
  } catch (err) {
    // console.log(
    //   'Something unexpected error received in util udpateHomeownerProp.\n',
    //   err
    // );
    // console.log(err.message);
    return null;
  }
}

async function updateQuickPersonalNotePS(
  hoData,
  mode = "note",
  resetMode = false
) {
  let url = resetMode
    ? `${config.resetPersonalNoteAndPSForMailerProID}?mailerProID=${hoData.mailerProID}`
    : mode === "note"
    ? config.setPersonalNoteForMailerProID
    : config.setPersonalPSForMailerProID;
  try {
    const res = await axios({
      method: resetMode ? "GET" : "POST",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      data: hoData,
      url: `${url}`,
    });
    return res;
  } catch (err) {
    // console.log(
    //   'Something unexpected error received in util udpateHomeownerProp.\n',
    //   err
    // );
    // console.log(err.message);
    return null;
  }
}

async function updateMailProEntity(hoData, isAgent) {
  //build the ho data
  try {
    const res = await axios({
      method: "POST",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      data: {
        mailerProID: hoData.mailerProID,
        areaID: hoData.areaID,
        agentID: hoData.agentID,
        firstName: hoData.mailingDetails.OwnerFirst,
        lastName: hoData.mailingDetails.OwnerLast,
        mailingAddress: hoData.mailingDetails.MailAddress,
        city: hoData.mailingDetails.MailCity,
        state: hoData.mailingDetails.MailState,
        zipcode: hoData.mailingDetails.MailZip,
        isAgent: isAgent,
      },
      url: `${config.updateMailerProEntity}`,
    });
    return res;
  } catch (err) {
    // console.log(
    //   'Something unexpected error received in util udpateHomeownerProp.\n',
    //   err
    // );
    // console.log(err.message);
    return null;
  }
}

async function getCityAreaMap(countyID, index) {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
      params: {
        countyID,
      },
      data: {},
      url: `${config.getCityAreaMap}`,
    });
    return res;
  } catch (err) {
    // console.log('Something unexpected error received.\n', err);
    // console.log(err.message);
    return null;
  }
}

async function getMailProIDDetails(mailProID) {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
      params: {
        mailProID,
      },
      data: {},
      url: `${config.getMailProIDDetails}`,
    });
    return res;
  } catch (err) {
    // console.log('Something unexpected error received.\n', err);
    // console.log(err.message);
    return null;
  }
}

async function getMarketActivity(areaAndDate) {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      params: {
        areaIDPMAMidQuarterDailyRunDate: areaAndDate,
      },
      data: {},
      url: `${config.getMarketActivity}`,
    });
    return res;
  } catch (err) {
    // console.log(
    //   'Something unexpected error received in getMarketActivity.\n',
    //   err
    // );
    // console.log(err.message);
    return null;
  }
}

async function getNavbarInfo(listgenUserID) {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${GetOAuthTokenByKey("access_token")}`,
      },
      params: {
        listgenUserID,
        isRefresh: false,
      },
      data: {},
      url: `${config.getNavbarInfoV1}`,
    });
    return res;
  } catch (err) {
    // console.log('Something unexpected error received in getNavbarInfo.\n', err);
    // console.log(err.message);
    return null;
  }
}

async function updateNavbar(mlsAreas) {
  try {
    const res = await axios({
      method: "POST",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      data: mlsAreas,
      url: `${config.updateNavbar}`,
    });
    return res;
  } catch (err) {
    // console.log('Something unexpected error received in updateNavbar.\n', err);
    // console.log(err.message);
    return null;
  }
}

async function getMQ2CityDetails(cityAndDate, subDivision, listgenUserID) {
  // console.log("inside util get MQ2 citydetails", cityAndDate);
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      params: {
        areaCityPeriod: cityAndDate,
        subDivision,
        listgenUserID,
      },
      data: {},
      url: `${config.getMQ2CityDetails}`,
    });
    return res;
  } catch (err) {
    // console.log('Something unexpected error received in getNavbarInfo.\n', err);
    // console.log(err.message);
    return null;
  }
}

async function getMQCityDetails(cityAndDate) {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      params: {
        cityIDPMAMidQuarterCityRunDate: cityAndDate,
      },
      data: {},
      url: `${config.getTotalUnits}`,
    });
    return res;
  } catch (err) {
    // console.log('Something unexpected error received in getNavbarInfo.\n', err);
    // console.log(err.message);
    return null;
  }
}

async function approveMailingList(approveObj) {
  try {
    const res = await axios({
      method: "POST",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      data: {
        areaID: approveObj.areaID,
        mailProIDs: approveObj.mailProIDs,
      },
      url: `${config.approveMailProIDs}`,
    });
    return res;
  } catch (err) {
    // console.log(
    //   'Something unexpected error received in approveMailingList util.\n',
    //   err
    // );
    // console.log(err.message);
    return null;
  }
}

async function changeMailProStatus(listgenUserID, mailProStatus) {
  console.log("inside util", listgenUserID, mailProStatus);
  try {
    const res = await axios({
      method: "GET",
      headers: {
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      params: {
        listgenUserID,
        mailProStatus,
      },
      url: `${config.changeMailProStatus}`,
      data: {},
    });
    return res;
  } catch (err) {
    // console.log(
    //   'Something unexpected error received in changeMailProStatus util.\n',
    //   err
    // );
    // console.log(err.message);
    return null;
  }
}
