import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Dashboard as DashboardLayout } from 'layouts';
import compose from "recompose/compose";
import { CreditCardListComponent } from './components';
import { GetStateStorage } from 'common/storage';
import Base from 'common/constants';
import { red } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { isAFaultyPage } from 'common/helpers';
import DocumentTitle from 'react-document-title';
import { systemLogRequest } from 'common/helpers';
import { isUserAdmin } from 'common/roles/role';

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    minHeight: '80vh',
    //width: '100%',
    maxWidth: '100%',
  },
  container: {
    marginTop: theme.spacing(2)
  },
});

class CreditCardManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      userData: {
        firstName: 'Amit',
        lastName: 'Kumar',
        fullname: 'Amit Kumar',
        email: 'akchoudhary966@gmail.com',
        phoneNumber: '9109911163',
        role: 'Agent',
        status: 'active',
        progress: 100,
      },
      isAdmin: false,
      listgenUserID: null,
      listgenAgentFirstName: null,
      listgenAgentLastName: null,
      listgenAgentEmailAddress: null,
      confirmationTitle: null,
      confirmationSubtitle: null,
      faultyPage: false,
      error: '',
      chargeCurrency: 'USD'
    };
  }

  /**
   * If logged in user role is Admin then take the id from params. Otherwise take from local storage
   */
  componentDidMount() {
    //check the role first
    //url format is: 
    // /cards/credit/ad/:listgenUserID/:agentFirstName/:agentLastName/:emailID
    //New changes due to session switch addition on every screen. Check listgen user id with the storage if its not`
    try {
      const { userData } = GetStateStorage();
      const listgenRoleID = userData.listgenRoleID;
      const listgenStorageUserID = userData.listgenUserID;
      const listgenParamsUserID = atob(this.props.match.params.listgenUserID);
      const listgenParamsAgentFirstName = atob(this.props.match.params.agentFirstName);
      const listgenParamsAgentLastName = atob(this.props.match.params.agentLastName);
      const listgenParamsEmailAddress = atob(this.props.match.params.emailID);
      //check for is admin
      const isAdmin = isUserAdmin(listgenRoleID);
      const listgenUserIDAccordingToRole = (isAdmin ? listgenParamsUserID : listgenStorageUserID);
      const listgenAgentFirstNameAccordingToRole = (isAdmin ? listgenParamsAgentFirstName : userData.listgenUserFirstName);
      const listgenAgentLastNameAccordingToRole = (isAdmin ? listgenParamsAgentLastName : userData.listgenUserLastName);
      const listgenAgentEmailAddressAccordingToRole = (isAdmin ? listgenParamsEmailAddress : userData.emailAddress);
      ///Check for faulty page. if its the faulty page then redirect to dashbaord. In case of error faulty page will redirec to other route
      isAFaultyPage(listgenRoleID, listgenStorageUserID, listgenParamsUserID).then(result => {
        this.setState({
          listgenUserID: listgenUserIDAccordingToRole,
          listgenAgentFirstName: listgenAgentFirstNameAccordingToRole,
          listgenAgentLastName: listgenAgentLastNameAccordingToRole,
          listgenAgentEmailAddress: listgenAgentEmailAddressAccordingToRole,
          isAdmin,
          faultyPage: false
        });
      });
    } catch (err) {
      systemLogRequest('credit_card', 100, err);
      this.setState({
        faultyPage: true,
        error: (err.message ? err.message : 'Error Code: 100')
      });
    }
  };

  // componentDidMount = () => {
  //   console.log('state data logged');
  //   console.log(this.state);
  // };

  render() {
    const { classes } = this.props;
    const { openCCAdditionDialog, openCreditCardConfirmationDialog, openChargeCreditCardDialog, isAdmin, listgenUserID, listgenAgentFirstName, listgenAgentLastName, listgenAgentEmailAddress, title, subtitle, faultyPage, chargeCurrency, error } = this.state;

    return (
      <DocumentTitle title="Manage Credit Cards">
        <DashboardLayout title="Manage Credit Cards">
          <div className={classes.root}>
            <Grid
              container
              spacing={2}
            >
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                item
              />
              {!faultyPage ?
                <CreditCardListComponent
                  enableCreditDialogFn={this.enableCCDialog}
                  closeCCDialogFn={this.closeCCDialog}
                  enableCCConfirmationDialogFn={this.enableCCConfirmationDialog}
                  closeCCConfirmationDialogFn={this.closeCCConfirmationDialog}
                  enableCCChargeDialogFn={this.enableCCChargeDialog}
                  closeCCChargeDialogFn={this.closeCCChargeDialog}
                  isAdmin={isAdmin}
                  listgenUserID={listgenUserID}
                  listgenAgentFirstName={listgenAgentFirstName}
                  listgenAgentLastName={listgenAgentLastName}
                  listgenAgentEmailAddress={listgenAgentEmailAddress}
                  chargeCurrency={chargeCurrency}
                />
                :
                <Alert variant="filled" severity="error" style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                  <AlertTitle>Error</AlertTitle>
                  We are unable to process your request. Please try after some time.
                  <br />
                  {error}
                </Alert>
              }
            </Grid>
          </div>
        </DashboardLayout>
      </DocumentTitle>
    );
  }
}

CreditCardManager.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(CreditCardManager);
