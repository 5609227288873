import React, { Component } from "react";

class DBHeader extends Component {
  render() {
    return (
      <div className="db-header">
        <div className="db-header-main">4 Key Market Trends</div>
        <div className="db-header-sub">To Keep An Eye On</div>
      </div>
    );
  }
}

export default DBHeader;
