export const Roles = {
    ADMIN: 1,
    AGENT: 2,
    BROKER_ADMIN: 20
};

export const isUserAdmin = (role) => {
    return role === Roles.ADMIN || parseInt(role) === Roles.ADMIN;
}

export const isUserAgent = (role) => {
    return role === Roles.AGENT || parseInt(role) === Roles.AGENT;
}

export const isUserBrokerAdmin = (role) => {
    return role === Roles.BROKER_ADMIN || parseInt(role) === Roles.BROKER_ADMIN;
}

