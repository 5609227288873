import React, { Component, Fragment } from "react";
import classNames from "classnames";
import {
  Portlet,
  PortletContent,
  PortletHeader,
  PortletLabel,
} from "components";
import { Grid, Typography, Divider, Button } from "@material-ui/core";
import compose from "recompose/compose";
import _ from "lodash";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import { WebsiteLayout } from "layouts";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Grade } from "@material-ui/icons";
import { GetStateStorage } from "common/storage";
import Base from "common/constants";
import moment from "moment";
import { getAgentPrintTimesText } from "common/helpers";
import {
  getVersionFullName,
  convertToFormat,
  getMonthYearForDisplay,
  MailingMonthNameMapping,
} from "./MailingScheduleServices";
import PrintFormatDialog from "./PrintFormatDialog";
import { EllipsisLoader } from "../../components/EllipsisLoader/index";
import { withSnackbar } from "notistack";
import { showErrorNotification } from "common/helpers";
import { getScheduleByYear } from "./MailingScheduleServices";
import { isNonMailingSchedule } from "./MailingScheduleServices";
import { Link } from "react-router-dom";
import palette from "theme/palette";
import AddToCalendar from "components/AddToCalendar";
import Constants from "common/constants";
import { isUserAdmin } from "common/roles/role";

class ScheduleBodyV2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listgenLogo: "/images/rsz_listgen-name-logo.jpg",
      listgenLogoAlt: "ListGen Logo",
      totalTimesQuarterlyPMAPrinted: null,
      totalTimesMidQuarterlyPMAPrinted: null,
      listgenRoleID: null,
      isLoading: true,
      noMailingScheduleFound: false,
      scheduleData: [],
    };
  }

  componentDidMount() {
    const localStorageData = GetStateStorage();
    const pmaScheduleID =
      localStorageData &&
      localStorageData.userData &&
      localStorageData.userData.pmaScheduleID;
    if (!pmaScheduleID) {
      this.setState({
        noMailingScheduleFound: true,
      });
      return;
    }
    getScheduleByYear(
      moment().format("YYYY"),
      pmaScheduleID,
      null,
      "get_rolling"
    )
      .then((data) => {
        this.setState({
          scheduleData: data ? this.buildDataForSchedule(data) : [],
          isLoading: false,
        });
      })
      .catch((err) => {
        showErrorNotification(
          this.props,
          "Something went wrong. Unable to fetch mailing schedule."
        );
        this.setState({
          scheduleData: [],
          isLoading: false,
          noMailingScheduleFound: false,
        });
      });
    if (localStorageData.userData) {
      this.setState({
        totalTimesQuarterlyPMAPrinted: localStorageData.userData
          .totalTimesQuarterlyPMAPrinted
          ? localStorageData.userData.totalTimesQuarterlyPMAPrinted
          : 0,
        totalTimesMidQuarterlyPMAPrinted: localStorageData.userData
          .totalTimesMidQuarterlyPMAPrinted
          ? localStorageData.userData.totalTimesMidQuarterlyPMAPrinted
          : 0,
        listgenRoleID: localStorageData.userData.listgenRoleID,
        scheduleID: pmaScheduleID,
      });
    }
  }

  buildDataForSchedule = (scheduleData) => {
    //Here we going to handle non mailing version. If non mailing version is is before today date then we will mark that as done so it can move to bottom
    scheduleData = scheduleData.map((item) => {
      if (
        isNonMailingSchedule(item.version) &&
        moment(item.hoDeliveryDateEnd).isBefore(new Date())
      ) {
        item.status = "delivered";
      }
      return item;
    });
    return scheduleData;
  };

  sortScheduleData = () => {
    let scheduleData = [...this.state.scheduleData];
    const keysToDelete = [];
    //put delivered on bottom then first one will be the current version
    const deliveredScheduled = scheduleData.filter((item, key) => {
      if (item.status === "delivered") {
        keysToDelete.push(key);
        return true;
      }
      return false;
    });
    //delete the delivered from original array
    scheduleData = scheduleData.filter(
      (_, key) => keysToDelete.indexOf(key) === -1
    );
    return [...scheduleData, ...deliveredScheduled];
  };

  getStatus(schedule, returnHtml = false) {
    //Open for Review/Approval (in Green), No Edits / Currently Printing (Red), Delivered to Post Office (Blue), Out for Delivery to Homeowners (Darker Blue), Delivered to Homeowners (Darker Green
    let currentTimestamp = moment();
    const { classes } = this.props;
    const stateData = GetStateStorage();
    const agentData = stateData.userData;
    let navbarData =
      agentData &&
      Array.isArray(agentData.navbarInfo) &&
      agentData.navbarInfo[0];

    if (
      moment(currentTimestamp).isBetween(
        new Date(schedule.reviewDateStart),
        new Date(schedule.reviewDateEnd)
      )
    ) {
      // <span className={classes.inReviewColor}></span>
      return returnHtml ? (
        <Link to={`/pma/${navbarData.mlsAreaID}`}>
          Please Click here to Review &amp; Approve your PMA
        </Link>
      ) : (
        "Open for Review / Approval"
      );
    } else if (
      moment(currentTimestamp).isBetween(
        new Date(schedule.printDateStart),
        new Date(schedule.printDateEnd)
      )
    ) {
      return returnHtml ? (
        <span className={classes.inPrintingColor}>
          No Edits / Currently Printing
        </span>
      ) : (
        "No Edits / Currently Printing"
      );
    } else if (
      moment(currentTimestamp).isBetween(
        new Date(schedule.printDateEnd),
        new Date(schedule.uspsHandoverDate)
      )
    ) {
      return returnHtml ? (
        <span className={classes.postOfficeColor}>
          Delivering to Post Office
        </span>
      ) : (
        "Delivering to Post Office"
      );
    } else if (
      moment(currentTimestamp).isBetween(
        new Date(schedule.uspsHandoverDate),
        new Date(schedule.hoDeliveryDateStart)
      )
    ) {
      return returnHtml ? (
        <span className={classes.postOfficeColor}>
          Delivered to Post Office
        </span>
      ) : (
        "Delivered to Post Office"
      );
    } else if (
      moment(currentTimestamp).isBetween(
        new Date(schedule.hoDeliveryDateStart),
        new Date(schedule.hoDeliveryDateEnd)
      )
    ) {
      return returnHtml ? (
        <span className={classes.deliveryColor}>
          Out for Delivery to Homeowners
        </span>
      ) : (
        "Out for Delivery to Homeowners"
      );
    } else if (schedule.status === "delievered") {
      return returnHtml ? (
        <span className={classes.deliveredColor}>Delivered to Homeowners</span>
      ) : (
        " Delivered to Homeowners"
      );
    } else {
      return "---";
    }
  }

  buildCalendarEvent = () => {
    const localStorageData = GetStateStorage();
    const { countyID, navbarInfo } =
      (localStorageData && localStorageData.userData) || {};
    // const areaNames = mlsNeighborhood
    const withoutDateEvent = {
      title: "ListGen PMA Cut-Off Date",
      description:
        "Please review and approve your PMA before this date. Let us know if you want any changes in your PMA.",
      location: `${Constants.COUNTY_ID_NAME_MAP[countyID] + ", CA" || "California (CA)"
        }`,
      startTime: new Date(),
      endTime: new Date(),
    };
    return (startTime, endTime) => {
      return { ...withoutDateEvent, ...{ startTime, endTime } };
    };
  };

  buildCalendarEvent = () => {
    const localStorageData = GetStateStorage();
    const { countyID, navbarInfo, emailAddress, teamMembers } =
      (localStorageData && localStorageData.userData) || {};
    // const areaNames = mlsNeighborhood
    const attendies = new Set();
    emailAddress && attendies.add(emailAddress);
    if (Array.isArray(teamMembers)) {
      teamMembers.forEach((member) => {
        member.email && attendies.add(member.email);
      });
    }
    const withoutDateEvent = {
      title: "ListGen PMA Approval Cut-Off Date",
      description:
        "Please sign into {{1}} and REVIEW and APPROVE your PMA within these calendar dates.\n\nThank you!\nYour ListGen Team",
      location: `${Constants.COUNTY_ID_NAME_MAP[countyID] + ", CA" || "California (CA)"
        }`,
      startTime: new Date(),
      endTime: new Date(),
      attendies: Array.from(attendies),
      appleCalUrl: "https://listgen.com",
      links: [
        {
          href: "https://listgen.com",
          title: "ListGen.com",
        },
      ],
    };
    return (startTime, endTime) => {
      return { ...withoutDateEvent, ...{ startTime, endTime } };
    };
  };

  render() {
    const { classes, className } = this.props;
    const rootClassName = classNames(className);
    const {
      scheduleData,
      listgenLogo,
      listgenLogoAlt,
      listgenRoleID,
    } = this.state;

    const calendarEvent = this.buildCalendarEvent();

    return (
      <Grid container className={classes.grid} spacing={0}>
        <Grid item lg={12} className={classes.paddingTC}>
          <div
            className={classes.headerMain}
            style={{ justifyContent: "center" }}
          >
            <div className={classes.middleSection}>
              <div className={classes.imageSection}>
                <div className={classes.imageWrapper}>
                  <img
                    alt={listgenLogoAlt}
                    className={classes.image}
                    src={listgenLogo}
                  />
                </div>
              </div>
              <div
                className={classes.mailingTextMargin}
                style={{ marginBottom: "16px" }}
              >
                <Typography
                  variant="h3"
                  align="center"
                  color="primary"
                  className={classes.headingColor}
                >
                  PRECISION MARKET ANALYSIS MAILING SCHEDULE
                </Typography>
              </div>
            </div>
            {/* <div></div> */}
          </div>
          <div className={classes.lineSpacingBreak}></div>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Typography
              variant="subtitle1"
              align="center"
              style={{ width: "70%" }}
            >
              This table provides a list of the approximate print, finishing,
              and delivery dates for the upcoming PMA mailings.
              <br />
              ListGen will do everything possible to maintain strict adherence
              to this schedule, but on occasion,
              <br />
              some dates may change due to possible interruptions that are out
              of our control.
            </Typography>
          </div>
          <div className={classes.lineSpacingBreak}></div>
          {isUserAdmin(listgenRoleID) ? (
            ""
          ) : (
            //show only agent role id
            <Fragment>
              {/* <div
                className={
                  classes.lineSpacingBreak
                }
              ></div>
              <Typography variant="h5" color="primary" align="center">
                {`This is your ${getAgentPrintTimesText({ totalTimesPMAPrinted })} Precision Market Analysis mailing.`}
              </Typography>
              <div
                className={classes.lineSpacingBreak}
              ></div> */}
            </Fragment>
          )}
          <div className={classes.lineSpacingBreak}></div>
          {this.state.noMailingScheduleFound && (
            <div className={classes.noScheduleContainer}>
              <div className={classes.noSchedule}>
                Your account is not mapped to any schedule yet. Please wait till
                the time system assign a schedule.
              </div>
            </div>
          )}
          {this.state.isLoading ? (
            <div className={classes.loadingSchedule}>
              <EllipsisLoader />
            </div>
          ) : this.state.scheduleData.length === 0 ? (
            <div className={classes.noScheduleContainer}>
              <div className={classes.noSchedule}>
                No mailing schedule found.
              </div>
            </div>
          ) : (
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
              padding="none"
            >
              <TableHead>
                <TableRow>
                  {/* <TableCell padding="none" align="center" style={{ width: '5%' }} className={classes.stickyHead}></TableCell> */}
                  <TableCell
                    padding="none"
                    align="center"
                    style={{ width: "14%" }}
                    className={classes.stickyHead}
                  >
                    PMA Version Name
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    style={{ width: "20%" }}
                    className={classes.stickyHead}
                  >
                    PMAs are Available <br />
                    to Review &amp; Approve
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    style={{ width: "10%" }}
                    className={classes.stickyHead}
                  >
                    PMA Approval Deadline
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    style={{ width: "12%" }}
                    className={classes.stickyHead}
                  >
                    Printing and Finishing
                    <br />
                    Dates
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    style={{ width: "10%" }}
                    className={classes.stickyHead}
                  >
                    Delivered to Post Office
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    style={{ width: "12%" }}
                    className={classes.stickyHead}
                  >
                    Approximate Delivery
                    <br />
                    to Homeowners
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    className={classes.stickyHead}
                    style={{ width: "11%" }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.sortScheduleData(scheduleData).map((item, key) => {
                  return isNonMailingSchedule(item.version) ? (
                    <TableRow
                      className={classNames(
                        classes.tableBodyRow,
                        key === 0 ? classes.ongoingPrinting : ""
                      )}
                    >
                      <TableCell
                        padding="none"
                        colSpan={8}
                        align="center"
                        className={classes.lightColor}
                        style={{ height: "86px" }}
                      >
                        <Typography variant="subtitle2" color="primary">
                          ----&nbsp;No Mailing in{" "}
                          {moment(item.hoDeliveryDateEnd).format("MMM, YYYY")}{" "}
                          &nbsp;----
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow
                      className={classNames(
                        classes.tableBodyRow,
                        item.status === "delivered"
                          ? //if its completed mailing use blue color, if LAST completed mailing use light blue background also
                          classNames(
                            classes.completedPrinting,
                            key === scheduleData.length - 1
                              ? classes.lastCompletedMailing
                              : {}
                          )
                          : key === 0
                            ? classes.ongoingPrinting
                            : ""
                      )}
                    >
                      {/* <TableCell padding="normal" align="center" className={classes.customizedPaddingSchedule}>
                            {item.status === 'delivered' ?
                              'Delivered to Homeowners' : null}
                            key < 3 ? <PrintFormatDialog version={item.version} viewPMAURL={item.viewPMAURL} /> : null}
                          </TableCell> */}
                      <TableCell
                        padding="none"
                        align="center"
                        className={classes.customizedPaddingSchedule}
                      >
                        {key === 0 && (
                          <Fragment>
                            <span
                              style={{
                                fontSize: "15px",
                                textAlign: "center",
                                color: palette.primary.main,
                              }}
                            >
                              THIS IS THE <br />
                              CURRENT MAILING
                            </span>
                            <br />
                            <br />
                          </Fragment>
                        )}

                        {/* <span style={{ fontSize: "14px" }}>
                          {item.pmaDisplayName}
                        </span> */}
                        {/* <br />
                        <br /> */}
                        {item.status === "delivered"
                          && <Fragment>
                            <span style={{ fontSize: "14px" }}>DELIVERED to Homeowners</span>
                            <br />
                            <br />
                          </Fragment>}
                        <span
                          style={{ fontSize: "17px" }}
                          className={classes.greenColor}
                        >
                          {MailingMonthNameMapping[item.mailingMonth] ? MailingMonthNameMapping[item.mailingMonth] : getMonthYearForDisplay(item.reviewDateStart)}
                        </span>
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="center"
                        className={classNames(
                          "ongoingCutoff",
                          classes.customizedPaddingSchedule
                        )}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {key < 4 && (
                            <div style={{ marginRight: "16px" }}>
                              <AddToCalendar
                                event={calendarEvent(
                                  new Date(item.reviewDateStart + " 00:00:00"),
                                  new Date(item.reviewDateEnd + " 23:59:59")
                                )}
                              >
                                <Button
                                  //color="primary"
                                  variant="outlined"
                                  size="small"
                                  style={{
                                    width: "100px",
                                    fontSize: "0.6125rem",
                                    background: palette.primary.currentLightBlue,
                                    color: 'white'
                                  }}

                                >
                                  <div className={classNames("flashDate10")}>
                                    CLICK HERE <br />
                                    TO ADD THESE
                                    <br /> DATES TO YOUR <br />
                                    CALENDAR
                                  </div>
                                </Button>
                              </AddToCalendar>
                            </div>
                          )}
                          <div className={classNames("flashDate")}>
                            {convertToFormat(item.reviewDateStart)}
                            <div className={classes.toSeparator}>through</div>
                            {convertToFormat(item.reviewDateEnd)}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="center"
                        className={classNames(
                          "ongoingCutoff",
                          classes.customizedPaddingSchedule
                        )}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div className={classNames("flashDate10")}>
                            {convertToFormat(item.cutOffDateTime, "date")}
                            <br />
                            <br />
                            {convertToFormat(item.cutOffDateTime, "time")}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="center"
                        className={classes.customizedPaddingSchedule}
                      >
                        {convertToFormat(item.printDateStart)}
                        <div className={classes.toSeparator}>through</div>
                        {convertToFormat(item.printDateEnd)}
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="center"
                        className={classes.customizedPaddingSchedule}
                      >
                        {convertToFormat(item.uspsHandoverDate)}
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="center"
                        className={classes.customizedPaddingSchedule}
                      >
                        {convertToFormat(item.hoDeliveryDateStart)}
                        <div className={classes.toSeparator}>through</div>
                        {convertToFormat(item.hoDeliveryDateEnd)}
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="center"
                        className={classes.customizedPaddingSchedule}
                      >
                        {item.status === "delivered"
                          ? "DELIVERED to Homeowners"
                          : key === 0
                            ? this.getStatus(item, true)
                            : "---"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default compose(withSnackbar, withStyles(styles))(ScheduleBodyV2);
