import { roiActionConstants } from "_constants";

/* ROI Action Creators */
export const roiActions = {
  deleteSelectedArea,
  addSelectedArea,
  setROIEnlargeView,
  setROIAreaIntervelID,
  initROIOpenAccessView,
  addROICityDataMapForCounty,
  handleROIAreaSelectionResponse,
};

function deleteSelectedArea(areaID) {
  //for ROI

  return {
    type: roiActionConstants.DELETE_SELECTED_AREA,
    payload: areaID,
  };
}

function addSelectedArea(areaObj) {
  //for ROI
  return {
    type: roiActionConstants.ADD_SELECTED_AREA,
    payload: areaObj,
  };
}

function initROIOpenAccessView(enlarge = true) {
  //for ROI
  return {
    type: roiActionConstants.INIT_ROI_OPEN_ACCESS_VIEW,
    payload: enlarge,
  };
}

function setROIEnlargeView(enlarge = true) {
  //for ROI
  return {
    type: roiActionConstants.SET_ROI_ENLARGE_VIEW,
    payload: enlarge,
  };
}

function setROIAreaIntervelID(enlarge = true) {
  //for ROI
  return {
    type: roiActionConstants.SET_ROI_AREA_INTERVEL_ID,
    payload: enlarge,
  };
}

function addROICityDataMapForCounty(data) {
  //for ROI
  return {
    type: roiActionConstants.ADD_ROI_AREA_CITY_DATA_FOR_COUNTY,
    payload: data,
  };
}

function handleROIAreaSelectionResponse(
  pendingAreas,
  selectedAreas,
  licensedAreas
) {
  //for ROI
  return {
    type: roiActionConstants.HANDLE_ROI_AREA_ALLOCATION_RESPONSE,
    payload: {
      pendingAreas,
      selectedAreas,
      licensedAreas,
    },
  };
}
