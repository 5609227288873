import React, { useState, useEffect, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { GetStateStorage } from "common/storage";
import PSContents from "views/Pma/PmaEditor/CommonEditor/PSContents";
import QVThreeGreetingsPS from "views/Pma/PmaEditor/Quarterly/QVThree/QVThreeSecondPage/QVThreeGreetingsPS";
import MQThreeGreetingsPS from "views/Pma/PmaEditor/MidQuarter/MQThree/MQThreeSecondPage/MQThreeGreetingsPS";
import QVFourGreetingsPS from "views/Pma/PmaEditor/Quarterly/QVFour/QVFourSecondPage//QVFourGreetingsPS";

require("../../views/Pma/PmaEditor/MidQuarter/MQThree/mq3editor.scss");

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: 1,
    height: "100%",
  },
  margin: {
    margin: theme.spacing(1),
  },
  portletContentCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    position: "relative",
    minHeight: "200px",
  },
  footerButtons: {
    position: "relative",
    display: "flex",
  },
  portletFooter: {
    display: "inline-block",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: "100%",
  },
  footerButtonsCenter: {
    justifyContent: "center",
    alignItems: "center",
  },
  footerButtonsLeftRight: {
    flex: 1,
  },
  leftAlignText: {
    textAlign: "left !important",
    "&>p": {
      textAlign: "left !important",
    },
  },
  confrimationText: {
    color: red[900],
    fontWeight: "500",
    marginTop: theme.spacing(1),
  },
  dualModeMArgin: {
    marginTop: theme.spacing(1),
  },
  clearfix: {
    clear: "both",
  },
}));

export default function GlobalNotePSReviewDialog(props) {
  const {
    version,
    greetingTop,
    greetingBottom,
    globalNoteToReview,
    globalNoteToReviewTeam,
    dualMode,
    showConfirmationText,
    confirmationID,
    closeFn,
    methodToCallOnConfirmation,
    globalPS,
    globalPSTeam,
    cameFrom,
    currentArea,
    fromMPInsights,
  } = props;

  const stateData = GetStateStorage();
  const agentData = stateData.userData;

  const {
    isMemberOfTeam,
    teamMembers,
    listgenUserFirstName,
    listgenUserID,
  } = agentData || {};

  const [open, setOpen] = React.useState(true);
  /**classes */
  const classes = useStyles();

  const handleConfirmationClick = () => {
    /**call the close method first */
    closeFn();
    methodToCallOnConfirmation(confirmationID);
  };

  const handleClose = () => {
    /**call the close method first */
    closeFn();
  };

  // useEffect(() => {
  //     let element = document.getElementById('global-ps-review-main-div-to-test');
  //     if (element) {
  //         let isOverflown = element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  //         console.log('isOverflown element');
  //         console.log(isOverflown);
  //     }

  // });

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
        fullWidth={true}
      >
        <DialogTitle id="cc-alert-dialog-title">
          <Typography variant="h5" align="center">
            Review your{" "}
            {cameFrom === "gps"
              ? "GLOBAL NOTE to all Homeowners"
              : cameFrom === "pps"
              ? "PERSONAL NOTE to specific Homeowner"
              : "note"}{" "}
            before submitting.
          </Typography>
          {/* <Typography variant="body1" align="center">
            The note will look like this on your PMA.
          </Typography> */}
        </DialogTitle>
        <DialogContent className={classes.portletContentCenter}>
          {dualMode ? (
            <Fragment>
              <Typography variant="subtitle2">
                GLOBAL NOTE for Agent (Individual)
              </Typography>
              <br />
              <br />
            </Fragment>
          ) : (
            ""
          )}
          {fromMPInsights ? (
            <MQThreeGreetingsPS
              theHomeowners={greetingTop}
              hoTextNote={globalNoteToReview}
              fromMailPro={true}
              agentSignoff={greetingBottom}
              mailingNum="mq3"
              currentPS={globalPS}
              agentData={agentData}
            />
          ) : (
            <QVFourGreetingsPS
              version={version}
              theHomeowners={greetingTop}
              hoTextNote={globalNoteToReview}
              isMemberOfTeam={isMemberOfTeam}
              teamMembers={teamMembers}
               listgenUserFirstName={listgenUserFirstName}
              mailproAgentSignoff={greetingBottom}
              currentArea={currentArea}
              currentPS={globalPS}
              listgenUserID={listgenUserID}
              fromMailPro={true}
              mailingNum="qv4"
              agentData={agentData}
            />
          )}

          <div className={classes.clearfix}></div>
          {dualMode ? (
            <Fragment>
              <br />
              <br />
              <Typography
                variant="subtitle2"
                className={classes.dualModeMArgin}
              >
                GLOBAL NOTE for Agent (Team)
              </Typography>
              <br />
              <br />
            </Fragment>
          ) : (
            ""
          )}
          {globalNoteToReviewTeam && fromMPInsights ? (
            <MQThreeGreetingsPS
              theHomeowners={greetingTop}
              hoTextNote={globalNoteToReviewTeam}
              fromMailPro={true}
              agentSignoff={greetingBottom}
              mailingNum="mq3"
              currentPS={globalPS}
              agentData={agentData}
              listgenUserID={listgenUserID}
            />
          ) : globalNoteToReviewTeam ? (
            <QVFourGreetingsPS
              version={version}
              theHomeowners={greetingTop}
              hoTextNote={globalNoteToReviewTeam}
              isMemberOfTeam={isMemberOfTeam}
              teamMembers={teamMembers}
               listgenUserFirstName={listgenUserFirstName}
              mailproAgentSignoff={greetingBottom}
              currentArea={currentArea}
              listgenUserID={listgenUserID}
              currentPS={globalPSTeam}
              fromMailPro={true}
              mailingNum="qv4"
              agentData={agentData}
            />
          ) : (
            ""
          )}
          {showConfirmationText ? (
            <Fragment>
              <br />
              <br />
              <Typography
                variant="subtitle2"
                align="center"
                className={classes.confrimationText}
              >
                After updating the GLOBAL NOTE from here it will replace all
                GLOBAL NOTE/PS for the current version. There would be no revert
                back.
              </Typography>
              <br />
            </Fragment>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <div className={classes.portletFooter}>
            <div className={classes.footerButtons}>
              <div className={classes.footerButtonsLeftRight}>
                <Button
                  variant="outlined"
                  onClick={(event) => handleClose()}
                  color="default"
                >
                  {"Cancel"}
                </Button>
              </div>
              <div className={classes.footerButtonsCenter}>
                <Button
                  variant="contained"
                  onClick={(event) => handleConfirmationClick()} //in handle close we are passing confirmation method
                  color="primary"
                >
                  {"Looks Good, Proceed"}
                </Button>
              </div>
              <div className={classes.footerButtonsLeftRight}></div>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
