import React, {
  Component,
  Fragment,
  useState,
  useEffect,
  useContext,
} from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import styles2, { getPHListStyle, getDDItemStyle } from "views/PmaAdmin/styles";
import ListingRow from "./ListingRow";
import SingleListingRow from "./SingleListingRow";
import styles from "views/PmaAdmin/PropertyHighlights/styles";
import "views/PmaAdmin/PropertyHighlights/styles.scss";
import { Dashboard as DashboardLayout } from "layouts";
import { withSnackbar } from "notistack";
import { Portlet } from "components";
import { PortletContent } from "components";
import { PortletHeader } from "components";
import classNames from "classnames";
import { ImageUploadDialog } from "components";
import { GetAreaPropHighlights } from "services/others";
import Base from "common/constants";
import { GetStateStorage, IsValidSessionSwitch } from "common/storage";
import moment from "moment";
import { GetPmaAdminDataAPI } from "services/agent";
import {
  UpdatePropertyHighlights,
  PropertyHighlightPhotoOptimization,
  UpdatePropertyHighlightsForSingleArea,
} from "services/agent";
import { showErrorNotification, showInfoNotification } from "common/helpers";
import { showSuccessNotification } from "common/helpers";
import { withRouter } from "react-router-dom";
import { Store } from "Store";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdfOutlined";
import { MediaViewComponent } from "components";
import DocumentTitle from "react-document-title";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { propertyUtil } from "views/Pma/PropertyUtil";
import HighlightRow from "views/PmaAdmin/PropertyHighlights/HighlightRow";
import EditProperty from "views/Pma/PmaEditor/CommonEditor/PhotoEditProperty";
import AddProperty from "views/Pma/PmaEditor/CommonEditor/PhotoAddProperty";
import YETwoPropertyHighlights from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoFirstPage/YETwoPropertyHighlights";
// import ErrorModal from "./ErrorModal";

require("views/Pma/PmaEditor/YearEnd/YETwo/ye2styles.scss");

const PropertyHighlights = (props) => {
  const { classes } = props;
  const { dispatch } = useContext(Store);
  const isAValidSessionSwitch = IsValidSessionSwitch();
  const { userData } = GetStateStorage();
  const regexWithPDF = new RegExp(/^.*\.(PDF|pdf)$/);
  const maxHighlightTitleLength = 28;
  const maxHighlightSubtitleLength =
    Base.PMA_ADMIN.MAX_HIGHLIGHT_SUBTITLE_LENGTH;
  const maxHighlightFooter1Length = 60; //60
  const maxHighlightFooter2Length = 30;

  const [showEditDialog, setEditDialog] = React.useState(false);
  const [showAddPropDialog, setAddPropDialog] = React.useState(false);
  const [showHighlightTitleDialog, setHighlightTitleDialog] = React.useState(
    false
  );
  const [showHighlightFooterDialog, setHighlightFooterDialog] = React.useState(
    false
  );
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalText, setErrorModalText] = useState("");
  const [goToPma, setGoToPma] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openUploadImageDialog, setUploadImageDialog] = React.useState(false);
  const [openViewImageDialog, setViewImageDialog] = React.useState(false);
  const [uploadImageKey, setUploadImageKey] = React.useState(null);
  const [uploadCurrentImage, setUploadCurrentImage] = React.useState(null);
  const [oldTitle, setOldTitle] = React.useState({
    propertyHighlightsTitle: "",
    propertyHighlightsSubtitle: "",
  });
  const [oldFooter, setOldFooter] = React.useState({
    propertyHighlightsFooterText1: "",
    propertyHighlightsFooterText2: "",
  });
  const [editPropFormData, setEditPropFormData] = React.useState({
    address: "",
    bedBaths: "",
    brokerageName: "",
    cityName: "",
    daysOnMarket: "",
    displayOrder: "",
    isBuyingAgent: false,
    isSystemSelected: false,
    isSellingAgent: true,
    listDate: "",
    listPrice: "",
    listingAgentLicenseID: "",
    listingAgentName: "",
    lotSizeSquareFt: "",
    photoURL: "",
    saleDate: "",
    salePrice: "",
    sellingAgentLicenseID: "",
    sellingAgentName: "",
    squareFeet: "",
    status: "",
    streetDirPrefix: "-",
    streetName: "",
    streetNumber: "",
    streetSuffix: "",
    unitNumber: "",
    view: "",
    justAdded: false,
  });

  const [oldPropData, setOldPropData] = React.useState({
    address: "",
    bedBaths: "",
    brokerageName: "",
    cityName: "",
    daysOnMarket: "",
    displayOrder: "",
    isBuyingAgent: false,
    isSystemSelected: false,
    isSellingAgent: true,
    listDate: "",
    listPrice: "",
    listingAgentLicenseID: "",
    listingAgentName: "",
    lotSizeSquareFt: "",
    photoURL: "",
    saleDate: "",
    salePrice: "",
    sellingAgentLicenseID: "",
    sellingAgentName: "",
    squareFeet: "",
    status: "",
    streetDirPrefix: "-",
    streetName: "",
    streetNumber: "",
    streetSuffix: "",
    unitNumber: "",
    view: "",
    justAdded: false,
  });
  const emptyProp = {
    address: "",
    bedBaths: "",
    brokerageName: "",
    cityName: "",
    daysOnMarket: "",
    displayOrder: "",
    isBuyingAgent: false,
    isSystemSelected: false,
    isSellingAgent: true,
    listDate: "",
    listPrice: "",
    listingAgentLicenseID: "",
    listingAgentName: "",
    lotSizeSquareFt: "",
    photoURL: "",
    saleDate: "",
    salePrice: "",
    sellingAgentLicenseID: "",
    sellingAgentName: "",
    squareFeet: "",
    status: "",
    streetDirPrefix: "-",
    streetName: "",
    streetNumber: "",
    streetSuffix: "",
    unitNumber: "",
    view: "",
    justAdded: false,
  };
  const [photoDetails, setPhotoDetails] = React.useState([]);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setBtnLoading] = useState(false);
  //
  // const reorderPhotoList = (photoDetails) => {
  //   console.log("inside reorder", photoDetails);
  //
  //   let selected = photoDetails.filter((prop) => prop.isSystemSelected === true);
  //   if (selected.length === 0) {
  //     console.log("****no selected here");
  //     //  let mapToIndex = selected.map((prop, index) => (prop.displayOrder = index));
  //   }
  //   let unselected = photoDetails.filter((prop) => prop.isSystemSelected === false);
  //   let propsNeeded = 5 - selected.length;
  //   if (selected.length < 5) {
  //     let removeTopSales = unselected.slice(0, propsNeeded);
  //     let makeSelected = removeTopSales.map(
  //       (prop) => (prop = { ...prop, isSystemSelected: true })
  //     );
  //     selected = [...selected, ...makeSelected];
  //   }
  //   let sortSelected = selected.map(
  //     (prop, index) => (prop = { ...prop, displayOrder: index })
  //   );
  //   let transformUnselected = unselected
  //     .slice(propsNeeded)
  //     .map(
  //       (prop, index) =>
  //         (prop = { ...prop, displayOrder: index + selected.length })
  //     );
  //   let updatedPropInfo = [...sortSelected, ...transformUnselected];
  //
  //   return updatedPropInfo;
  // };
  const reorderSelected = (photoDetails) => {
    let selected = photoDetails.filter(
      (prop) => prop.isSystemSelected === true
    );
    if (selected.length === 0) {
      let createSelected = photoDetails
        .slice(0, 5)
        .map((prop) => (prop.isSystemSelected = true));
    }
    let unselected = photoDetails.filter(
      (prop) => prop.isSystemSelected === false
    );
    let selectedWithSameOrder = selected
      .map((prop) => prop.displayOrder)
      .filter((order) => order === 0).length;
    if (selectedWithSameOrder > 1) {
      let sortSelected = selected.map(
        (prop, index) => (prop = { ...prop, displayOrder: index })
      );
      return [...sortSelected, ...unselected];
    } else {
      return photoDetails;
    }
  };
  const getAreaPropHighlights = () => {
    const { roundSalePrice } = propertyUtil;
    const { isMemberOfTeam, listgenUserID } = userData;
    setIsLoading(true);
    const thisArea = props.currentArea.mlsAreaID;
    //use the below for single areas
    //GetAreaPropHighlights(userData.listgenUserID, thisArea)
    GetAreaPropHighlights(userData.listgenUserID, userData.ownedMLSID[0])
      .then((data) => {
        console.log("******after prop highlights loaded", data);
        if (Object.keys(data).length > 0) {
          const { photoDetails } = data;
          console.log("data", data);
          let updatedPropInfo = reorderSelected(photoDetails);
          let uniqueProps = getUniqueProps(updatedPropInfo);
          console.log("unique props", uniqueProps);
          setPhotoDetails(uniqueProps);
          setEditPropFormData({
            ...editPropFormData,
          });
        } else {
          console.log("no data came back");
          setPhotoDetails([]);
        }
      })
      .catch((err) => {
        setPhotoDetails([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (
      photoDetails &&
      Object.keys(photoDetails).length > 1 &&
      isSubmitting === true
    ) {
      console.log("about to go to submit, in use effect");
      onPropHighlightSubmit();
    }
  }, [isSubmitting]);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("inside  use effect", props);
    if (Object.keys(photoDetails).length === 0) {
      // setPhotoDetails(props.currentSoldListings)
      // getAreaPropHighlights(userData.listgenUserID, userData.ownedMLSID[0]);
      setPhotoDetails(reorderSelected(props.currentSoldListings));
    }
  }, []);

  const openUploadMedia = async (key, currentImage) => {
    setUploadImageDialog(true);
    setUploadCurrentImage(currentImage);
    setUploadImageKey(key);
  };

  const handlePropertyHighlightUpload = async (uploadedImageUrl) => {
    if (uploadedImageUrl && uploadedImageUrl.optimizedPhotoURL) {
      setEditPropFormData({
        ...editPropFormData,
        photoURL: uploadedImageUrl.optimizedPhotoURL,
        optimizedPhotoURL: uploadedImageUrl.optimizedPhotoURL,
        justAdded: true,
      });
    }
    setUploadImageDialog(false);
    setUploadCurrentImage(null);
    setUploadImageKey(null);
    setShowError("");
  };

  const openViewMedia = async (currentImage) => {
    setViewImageDialog(true);
    setUploadCurrentImage(currentImage);
  };

  const closeViewMedia = async () => {
    setViewImageDialog(false);
    setUploadCurrentImage(null);
  };

  const handleOpenEditDialog = (prop) => {
    const propObj = prop;
    setOldPropData({
      ...propObj,
      streetSuffix: propObj.streetSuffix === "-" ? "" : propObj.streetSuffix,
      unitNumber: propObj.unitNumber === "-" ? "" : propObj.unitNumber,
    });
    setEditPropFormData({
      ...propObj,
      streetSuffix: propObj.streetSuffix === "-" ? "" : propObj.streetSuffix,
      unitNumber: propObj.unitNumber === "-" ? "" : propObj.unitNumber,
    });
    setEditDialog(true);
  };

  const handleErrorModal = (type) => {
    if (type === "close") {
      setShowErrorModal(false);
      setErrorModalText("false");
    }
  };

  const isValidTopFiveForm = () => {
    let isValid = true;
    const {
      areaID,
      averageSalePricePreviousYear,
      averageSalePriceCurrentYear,
      countyID,
      homesSoldPreviousYear,
      homesSoldCurrentYear,
      listgenUserAreaID,
      listgenUserID,
      photoDetails,
      photoDetailsRunDate,
      propertyHighlightsSubtitle,
      propertyHighlightsTitle,
      propertyHighlightsFooterText1,
      propertyHighlightsFooterText2,
      totalSalePricePreviousYear,
      totalSalePriceCurrentYear,
    } = photoDetails;
    console.log(photoDetails);
    console.log(
      areaID,
      averageSalePricePreviousYear,
      averageSalePriceCurrentYear,
      countyID,
      homesSoldPreviousYear,
      homesSoldCurrentYear,
      listgenUserAreaID,
      listgenUserID,
      photoDetails,
      photoDetailsRunDate,
      propertyHighlightsSubtitle,
      propertyHighlightsTitle,
      propertyHighlightsFooterText1,
      propertyHighlightsFooterText2,
      totalSalePricePreviousYear,
      totalSalePriceCurrentYear
    );
    if (
      !areaID ||
      !averageSalePricePreviousYear ||
      !averageSalePriceCurrentYear ||
      !countyID ||
      !homesSoldPreviousYear ||
      !homesSoldCurrentYear ||
      !listgenUserAreaID ||
      !listgenUserID ||
      !photoDetails ||
      !photoDetailsRunDate ||
      !propertyHighlightsSubtitle ||
      // !propertyHighlightsTitle ||
      // propertyHighlightsFooterText1 ||
      // propertyHighlightsFooterText2 ||
      !totalSalePricePreviousYear ||
      !totalSalePriceCurrentYear
    ) {
      isValid = false;
    }
    console.log("is Valid====", isValid);
    return isValid;
  };

  const handleFormClose = () => {
    setAddPropDialog(false);
    setEditDialog(false);
  };
  const onHighlightTitleClose = () => {
    setHighlightTitleDialog(false);
    setOldTitle({
      propertyHighlightsTitle: "",
      propertyHighlightsSubtitle: "",
    });
  };
  const onHighlightTitleOpen = () => {
    setHighlightTitleDialog(true);
    setOldTitle({
      propertyHighlightsTitle: photoDetails.propertyHighlightsTitle,
      propertyHighlightsSubtitle: photoDetails.propertyHighlightsSubtitle,
    });
  };
  const onHighlightFooterClose = () => {
    setHighlightFooterDialog(false);
    setOldFooter({
      propertyHighlightsFooterText1: "",
      propertyHighlightsFooterText2: "",
    });
  };
  const onHighlightFooterOpen = () => {
    console.log("inside highlightfooterOpen", photoDetails);
    setHighlightFooterDialog(true);
    setOldFooter({
      propertyHighlightsFooterText1: photoDetails.propertyHighlightsFooterText1,
      propertyHighlightsFooterText2: photoDetails.propertyHighlightsFooterText2,
    });
  };
  const handleAddPropDialog = () => {
    let selected =
      (photoDetails &&
        photoDetails.filter(
          (property) => property.isSystemSelected === true
        )) ||
      [];
    if (selected.length >= 5) {
      setErrorModalText("Max properties");
      setShowErrorModal(true);
    } else {
      setAddPropDialog(true);
      setEditPropFormData(emptyProp);
    }
  };

  const handleAddProperty = () => {
    const {
      streetNumber,
      streetName,
      cityName,
      salePrice,
      brokerageName,
      photoURL,
    } = editPropFormData;
    if (
      !streetNumber ||
      !streetName ||
      !cityName ||
      !salePrice ||
      !brokerageName ||
      !photoURL
    ) {
      setErrorModalText("Missing inputs");
      setShowErrorModal(true);
    } else {
      let numberPattern = /\d+/g;
      //Removes all commas, dollar sign and non-numeric characters from user entered sale price
      let salePriceOnlyNumbers = (
        (editPropFormData.salePrice || 0) + ""
      ).replace(/\D/g, "");
      let selected = photoDetails.photoDetails.filter(
        (property) => property.isSystemSelected === true
      );
      let changedObj = {
        ...editPropFormData,
        salePrice: salePriceOnlyNumbers || 0,
        isSystemSelected: true,
        displayOrder: selected.length,
      };
      setEditPropFormData(changedObj);
      setPhotoDetails({
        ...photoDetails,
        photoDetails: [...photoDetails.photoDetails, changedObj],
      });
      setIsSubmitting(true);
      setAddPropDialog(false);
    }
  };
  const wholePageSubmit = () => {
    setIsSubmitting(true);
    setGoToPma(true);
  };
  const handleAddTitle = (overMaxTitle, overMaxSubtitle) => {
    if (overMaxTitle || overMaxSubtitle) {
      setErrorModalText("Max title");
      setShowErrorModal(true);
    } else {
      setIsSubmitting(true);
      setHighlightTitleDialog(false);
    }
  };

  const handleAddFooter = (overMaxFooter1, overMaxFooter2) => {
    if (overMaxFooter1 || overMaxFooter2) {
      setErrorModalText("Max title");
      setShowErrorModal(true);
    } else {
      setIsSubmitting(true);
      setHighlightFooterDialog(false);
    }
  };

  const onPropHighlightSubmit = () => {
    const { photoDetails } = photoDetails;
    let selected = photoDetails.filter(
      (property) => property.isSystemSelected === true
    );

    let isValid = isValidTopFiveForm();
    console.log("isValid", isValid);
    console.log("is it valid in prophighlightsubmit?", isValid);
    if (!isValid) {
      console.log("is not valid);");
      setErrorModalText("Issue submitting form");
      setShowErrorModal(true);
    } else {
      console.log("in the else");
      //now start loading and submit the form
      try {
        // const stateData = GetStateStorage();
        // const agentData = stateData.userData;
        //check if state data not founf
        //const { mlsAreas, switchArea } = this.props;
        // /**Get session storage */
        // console.log("in the try block");
        //  let paramAreaID = this.props.match.params.areaID;
        // console.log("match****", this.props);
        // console.log("paramAreaID", paramAreaID);
        // let navbarData =
        //   agentData && Array.isArray(agentData.navbarInfo)
        //     ? agentData.navbarInfo.filter(
        //         (area) => area.mlsAreaID === paramAreaID
        //       )[0]
        //     : {};
        console.log("photoDetails", photoDetails);
        setBtnLoading(true);
        //use the below only for single area
        //UpdatePropertyHighlightsForSingleArea(photoDetails)
        UpdatePropertyHighlights(photoDetails)
          .then((data) => {
            // console.log("data is", data);
            showSuccessNotification(
              props,
              `Your property highlights have been saved`
            );
            setBtnLoading(false);
            //
            setIsSubmitting(false);
            // console.log("props before set timeout", props);
            if (goToPma) {
              setTimeout(() => {
                props.history.push(`/pma/${props.match.params.areaID}`);
              }, 1300);
            }
          })
          .catch((err) => {
            console.log("in another catch");
            showErrorNotification(
              props,
              "Something went wrong while processing your request."
            );
            setBtnLoading(false);
          });
      } catch (err) {
        console.log("in the catch");
        showErrorNotification(props, "Unable to process request.");
        setBtnLoading(false);
      }
    }
  };

  const onEditFormSubmit = () => {
    const { photoDetails } = photoDetails;
    const {
      streetNumber,
      streetName,
      cityName,
      salePrice,
      brokerageName,
      photoURL,
    } = editPropFormData;
    if (
      !streetNumber ||
      !streetName ||
      !cityName ||
      !salePrice ||
      !brokerageName ||
      !photoURL
    ) {
      setErrorModalText("Missing inputs");
      setShowErrorModal(true);
    } else {
      const changeProperty = photoDetails.map((prop) =>
        prop.streetName === editPropFormData.streetName &&
        prop.streetNumber === editPropFormData.streetNumber &&
        prop.listDate === editPropFormData.listDate &&
        prop.unitNumber === editPropFormData.unitNumber
          ? editPropFormData
          : prop
      );

      let change3 = photoDetails.map((prop) => {
        if (
          //compare fields that cannot be edited by the agent
          prop.view === editPropFormData.view &&
          prop.bedBaths === editPropFormData.bedBaths &&
          prop.listingAgentLicenseID === editPropFormData.listingAgentLicenseID
        ) {
          return editPropFormData;
        } else {
          return prop;
        }
      });

      setPhotoDetails({
        ...photoDetails,
        photoDetails: change3,
      });
      setIsSubmitting(true);
      handleFormClose();
    }
  };

  const handleDragEnd = (result) => {
    // const {
    //   areaID,
    //   countyID,
    //   listgenUserAreaID,
    //   listgenUserID,
    //   photoDetailsRunDate,
    //   photoDetails,
    // } = photoDetails;
    console.log("result is", result);
    console.log("inside handle drag end photo", photoDetails);
    // dropped outside the list
    let selected = photoDetails.filter(
      (prop) => prop.isSystemSelected === true
    );
    let sortSelected = selected.sort((a, b) => a.displayOrder - b.displayOrder);
    let mapToIndex = selected.map((prop, index) => (prop.displayOrder = index));
    let unselected = photoDetails.filter(
      (prop) => prop.isSystemSelected === false
    );
    if (!result.destination) {
      return;
    }
    let dataToSort = Array.from(selected);
    let startIndex = result.source.index;
    let endIndex = result.destination.index;
    let [startData] = selected.splice(startIndex, 1);
    selected.splice(endIndex, 0, startData);
    let changeOrders = selected.map(
      (prop, index) => (prop.displayOrder = index)
    );
    let newList = [...selected, ...unselected];

    setPhotoDetails([...newList]);
    showInfoNotification(
      props,
      `Please SAVE YOUR CHANGES!  Click on the blue LOOKS GOOD – SUBMIT button at the bottom of the screen.`,
      20000,
      "top",
      "center"
    );
  };

  const filterSelectedProps = (propList) => {
    let selected = propList.filter(
      (property) => property.isSystemSelected === true
    );
    return selected.sort((a, b) => a.displayOrder - b.displayOrder);
  };
  const filterUnselectedProps = (propList) => {
    let unselected = propList.filter(
      (property) => property.isSystemSelected !== true
    );
    return unselected.sort((a, b) => a.sortOrder - b.sortOrder);
  };

  const handleSelect = (property, select) => {
    let selected = photoDetails.filter(
      (property) => property.isSystemSelected === true
    );
    let uniqueSelect = new Set(selected);
    if (select === true && selected.length >= 5) {
      setErrorModalText("Max properties");
      setShowErrorModal(true);
    } else {
      let propData = photoDetails;
      let selected = photoDetails.filter(
        (prop) => prop.isSystemSelected === true
      );
      let newDisplayOrder;
      if (select && !selected) {
        newDisplayOrder = 0;
      } else if (select && selected.length > 0) {
        newDisplayOrder = selected.length;
      } else {
        newDisplayOrder = 0;
      }
      const updatedPropList = propData.map((prop) =>
        prop.streetName === property.streetName &&
        prop.streetNumber === property.streetNumber &&
        prop.listDate === property.listDate
          ? {
              ...prop,
              isSystemSelected: select ? true : false,
              displayOrder: newDisplayOrder,
            }
          : prop
      );
      let updateSelected = updatedPropList.filter(
        (property) => property.isSystemSelected === true
      );
      if (!select) {
        let changeSelectOrders = updateSelected.map(
          (prop, index) => (prop.displayOrder = index)
        );
      }
      const {
        areaID,
        countyID,
        listgenUserAreaID,
        listgenUserID,
        photoDetailsRunDate,
      } = photoDetails;
      let constructedHighlights = {
        areaID,
        countyID,
        listgenUserAreaID,
        listgenUserID,
        photoDetails: updatedPropList,
        photoDetailsRunDate,
      };
      setPhotoDetails({
        ...photoDetails,
        photoDetails: updatedPropList,
      });
    }
  };

  const handleRepresentedOptions = () => {
    if (editPropFormData.isBuyingAgent && editPropFormData.isSellingAgent) {
      setEditPropFormData({
        ...editPropFormData,
        isSellingAgent: false,
      });
    } else if (editPropFormData.isBuyingAgent) {
      setEditPropFormData({
        ...editPropFormData,
        isSellingAgent: true,
        isBuyingAgent: false,
      });
    } else if (editPropFormData.isSellingAgent) {
      setEditPropFormData({
        ...editPropFormData,
        isSellingAgent: true,
        isBuyingAgent: true,
      });
    }
  };

  const getUniqueProps = (props) => {
    let final = [];
    let addressList = [];
    for (let i = 0; i < props.length; i++) {
      let prop = props[i];
      let address = `${prop.streetNumber} ${prop.streetName} ${prop.unitNumber}`;
      if (!addressList.includes(address)) {
        final.push(prop);
        addressList.push(address);
      } else {
      }
    }
    return final;
  };
  const handleInputChange = (e) => {
    const target = e.target;
    let value = target.value;
    const name = target.name;
    if (e.target.name === "salePrice") {
      value = Number(value);
    }
    setEditPropFormData({
      ...editPropFormData,
      [e.target.name]: value,
    });
  };

  const handleTitleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setPhotoDetails({ ...photoDetails, [e.target.name]: value });
  };

  const handleFooterChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setPhotoDetails({ ...photoDetails, [e.target.name]: value });
  };

  const resetDisplayOrder = () => {
    const { photoDetails } = photoDetails;
    let updatedPropList = photoDetails.map((property) => ({
      ...property,
      displayOrder: 0,
    }));

    setPhotoDetails({
      photoDetails: updatedPropList,
    });
  };
  const scrollDownToProperties = () => {
    window.scrollTo({
      top: 970,
      behavior: "auto",
    });
  };
  const {
    currentArea,
    handlePropCheck,
    agentData,
    updateChanges,
    dateSort,
    beginClosePhotoModal,
  } = props;
  const { mlsAreaID, mlsNeighborhood } = currentArea;

  const { formatSuffix } = propertyUtil;
  const selectedProps =
    photoDetails &&
    photoDetails.length > 1 &&
    filterSelectedProps(photoDetails);
  const unselectedProps =
    photoDetails &&
    photoDetails.length > 1 &&
    filterUnselectedProps(photoDetails);
  let showButton = true;
  const thisArea = props.currentArea.mlsAreaID;

  console.log("props are", props);
  // console.log("photoDetails", photoDetails && photoDetails);
  console.log("&****photoDetails", photoDetails);
  let selectedProperties = photoDetails.filter(
    (prop) => prop.isSystemSelected == true
  );
  let soldProperties = photoDetails.filter(
    (prop) => prop.status === "Sold" && prop.isSystemSelected == false
  );
  console.log("soldProperties", soldProperties);
  let pendingProperties = photoDetails.filter(
    (prop) =>
      (prop.status === "Pending" || prop.status === "Contingent") &&
      prop.isSystemSelected == false
  );
  console.log("pendingProperties", pendingProperties);
  let activeProperties = photoDetails.filter(
    (prop) => prop.status === "Active" && prop.isSystemSelected == false
  );
  console.log("00000000000selectedProperties", selectedProperties);
  if (photoDetails && photoDetails.length >= 1) {
    return (
      <div className="photo-modal">
        <div className="photo-modal-body">
          <div className="photo-modal-top-title-row">
            <div className="photo-modal-title">
              <div className="photo-modal-title-text">PMA</div>
              <div className="photo-modal-title-divider" />
              <div className="photo-modal-title-text">Edit Property Photos</div>
            </div>
            <div
              className="close-photo-modal-button"
              onClick={() => beginClosePhotoModal()}
            >
              <p>Close</p>
            </div>
          </div>
          <div className="photo-modal-top-divider"></div>
          <div className="photo-modal-bottom-title-row">
            <div className="photo-modal-bottom-title-col">
              <div className="photo-modal-bottom-title-area">
                {mlsNeighborhood}
              </div>
              <div className="photo-modal-bottom-title-date">
                As of XXX - March 13, 2023
              </div>
            </div>
            <div className="photo-modal-bottom-title-col">
              <div
                className="update-changes-button"
                onClick={() => updateChanges()}
              >
                <p>Update + Save Changes</p>
              </div>
            </div>
          </div>

          <div className="photo-modal-information-banner">
            <div className="photo-modal-information-banner-text">
              <p>
                Click the checkbox of any 6 properties that you would like to
                display on the photo page:
              </p>
            </div>
          </div>
          {/*<div
          className="photo-add-property-row"
          onClick={() => handleAddPropDialog()}
        >
          <div className="add-property-button">
            <div>+</div>
          </div>
          <div className="add-property-text">
            Add a <span className="sold-span">Sold</span> Property
          </div>
        </div>*/}
          {/*<div className="update-changes-button-container">
          <div style={{ maxWidth: "439px" }}>
            <DrawerModeHelpScreen
              accessedFromAreaID={(currentArea || {}).mlsAreaID}
              text="Don’t see a property you’re looking for? Click here to send a support ticket"
            />
          </div>
         <div
            className="support-photo-modal-button"
            // onClick={() => updateChanges()}
          >
            <p>Don’t see a property you’re looking for? </p>
            <p>Click here to send a support ticket</p>
          </div>
        </div>*/}

          {/*<div className="photo-modal-recent-activity-text">
          Recent Activity as of XXX - {this.getTodayDate()}
        </div>
        <div className="photo-modal-legend">
          <div className="photo-modal-legend-row">
            <div className="photo-modal-my-listing-dot"></div>Indicates My
            Listing
          </div>
        </div>*/}
          <div className="photo-sales-table">
            <div>
              {/*<DragDropContext
                onDragEnd={handleDragEnd}
                className="drag-drop-context"
              >
                <Droppable
                  droppableId="lg-property-highlight"
                  className="droppable-prop"
                >
                  {(provided, snapshot) => (
                    <div
                      className="draggable-div"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getPHListStyle(snapshot.isDraggingOver)}
                    >
                      <Draggable
                        className="draggable-container"
                        draggableId={`${photoDetails[0].streetNumber}-${photoDetails[0].streetName}-${photoDetails[0].unitNumber}`}
                        index={photoDetails[0].displayOrder}
                        key={`${photoDetails[0].streetNumber}-${photoDetails[0].streetName}-${photoDetails[0].unitNumber}`}
                      >
                        {(provided, snapshot) => (
                          <div
                            className="highlight-row-container"
                            key={`${photoDetails[0].streetNumber}-${photoDetails[0].streetName}-${photoDetails[0].unitNumber}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <SingleListingRow
                              properties={selectedProperties}
                              oldPropData={oldPropData}
                              photoDetails={photoDetails.filter(
                                (listing) => listing.isSystemSelected
                              )}
                              mlsAreaID={mlsAreaID}
                              handlePropCheck={handlePropCheck}
                              agentData={agentData}
                              handleOpenEditDialog={handleOpenEditDialog}
                              fromPhotoTable={true}
                              isTopSix={true}
                              prop={photoDetails[0]}
                              property={[photoDetails[0].displayOrder]}
                              index={photoDetails[0].displayOrder}
                              areaID={mlsAreaID}
                              handleSelect={handleSelect}
                            />
                          </div>
                        )}
                      </Draggable>

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>*/}
              {photoDetails && photoDetails.length > 1 && (
                <DragDropContext
                  onDragEnd={handleDragEnd}
                  className="drag-drop-context"
                >
                  <Droppable
                    droppableId="lg-property-highlight"
                    className="droppable-prop"
                  >
                    {(provided, snapshot) => (
                      <div
                        className="draggable-div"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getPHListStyle(snapshot.isDraggingOver)}
                      >
                        {selectedProperties.map((prop, index) => {
                          console.log("***props is", prop);
                          return (
                            <Draggable
                              className="draggable-container"
                              // key={prop.displayOrder}
                              draggableId={`${prop.streetNumber}-${prop.streetName}-${index}-${prop.unitNumber}`}
                              // draggableId={`lg-ag-${prop.displayOrder}`}
                              // draggableId={index.toString()}
                              index={index}
                              key={`${prop.streetNumber}-${prop.streetName}-${prop.unitNumber}-{index}`}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className="highlight-row-container"
                                  key={`${prop.streetNumber}-${prop.streetName}-${prop.unitNumber}-{index}`}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <SingleListingRow
                                    // properties={selectedProperties}
                                    property={[photoDetails[index]]}
                                    oldPropData={oldPropData}
                                    photoDetails={prop}
                                    // photoDetails={photoDetails.filter(
                                    //   (listing) => listing.isSystemSelected
                                    // )}
                                    mlsAreaID={mlsAreaID}
                                    handlePropCheck={handlePropCheck}
                                    agentData={agentData}
                                    handleOpenEditDialog={handleOpenEditDialog}
                                    singleProperty={prop}
                                    fromPhotoTable={true}
                                    isTopSix={true}
                                    prop={photoDetails[0]}
                                    // index={photoDetails[index].displayOrder}
                                    index={index}
                                    areaID={mlsAreaID}
                                    handleSelect={handleSelect}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
              {/*<ListingRow
                properties={selectedProperties}
                oldPropData={oldPropData}
                photoDetails={photoDetails.filter(
                  (listing) => listing.isSystemSelected
                )}
                mlsAreaID={mlsAreaID}
                handlePropCheck={handlePropCheck}
                agentData={agentData}
                handleOpenEditDialog={handleOpenEditDialog}
                fromPhotoTable={true}
                isTopSix={true}
              />*/}
              {/*<ListingRow
                properties={dateSort(soldProperties)}
                mlsAreaID={mlsAreaID}
                handlePropCheck={handlePropCheck}
                agentData={agentData}
                handleOpenEditDialog={handleOpenEditDialog}
                fromPhotoTable={true}
                oldPropData={oldPropData}
              />
*/}
              {/*<ListingRow
                properties={dateSort(pendingProperties)}
                mlsAreaID={mlsAreaID}
                handlePropCheck={handlePropCheck}
                agentData={agentData}
                handleOpenEditDialog={handleOpenEditDialog}
                fromPhotoTable={true}
                oldPropData={oldPropData}
              />*/}
              {/*<ListingRow
                properties={dateSort(activeProperties)}
                mlsAreaID={mlsAreaID}
                handlePropCheck={handlePropCheck}
                agentData={agentData}
                handleOpenEditDialog={handleOpenEditDialog}
                fromPhotoTable={true}
                oldPropData={oldPropData}*/}
              />
            </div>
          </div>
        </div>

        {openViewImageDialog ? (
          <MediaViewComponent
            mediaURL={uploadCurrentImage}
            mediaTitle={"Property Highlight"}
            onOpen={() => {}}
            onClose={closeViewMedia}
          />
        ) : (
          ""
        )}
        {openUploadImageDialog ? (
          <ImageUploadDialog
            openDialog={true}
            onSubmit={handlePropertyHighlightUpload}
            onClose={handlePropertyHighlightUpload}
            isMyAccountUpload={isAValidSessionSwitch}
            imageType={"propertyHighlight"}
            listgenUserID={agentData.listgenUserID} //not required  because designation Logo doesnt required any image upload
            currentImage={uploadCurrentImage}
            teamMode={false}
          />
        ) : (
          ""
        )}
        {/*{showErrorModal ? (
            <ErrorModal
              errorModalText={errorModalText}
              handleErrorModal={handleErrorModal}
            />
          ) : (
            ""
          )}*/}
        {showAddPropDialog ? (
          <AddProperty
            onCloseFn={handleFormClose}
            openUploadMedia={openUploadMedia}
            handleInputChange={handleInputChange}
            editPropFormData={editPropFormData}
            handleRepresentedOptionsFunc={handleRepresentedOptions}
            listgenUserID={agentData.listgenUserID}
            handleAddProperty={handleAddProperty}
            userData={agentData}
            fromPhotoTable={true}
            agentData={agentData}
          />
        ) : (
          ""
        )}
        {showEditDialog ? (
          <EditProperty
            onCloseFn={handleFormClose}
            openUploadMedia={openUploadMedia}
            handleInputChange={handleInputChange}
            editPropFormData={editPropFormData}
            handleRepresentedOptionsFunc={handleRepresentedOptions}
            listgenUserID={agentData.listgenUserID}
            onEditFormSubmit={onEditFormSubmit}
            userData={agentData}
            oldPropData={oldPropData}
            areaID={mlsAreaID}
            fromPhotoTable={true}
            agentData={agentData}
          />
        ) : (
          ""
        )}

        {isLoading ? (
          <div className="prop-highlight-table-empty-container">
            <p>Loading Properties ....</p>
          </div>
        ) : (
          <div className="prop-highlight-table-container">
            {/*<div className="unique-btn" onClick={() => getUniqueProps()}>
                  <p>UNIQUE</p>
                </div>*/}

            <div className="prop-highlight-table">
              {photoDetails &&
                photoDetails.length === 1 &&
                photoDetails[0].isSystemSelected === true && (
                  <DragDropContext
                    onDragEnd={handleDragEnd}
                    className="drag-drop-context"
                  >
                    <Droppable
                      droppableId="lg-property-highlight"
                      className="droppable-prop"
                    >
                      {(provided, snapshot) => (
                        <div
                          className="draggable-div"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getPHListStyle(snapshot.isDraggingOver)}
                        >
                          <Draggable
                            className="draggable-container"
                            draggableId={`${photoDetails[0].streetNumber}-${photoDetails[0].streetName}-${photoDetails[0].unitNumber}`}
                            index={photoDetails[0].displayOrder}
                            key={`${photoDetails[0].streetNumber}-${photoDetails[0].streetName}-${photoDetails[0].unitNumber}`}
                          >
                            {(provided, snapshot) => (
                              <div
                                className="highlight-row-container"
                                key={`${photoDetails[0].streetNumber}-${photoDetails[0].streetName}-${photoDetails[0].unitNumber}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {/*<HighlightRow
                                    prop={photoDetails[0]}
                                    index={photoDetails[0].displayOrder}
                                    areaID={areaID}
                                    handleSelect={handleSelect}
                                    openEditDialogFn={handleOpenEditDialog}
                                  />*/}
                                {/*<ListingRow
                                  properties={selectedProperties}
                                  oldPropData={oldPropData}
                                  // photoDetails={photoDetails[index]}
                                  photoDetails={photoDetails.filter(
                                    (listing) => listing.isSystemSelected
                                  )}
                                  propListMap={selectedProperties}
                                  mlsAreaID={mlsAreaID}
                                  handlePropCheck={handlePropCheck}
                                  agentData={agentData}
                                  handleOpenEditDialog={handleOpenEditDialog}
                                  fromPhotoTable={true}
                                  isTopSix={true}
                                  // singleProperty={prop}
                                  prop={photoDetails[0]}
                                  index={photoDetails[0].displayOrder}
                                  areaID={mlsAreaID}
                                  handleSelect={handleSelect}
                                />
                              </div>*/}
                                <div index="1">hi</div>
                              </div>
                            )}
                          </Draggable>

                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}

              {photoDetails && photoDetails.length > 1 && (
                <DragDropContext
                  onDragEnd={handleDragEnd}
                  className="drag-drop-context"
                >
                  <Droppable
                    droppableId="lg-property-highlight"
                    className="droppable-prop"
                  >
                    {(provided, snapshot) => (
                      <div
                        className="draggable-div"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getPHListStyle(snapshot.isDraggingOver)}
                      >
                        {selectedProps.map((prop, index) => {
                          console.log("**********inside prop", prop);
                          return (
                            <Draggable
                              className="draggable-container"
                              // key={prop.displayOrder}
                              draggableId={`${prop.streetNumber}-${prop.streetName}-${index}-${prop.unitNumber}`}
                              // draggableId={`lg-ag-${prop.displayOrder}`}
                              // draggableId={index.toString()}
                              index={index}
                              key={`${prop.streetNumber}-${prop.streetName}-${prop.unitNumber}-{index}`}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className="highlight-row-container"
                                  key={`${prop.streetNumber}-${prop.streetName}-${prop.unitNumber}-{index}`}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <HighlightRow
                                    prop={prop}
                                    index={index}
                                    areaID={mlsAreaID}
                                    handleSelect={handleSelect}
                                    openEditDialogFn={handleOpenEditDialog}
                                  />
                                  }
                                  <ListingRow
                                    properties={selectedProperties}
                                    oldPropData={oldPropData}
                                    photoDetails={photoDetails[index]}
                                    // photoDetails={photoDetails.filter(
                                    //   (listing) => listing.isSystemSelected
                                    // )}
                                    propListMap={selectedProperties}
                                    mlsAreaID={mlsAreaID}
                                    handlePropCheck={handlePropCheck}
                                    agentData={agentData}
                                    handleOpenEditDialog={handleOpenEditDialog}
                                    fromPhotoTable={true}
                                    isTopSix={true}
                                    singleProperty={photoDetails[index]}
                                    // prop={photoDetails[0]}
                                    index={photoDetails[0].displayOrder}
                                    areaID={mlsAreaID}
                                    handleSelect={handleSelect}
                                  />
                                  <div>second</div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </div>

            {/*{photoDetails && photoDetails.length > 1 && (
              <div className="prop-highlight-table unselected-table">
                {unselectedProps.map((prop, index) => {
                  return (
                    <div className="drag-drop-context" key={index}>
                      <div className="droppable-prop">
                        <div className="draggable-div">
                          <div className="draggable-container">
                            <div className="highlight-row-container">
                              <HighlightRow
                                prop={prop}
                                index={index}
                                areaID={mlsAreaID}
                                handleSelect={handleSelect}
                                openEditDialogFn={handleOpenEditDialog}
                                showButton={showButton}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}*/}

            {photoDetails &&
              photoDetails.length === 1 &&
              photoDetails[0].isSystemSelected === false && (
                <div className="prop-highlight-table unselected-table">
                  <div
                    className="drag-drop-context"
                    key={`${photoDetails[0].streetName}-${photoDetails[0].unitNumber}`}
                  >
                    <div className="droppable-prop">
                      <div className="draggable-div">
                        <div className="draggable-container">
                          <div className="highlight-row-container">
                            <HighlightRow
                              prop={photoDetails[0]}
                              index={0}
                              areaID={mlsAreaID}
                              handleSelect={handleSelect}
                              openEditDialogFn={handleOpenEditDialog}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        )}
      </div>
    );
  } else {
    return <div>loading...</div>;
  }
};

PropertyHighlights.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withSnackbar,
  withStyles(
    (theme) => ({
      ...styles(theme),
      ...styles2(theme),
    }),
    {
      withTheme: true,
    },
    withRouter
  )
)(PropertyHighlights);
