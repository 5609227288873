import React, { Component } from "react";
import QVFourFirstPage from "./QVFourFirstPage";
import QVFourSecondPage from "./QVFourSecondPage";
import PropTypes from "prop-types";
import YETwoFirstPage from "views/Pma/PmaEditor/YearEnd2022/YETwo/YETwoFirstPage";

class QVFour extends Component {
  componentDidMount() {
    if (this.props.printVersion) {
      require("./qv4print.scss");
    } else {
      require("./qv4styles.scss");
    }
  }
  render() {
    const {
      printVersion,
      version,
      agentData,
      altAgentData,
      hoData,
      currentArea,
      mlsAreas,
      coverPageDetails,

      quarterlyAreaTable,
      quarterlyCityTable,
      testimonials,
      quadrantModalOpen,
      quadrantDetails,
      volunteerDetails,
      propertyPhotoDetailsDTO,
      missionStatement,
      isDemo,
      MQCityDetails,
      yearEnd,
      bubbleBoxes,
      aprilData,
      handlePropertyHighlightDialog,
    } = this.props;

    const { listgenUserID } = agentData;
    let showPropertyHighlightPage = false;
    if (listgenUserID === "x100462") {
      showPropertyHighlightPage = true;
    }

    if (showPropertyHighlightPage) {
      return (
        <div
          // className={printVersion ? "ye2-print-page" : "ye2-pma-editor-page"}

          className={
            printVersion
              ? "ye2-print-page qv4-print-page"
              : "ye2-pma-editor-page qv4-pma-editor-page"
          }
        >
          <YETwoFirstPage
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version={version}
            coverPageDetails={coverPageDetails}
            aprilData={aprilData}
            testimonials={testimonials}
            handlePropertyHighlightDialog={handlePropertyHighlightDialog}
            propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
            volunteerDetails={volunteerDetails}
            quarterlyAreaTable={quarterlyAreaTable}
            isDemo={isDemo}
            bubbleBoxes={bubbleBoxes}
            quadrantDetails={quadrantDetails}
          />
          <QVFourSecondPage
            agentData={agentData}
            hoData={hoData}
            currentArea={currentArea}
            mlsAreas={mlsAreas}
            printVersion={printVersion}
            version={version}
            quarterlyAreaTable={quarterlyAreaTable}
            quarterlyCityTable={quarterlyCityTable}
            isDemo={isDemo}
            MQCityDetails={MQCityDetails}
            yearEnd={yearEnd}
            aprilData={aprilData}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div
            className={printVersion ? "qv4-print-page" : "qv4-pma-editor-page"}
          >
            <QVFourFirstPage
              agentData={agentData}
              altAgentData={altAgentData}
              hoData={hoData}
              currentArea={currentArea}
              mlsAreas={mlsAreas}
              printVersion={printVersion}
              version={version}
              coverPageDetails={coverPageDetails}
              quarterlyAreaTable={quarterlyAreaTable}
              testimonials={testimonials}
              quadrantModalOpen={quadrantModalOpen}
              quadrantDetails={quadrantDetails}
              volunteerDetails={volunteerDetails}
              propertyPhotoDetailsDTO={propertyPhotoDetailsDTO}
              missionStatement={missionStatement}
              isDemo={isDemo}
              bubbleBoxes={bubbleBoxes}
              aprilData={aprilData}
              handlePropertyHighlightDialog={handlePropertyHighlightDialog}
            />
            <QVFourSecondPage
              agentData={agentData}
              hoData={hoData}
              currentArea={currentArea}
              mlsAreas={mlsAreas}
              printVersion={printVersion}
              version={version}
              quarterlyAreaTable={quarterlyAreaTable}
              quarterlyCityTable={quarterlyCityTable}
              isDemo={isDemo}
              MQCityDetails={MQCityDetails}
              yearEnd={yearEnd}
              aprilData={aprilData}
            />
          </div>
        </div>
      );
    }
  }
}

export default QVFour;
